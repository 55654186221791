import axios, { AxiosResponse } from "axios";
import axiosConfig from "./axios";
import { Credentials } from "../types/auth";
const API = process.env.REACT_APP_BACKEND_API;

/**
 *
 * @param email
 * @returns
 */
export const checkEmail = async (
  email: string
): Promise<{ pass: boolean; reason: string }> => {
  const match = await axiosConfig.post(`login/resetPass`, { email });
  return match.data;
};

/**
 *
 * @param email account that password will be changed on
 * @param password new password
 * @param code verification code that was sent via email
 * @returns
 */
export const changePassword = async (
  email: string,
  password: string,
  code: string
): Promise<{ pass: boolean; reason: string }> => {
  const match = await axiosConfig.post(`${API}/login/verifyReset`, {
    email,
    password,
    code,
  });
  return match.data;
};

/**
 * Hasn't been used yet, may be used for logged in password change.
 * @param email account that password will be changed on
 * @param password new password
 * @returns whether it passes and a reason why
 */
export const updatePassword = async (
  email: string,
  password: string
): Promise<{ pass: boolean; reason: string }> => {
  const update = await axiosConfig.post("update/password", { email, password });
  return update.data;
};

/**
 * Checks whether the login credentials are valid.
 * @param creds The object that contains the user's login details.
 * @returns
 */
export const checkServerAuth = async ({
  email,
  password,
}: Credentials): Promise<
  false | { accessToken: string; refreshToken: string }
> => {
  let match: false | AxiosResponse = false;
  try {
    match = await axiosConfig.post(`${API}/login`, { email, password });
  } catch (e) {
    console.error("error posting to /login", e);
  }
  return match ? match.data : match;
};

/**
 *
 * @param email
 * @returns
 */
export async function MultipassLogin(
  email: string,
  country: string
): Promise<{ accessToken: string; expiresAt: string }> {
  const urlMultipass = await axiosConfig.post(`${API}/login/multipass`, {
    email,
  });

  const storefrontToken =
    country === "Australia"
      ? process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN_AU!
      : process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN_NZ!;

  const token = urlMultipass.data;
  const shopifyHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",

    "X-Shopify-Storefront-Access-Token": storefrontToken, // eslint-disable-line @typescript-eslint/no-non-null-assertion,
  };
  const query = `mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
    customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }`;

  const storefrontUri =
    country === "Australia"
      ? process.env.REACT_APP_SHOPIFY_STOREFRONT_URI_AU
      : process.env.REACT_APP_SHOPIFY_STOREFRONT_URI_NZ;
  const multipassResult = await axios({
    url: `${storefrontUri}`,
    method: "post",
    headers: shopifyHeaders,
    data: {
      query: query,
      variables: { multipassToken: token },
    },
  });
  const multipassToken =
    multipassResult.data.data.customerAccessTokenCreateWithMultipass
      .customerAccessToken;
  if (multipassToken) {
    return multipassToken;
  } else {
    throw new Error(
      multipassResult.data.data.customerAccessTokenCreateWithMultipass.customerUserErrors.message
    );
  }
}

/**
 * @param email email that verification code will be sent to
 * @returns whether it passes and a reason why
 */
export const sendEmailVerification = async (
  email: string
): Promise<{ pass: boolean; reason: string }> => {
  const res = await axiosConfig.post(`${API}/signup/requestEmailVerification`, {
    email: email,
  });
  return res.data;
};

/**
 * @param email email that verification code will be sent to
 * @returns whether it passes and a reason why
 */
export const sendPasswordVerification = async (
  email: string
): Promise<{ pass: boolean; reason: string }> => {
  const res = await axiosConfig.post(`${API}/login/resetPass`, {
    email: email,
  });
  return res.data;
};
