import React, { useEffect } from "react";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import useShopifyUri from "../../../hooks/useShopifyUri";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

/**
 * Set up the cache to store request responses for
 * for better performance and lower data usage.
 */
export const cache = new InMemoryCache();

/**
 * Preconfigures a Shopify Appolo instance as a Provider.
 */
const ShopifyApolloProvider: React.FC = ({ children }) => {
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );

  /**
   * Identify the correct Shopify endpoint for requesting
   * product data based on user's country.
   */
  const [storefrontUri, storefrontToken] = useShopifyUri();

  /**
   * Preconfigure the client with header data.
   */
  const createClient = () => {
    return new ApolloClient({
      cache: cache,
      link: setContext(async (_, { headers }) => {
        return {
          headers: {
            ...headers,
            "X-Shopify-Storefront-Access-Token": storefrontToken,
          },
        };
      }).concat(
        createHttpLink({
          uri: storefrontUri,
        })
      ),
    });
  };
  const client = createClient();

  /**
   * Clear data on logout
   */
  useEffect(() => {
    client.stop();
    client.clearStore();
    client.cache.reset();
    client.resetStore();
  }, [isAuthenticated]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ShopifyApolloProvider;
