import { AppointmentType } from "../types/cogsworth";
import { CountryCode } from "../types/country";
import axiosConfig from "./axios";
const API = process.env.REACT_APP_BACKEND_API;

/**
 *
 */
export const generateCoupon = async (country: CountryCode): Promise<string> => {
  const response = await axiosConfig.get(`${API}/cogsworth`, {
    params: { country },
  });
  const data = response.data;
  return response.status === 201 ? data.data.code : "";
};

/**
 *
 */
export const generatePuppyCoupon = async (
  country: CountryCode
): Promise<string> => {
  const response = await axiosConfig.get(`${API}/cogsworth/puppy`, {
    params: { country },
  });
  const data = response.data;
  return response.status === 201 ? data.data.code : "";
};

/**
 *
 */
export const getAppointments = async (
  country: CountryCode
): Promise<AppointmentType[]> => {
  const response = await axiosConfig.get(`${API}/cogsworth/appointments`, {
    params: { country },
  });
  return response && response.status === 200 ? response.data : "";
};
