import "./Section.scss";

type SectionProps = {
  children: React.ReactNode | React.ReactNode[];
};

/**
 * Layout used to segment major sections in a web page.
 */
const Section: React.FC<SectionProps> = ({ children }: SectionProps) => {
  return <section className="section">{children}</section>;
};

export default Section;
