import { Address } from "../types/address";

/**
 * Converts the Google place response into an object compatible with our app.
 * Logic extracted from the React Native codebase for continuity.
 */
export const componentsToAddress = (
  address?: google.maps.GeocoderAddressComponent[]
): Address => {
  const result = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    province: "",
    postcode: "",
  };
  for (const component of address ?? []) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        result.address1 = `${component.long_name} ${result.address1}`;
        break;
      }

      case "route": {
        result.address1 += component.short_name;
        break;
      }

      case "subpremise": {
        result.address2 = component.long_name;
        break;
      }

      case "postal_code": {
        result.postcode = `${component.long_name}${result.postcode}`;
        break;
      }

      case "postal_code_suffix": {
        result.postcode = `${result.postcode}-${component.long_name}`;
        break;
      }

      case "locality":
        result.city = component.long_name;
        break;

      case "administrative_area_level_1": {
        result.state = component.long_name;
        break;
      }

      case "country":
        result.country = component.long_name;
        break;
    }
  }
  return result;
};

/**
 * Converts the address object into a readable string.
 */
export const addressToString = (address: Address | null): string => {
  if (!address) {
    return "";
  }

  const { address1, address2, city, country, postcode, state } = address;

  const renderAddress = () => {
    return address.address2 ? `${address2}/${address1}` : address1;
  };

  return `${renderAddress()}, ${city} ${state} ${postcode}, ${country}`;
};
