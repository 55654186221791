import "./BackLink.scss";
import React from "react";
import history from "../../../utils/history";

interface BackLinkProps {
  /**
   * Overrides the default text in the link.
   */
  label?: string;
  /**
   * Overrides the location that history redirects to.
   */
  onClick?: () => void;
}

const BackLink: React.FC<BackLinkProps> = ({ label, onClick }) => {
  return (
    <a className="backlink" onClick={onClick || history.goBack}>
      ← {label || "Go back"}
    </a>
  );
};

export default BackLink;
