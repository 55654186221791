import "./Claim.scss";
import React, { useState } from "react";
import { Button, Spinner } from "../../common/";
import _ from "lodash";
import { ClaimData } from "../../../types/claims";
import { Modal, Row } from "../../layout";
import { Invoice } from "../Invoice/Invoice";

interface ClaimProps {
  claim: ClaimData;
}

const Claim: React.FC<ClaimProps> = ({ claim }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        maxWidth={800}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      >
        {claim ? (
          <div className="claim__modal">
            <div className="claim__modalinvoice">
              <Invoice
                claimId={claim._id}
                isPdf={claim.invoice.slice(claim.invoice.length - 3) === "pdf"}
              />
            </div>
            <div className="claim__modalcontent">
              <h3>#YPPA-{claim.claimId} Claim</h3>
              <p>Type: {_.startCase(claim.type)}</p>
              <p>Pet: {claim.pet.name}</p>
              <p>Date: {new Date(claim.date).toDateString()}</p>
              <p>Vet: {claim.vet}</p>
              <p>Amount: {claim.reimbursement.amount}</p>
              <p>Status: {_.capitalize(claim.status)}</p>
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </Modal>
      <div className="claim" key={claim.claimId}>
        <div className="claim__content">
          <h3>
            #YPPA-{claim.claimId} {_.startCase(claim.type)} claim for{" "}
            {claim.pet.name}
          </h3>
          <p>Status: {_.capitalize(claim.status)}</p>
        </div>
        <div className="claim__buttons">
          <Button
            fullwidth
            label="View Claim"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
    </>
  );
};

export default Claim;
