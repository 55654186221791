import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "..";
import { Button } from "../../common";
import { TextInput } from "../../input";
import { actionCreators as AuthActionCreators } from "../../../store/Auth";
import { actionCreators as UserActionCreators } from "../../../store/User";
import history from "../../../utils/history";
import { useEffect } from "react";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/Cart";

/**
 * Form for logging a user into the web app.
 */
const LoginForm: React.FC = () => {
  const { fetchUserToken, fetchUserData } = bindActionCreators(
    { ...AuthActionCreators, ...UserActionCreators },
    useDispatch()
  );

  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const { clearCart } = bindActionCreators(actionCreators, useDispatch());

  useEffect(() => {
    if (isAuthenticated) {
      const loginSetup = async () => {
        await fetchUserData();
        history.push("/");
      };
      loginSetup();
    }
  }, [isAuthenticated]);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validate: ({ email }) => {
      const errors: { email?: string } = {};
      if (!email) {
        errors.email = "please add an email";
      }
      return errors;
    },
    onSubmit: async ({ email, password }) => {
      clearCart();
      await fetchUserToken(email, password);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <h2>
        Sign In
        <span>
          Don't have an account? <br />
          <a
            onClick={() => {
              history.push("/onboarding");
            }}
          >
            Join Thriving Pets today!
          </a>
        </span>
      </h2>
      <TextInput
        label="Email address"
        value={formik.values.email}
        error={formik.errors.email}
        touched={formik.touched.email}
        onChange={formik.handleChange("email")}
      />
      <TextInput
        label="Password"
        type="password"
        value={formik.values.password}
        error={formik.errors.password}
        touched={formik.touched.password}
        onChange={formik.handleChange("password")}
      />
      <Button label="Sign In" onClick={formik.handleSubmit} fullwidth />
      <br />
      <a onClick={() => history.push("/password/reset")}>Forgot Password?</a>
    </Form>
  );
};

export default LoginForm;
