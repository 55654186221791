import "./Row.scss";
import React, { CSSProperties } from "react";

interface RowProps {
  /**
   * Each child will be a row item in the row.
   * Space between each item is automatically applied.
   */
  children: React.ReactNode | React.ReactNode[];
  /**
   * How the items should be aligned vertically.
   */
  alignItems?: CSSProperties["alignContent"];
  /**
   * Removes spacing between elements in the row
   */
  removeSpacing?: boolean;
}

const Row: React.FC<RowProps> = ({ children, alignItems, removeSpacing }) => {
  /**
   * Convert the children to an array to make them
   * easier to work with.
   */
  const childrenArray = React.Children.toArray(children);

  const spacingModifier = removeSpacing ? "row--nospacing" : "";

  return (
    <div className={`row ${spacingModifier}`} style={{ alignItems }}>
      {childrenArray.map((child) => {
        return <div className="row__item">{child}</div>;
      })}
    </div>
  );
};

export default Row;
