import "./Spinner.scss";
import React from "react";

interface SpinnerProps {
  /**
   * The amount of vertical space the spinner should take up.
   * Spinner doesn't actually scale, it just sits in the center
   * of the designated space. Minimum height is 140px.
   */
  height?: number;
}

/**
 * A spinning component to be show that content is loading.
 */
const Spinner: React.VFC<SpinnerProps> = ({ height = "auto" }) => {
  return (
    <div className="spinner" style={{ height: height }}>
      <div className="spinner__body">
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
        <div className="spinner__bar"></div>
      </div>
    </div>
  );
};

export default Spinner;
