import { useSelector } from "react-redux";
import { getClaims } from "../api/claim";
import { ApplicationState } from "../store";
import { ClaimData } from "../types/claims";
import { ConfigPlan } from "../types/plans";

const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365;

/**
 *
 * @param petId ID of the pet to get claims for
 * @returns a map of the plan type and how many claims they have remaining for that type (or unlimited)
 */
export const useRemainingClaims = (petId: string) => {
  const plans = useSelector((state: ApplicationState) =>
    state.global?.planConfig ? state.global?.planConfig.plans : []
  );
  const pets = useSelector((state: ApplicationState) => state?.user?.pets);
  const pet = pets?.find((p) => p._id === petId);

  const planName = pet ? pet.plan : "NoPlan";
  const plan = plans.find((p: ConfigPlan) => p.planKey === planName)!;

  // Get remaining number of vet claims
  async function getRemainingClaims() {
    if (!pet) throw new TypeError("Pet could not be found.");
    if (planName == "NoPlan") return new Map();
    // Find datetime since plan started
    const planStartDate = pet.planStart;
    const desexEligible =
      new Date().getTime() - new Date(pet.birthday).getTime() < YEAR_IN_MS &&
      pet.desexed === "not desexed";
    const allClaims: ClaimData[] = await getClaims(pet.claims);

    const map = new Map();
    // get all claims and add them to the map
    for (const claim of allClaims) {
      if (claim.status === "rejected" || planStartDate >= claim.date) continue;
      if (map.has(claim.type)) {
        map.set(claim.type, map.get(claim.type) + 1);
      } else {
        map.set(claim.type, 1);
      }
    }
    if (plan) {
      if (plan.planKey.includes("ThrivePlus"))
        plan.benefits = { ...plan.benefits, desex: 1 };
      // get all the available claims that plan has and subtract the no. claims made
      for (const [type, amount] of Object.entries(plan.benefits)) {
        if (amount === "Unlimited") {
          map.set(type, "Unlimited");
        } else if (map.has(type)) {
          map.set(type, amount - map.get(type));
        } else {
          map.set(type, amount);
        }
      }
    }
    // means a claim has been made for desex this year
    if (desexEligible || map.get("desex") === 0) {
      map.delete("dental");
    } else {
      map.delete("desex");
    }
    //console.log({ claims: map });
    return map;
  }
  return {
    getRemainingClaims,
  };
};
