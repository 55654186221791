import { formatMobileNumber } from "./mobile-number";
import { validate } from "./validate";

type FV = (
  value: string,
  inputName: string,
  customMesage?: string
) => {
  [key: string]: string;
};

/**
 *
 */
export const isNotEmpty: FV = (value, inputName, customMessage) => {
  const message = customMessage || "This field is required.";
  if (!validate.isNotEmpty(value)) {
    return { [inputName]: message };
  }
  return {};
};

/*
 * contains only numbers and -, should be 6 length
 */
export const isValidBSB: FV = (value, inputName) => {
  const checkChar = /[0-9]|-/g.test(value);
  const checkLength = value.replace(/[^0-9]/g, "").length;
  if (!checkChar || checkLength != 6) {
    return { [inputName]: "Enter a valid BSB" };
  }
  return {};
};

/*
 * contains only numbers and -
 */
export const isValidAccountNumber: FV = (value, inputName, customMessage) => {
  const checkChar = /[0-9]|-/g.test(value);
  const checkLength = value.replace(/[^0-9]/g, "").length;
  if (!checkChar) {
    return { [inputName]: "Enter a valid Account Number" };
  }
  return {};
};

/**
 *
 */
export const isFloat: FV = (value, inputName, customMessage) => {
  const message = customMessage || "Please provide a valid number.";
  if (!validate.isFloat(value)) {
    return { [inputName]: message };
  }
  return {};
};

/**
 *
 */
export const isInteger: FV = (value, inputName, customMessage) => {
  const message = customMessage || "Please provide a valid number.";
  if (!validate.isInteger(value)) {
    return { [inputName]: message };
  }
  return {};
};

/**
 *
 */
export const isEmail: FV = (value, inputName, customMessage) => {
  const message = customMessage || "Please provide a valid email address.";
  if (!validate.isEmail(value)) {
    return { [inputName]: message };
  }
  return {};
};

/**
 * value requires a string that includes the country code appended to
 * the front in brackets i.e. (+61)
 */
export const isMobile: FV = (value, inputName, customMessage) => {
  const message = customMessage || "Please provide a valid number.";
  if (
    (value.includes("(+61)") && formatMobileNumber(value).length !== 9) ||
    (value.includes("(+64)") &&
      [7, 8, 9, 10].indexOf(formatMobileNumber(value).length) < 0)
  ) {
    return { [inputName]: message };
  }
  return {};
};
/**
 *
 */
export const isValidCountry: FV = (value, inputName, customMessage) => {
  const message =
    customMessage ||
    "You must be located in Australia or New Zealand to sign up.";
  if (!validate.isValidCountry(value)) {
    return { [inputName]: message };
  }
  return {};
};
/**
 *
 */
export const isValidPassword: FV = (value, inputName, customMessage) => {
  const message =
    customMessage || "Password must be at least 5 characters long.";
  if (value.length < 5) {
    return { [inputName]: message };
  }
  return {};
};

export const isEqualValue = (
  value: string,
  equalValue: string,
  inputName: string,
  customMessage?: string
) => {
  const message = customMessage || "Please confirm email address";
  if (value !== equalValue) {
    return { [inputName]: message };
  }
  return {};
};

/**
 *
 */
export const isInPast: FV = (value, inputName, customMessage) => {
  const message = customMessage || "Please provide a date in the past.";
  if (!validate.isInPast(value)) {
    return { [inputName]: message };
  }
  return {};
};
