import { useEffect, useState } from "react";
import { CountryCode } from "../types/country";
import useCountryCode from "./useCountryCode";
const auStorefrontToken = process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN_AU!;
const auStorefrontUri = process.env.REACT_APP_SHOPIFY_STOREFRONT_URI_AU!;
const nzStorefrontToken = process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN_NZ!;
const nzStorefrontUri = process.env.REACT_APP_SHOPIFY_STOREFRONT_URI_NZ!;

type StorefrontUri = string;
type StorefrontToken = string;

/**
 * Returns the correct Shopify storefront URI and token
 * according to the country saved to their account.
 */
const useShopifyUri = (): [StorefrontUri, StorefrontToken] => {
  const [storefrontUri, setStorefrontUri] = useState("");
  const [storefrontToken, setStorefrontToken] = useState("");

  /**
   * Get the country code of the user.
   */
  const countryCode = useCountryCode();

  /**
   * Configure the correct storefront details
   * according to the user's country code.
   */
  useEffect(() => {
    switch (countryCode) {
      case CountryCode.AU:
        setStorefrontUri(auStorefrontUri);
        setStorefrontToken(auStorefrontToken);
        break;
      case CountryCode.NZ:
        setStorefrontUri(nzStorefrontUri);
        setStorefrontToken(nzStorefrontToken);
        break;
      default:
        setStorefrontUri("");
        setStorefrontToken("");
    }
  }, [countryCode]);

  return [storefrontUri, storefrontToken];
};

export default useShopifyUri;
