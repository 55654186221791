import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  changePassword,
  sendPasswordVerification,
} from "../../api/authorisation";
import { Button, Spinner } from "../../components/common";
import CodeInputForm from "../../components/form/CodeInputForm/CodeInputForm";
import EmailForm from "../../components/form/EmailForm/EmailForm";
import {
  Card,
  Container,
  Page,
  Section,
  Stepper,
} from "../../components/layout";
import { ApplicationState } from "../../store";
import history from "../../utils/history";
import { isEmail } from "../../utils/formik-validate";

const PasswordReset: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Page title="Reset Password">
      <Section>
        <Container>
          <Card>
            <h1> Reset Password</h1>
            <p className="error">
              Please ensure you check your junk mail or promotional mail to
              access this verification code. From time to time this automated
              email will be filtered by your email provider directly into one of
              these folders.
            </p>
            <Stepper
              currentIndex={currentIndex}
              customStepLabels={["Enter Email", "Verify", "Complete"]}
            >
              {!loading ? (
                <EmailForm
                  passEmail={async (value, skip) => {
                    setLoading(true);
                    const errors = { ...isEmail(value, "email") };
                    //console.log("Errors", errors);
                    if (Object.keys(errors).length > 0) {
                      toast.error(errors?.email);
                      return setLoading(false);
                    }
                    if (!skip) {
                      const response = await sendPasswordVerification(value);
                      if (response.pass === true) {
                        setEmail(value);
                        setCurrentIndex(currentIndex + 1);
                        setLoading(false);
                      } else {
                        setLoading(false);
                        toast.error(response.reason);
                      }
                    } else {
                      setLoading(false);
                      setEmail(value);
                      setCurrentIndex(currentIndex + 1);
                    }
                  }}
                />
              ) : (
                <Spinner />
              )}
              {!loading ? (
                <CodeInputForm
                  email={email}
                  code=""
                  step={async (code, newPassword) => {
                    if (newPassword) {
                      setLoading(true);
                      const res = await changePassword(
                        email,
                        newPassword,
                        code
                      );
                      if (res.pass === true) {
                        setPassword(password);
                        setCurrentIndex(currentIndex + 1);
                        setLoading(false);
                      } else {
                        setLoading(false);
                        toast.error(res.reason);
                      }
                    }
                  }}
                  formType="password"
                  changedPassword=""
                />
              ) : (
                <Spinner />
              )}
              <div>
                <h1> Password Successfully Changed </h1>
                {!isAuthenticated && (
                  <>
                    <p> You can now login with your new password. </p>

                    <Button
                      label="Return to Login"
                      onClick={async () => {
                        history.push("/login");
                      }}
                    />
                  </>
                )}
                {isAuthenticated && (
                  <>
                    <p> Next time you login, please use your new password. </p>
                    <Button
                      label="Return Home"
                      onClick={async () => {
                        history.push("/");
                      }}
                    />
                  </>
                )}
              </div>
            </Stepper>
          </Card>
        </Container>
      </Section>
    </Page>
  );
};

export default PasswordReset;
