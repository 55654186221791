import { Form } from "..";
import { useFormik } from "formik";
import { Row } from "../../layout";
import { TextInput, CodeInput } from "../../input";
import { Button } from "../../common";
import {
  isInteger,
  isNotEmpty,
  isValidPassword,
} from "../../../utils/formik-validate";
import { UserSignupData } from "../../../types/user";

interface VerificationCode {
  /**
   * signupData used for creating account
   */
  signupData?: UserSignupData;
  /**
   * email used for resetting password
   */
  email?: string;
  /**
   * code for validation
   */
  code: string;
  /**
   * this is used for stepping to the next page on the main form
   */
  step: (code: string, password?: string) => void;
  /**
   * to determine what components to render
   */
  formType: "password" | "email";
  /**
   * for updating password for password change
   */
  changedPassword: string;
}

const CodeInputForm: React.FC<VerificationCode> = ({
  signupData,
  step,
  formType,
  email,
}) => {
  const initialValues: VerificationCode = {
    signupData: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        province: "",
        postcode: "",
      },
      password: "",
      imageUrl: "",
      pets: [],
    },
    code: "",
    step,
    formType: "email",
    changedPassword: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (formType === "email") {
        step(values.code);
      } else if (formType === "password") {
        step(values.code, values.changedPassword);
      }
    },
    validate: async ({ code, changedPassword }) => {
      const errors =
        formType === "email"
          ? {
              ...isNotEmpty(code, "code"),
              ...isInteger(code, "code"),
            }
          : {
              ...isNotEmpty(code, "code"),
              ...isInteger(code, "code"),
              ...isNotEmpty(changedPassword, "changedPassword"),
              ...isValidPassword(changedPassword, "changedPassword"),
            };
      return errors;
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      {formType === "email" && signupData && (
        <>
          <Row>
            <CodeInput
              value={formik.values.code}
              onChange={formik.handleChange("code")}
              email={signupData.email}
              name="verification"
              inputMode="numeric"
              type="number"
              fields={6}
              formType="email"
              error={formik.errors.code}
              touched={formik.touched.code}
            />
          </Row>
        </>
      )}
      {formType === "password" && email && (
        <>
          <Row>
            <CodeInput
              value={formik.values.code}
              onChange={formik.handleChange("code")}
              email={email}
              name="verification"
              inputMode="numeric"
              type="number"
              fields={6}
              formType="password"
            />
          </Row>
          <Row>
            <TextInput
              label="New Password"
              value={formik.values.changedPassword}
              error={formik.errors.changedPassword}
              touched={formik.touched.changedPassword}
              type="password"
              onChange={formik.handleChange("changedPassword")}
              required
            />
          </Row>
        </>
      )}
      <Button label="Submit" onClick={formik.handleSubmit} />
    </Form>
  );
};
export default CodeInputForm;
