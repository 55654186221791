import { useSelector } from "react-redux";
import { ApplicationState } from "../store";

const useSelectedPet = () => {
  const selectedPet = useSelector((state: ApplicationState) =>
    state?.user?.pets?.find((pet) => pet._id === state?.global?.selectedPet)
  );

  return selectedPet;
};

export default useSelectedPet;
