import "./Onboarding.scss";
import {
  OnboardingHeader,
  OnboardingMenus,
  TermsConditions,
  truckIcon,
  chatIcon,
  hospitalIcon,
  injectionIcon,
  stethoscopeIcon,
  toothbrushIcon,
  desexedIcon,
  storeIcon,
  calculatorIcon,
  faqIcon,
  checkIcon,
  THRIVING_URL,
} from "./Module";
import history from "../../utils/history";
import { useEffect, useState, useRef } from "react";
import { Row, Modal } from "../../components/layout";

const Onboarding: React.FC = () => {
  const [startDate, setStartDate] = useState<any>(new Date());
  const [show, setShow] = useState({
    monthly: false,
    yearly: true,
    terms: false,
  });
  const OnboardingRender = () => {
    const dateToday = () => {
      const today = new Date();
      const year = today.getFullYear();
      let mo: any = today.getMonth() + 1;
      let day: any = today.getDate();
      if (day < 10) day = `0${day}`;
      if (mo < 10) mo = `0${mo}`;
      return day + "/" + mo + "/" + year;
    };
    return (
      <div className="content">
        <OnboardingHeader step={1} stepTotal={5} />
        <div className="content-box">
          <OnboardingMenus active="" />
          <h2 className="title-2">
            Welcome to Thriving Pets.
            <span>A simple & affordable pet wellness plan.</span>
          </h2>
          <div className="row column two">
            <div className="box card primary">
              <h3 className="header">Wellness Plan Inclusions</h3>
              <div className="card-content">
                <div className="icon-content left-align">
                  <img className="icon" src={truckIcon} />
                  <p className="icon-text">
                    Flea, Tick & Worming Home Delivered
                    <span className="subtext">
                      (Sent automatically to you at the end of each relevant
                      month)
                    </span>
                  </p>
                </div>
                <div className="icon-content left-align">
                  <img className="icon" src={hospitalIcon} />
                  <p className="icon-text">
                    Unlimited Consultations at any Vet Clinic
                    <span className="subtext">
                      (Claim up to $75 per consultation)
                    </span>
                  </p>
                </div>
                <div className="icon-content left-align">
                  <img className="icon" src={injectionIcon} />
                  <p className="icon-text">
                    Annual Vaccination
                    <span className="subtext">(Claim up to $100)</span>
                  </p>
                </div>
                <div className="icon-content left-align">
                  <img className="icon" src={stethoscopeIcon} />
                  <p className="icon-text">
                    $100 Wellness Screen Allowance
                    <span className="subtext">
                      (Wellness blood test as recommended by your Vet)
                    </span>
                  </p>
                </div>
                <div className="icon-content left-align or-option">
                  <img className="icon" src={toothbrushIcon} />
                  <p className="icon-text">
                    $100 Dental Allowance
                    <span className="subtext">
                      (From 12 months of age onwards)
                    </span>
                  </p>
                </div>
                <div className="icon-content left-align">
                  <img className="icon" src={desexedIcon} />
                  <p className="icon-text">
                    $100 Desexing Allowance
                    <span className="subtext">(Up to 12 months of age)</span>
                  </p>
                </div>
                <div className="icon-content left-align">
                  <img className="icon" src={storeIcon} />
                  <p className="icon-text">
                    10% Online Pet Store Discount
                    <span className="subtext">
                      (Visit yourpetpa.com.au. Terms and Conditions apply).
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="box card secondary">
              <h3 className="header">Wellness Plan Pricing</h3>
              <div className="card-content">
                <div className="membership-tab-pricing">
                  <ul className="tab">
                    <li
                      className={show.yearly ? "active" : ""}
                      onClick={() => {
                        setShow({ ...show, yearly: true, monthly: false });
                      }}
                    >
                      Yearly
                    </li>
                    <li
                      className={show.monthly ? "active" : ""}
                      onClick={() => {
                        setShow({ ...show, yearly: false, monthly: true });
                      }}
                    >
                      Monthly
                    </li>
                  </ul>
                  <div className={show.yearly ? "yearly content" : "none"}>
                    <p className="total amount">
                      <span className="price">
                        $ 590 <span className="regular">$655</span>
                      </span>
                      per year
                    </p>
                    <p className="total desc">
                      <span className="label">Total:</span>$590, paid once
                      annually
                    </p>
                    <ul className="list check">
                      <li>
                        <img className="icon" src={checkIcon} />
                        Plan Paid Yearly (12 Month Membership)
                      </li>
                      <li>
                        <img className="icon" src={checkIcon} />
                        One time payment of: $590
                      </li>
                      <li>
                        <img className="icon" src={checkIcon} />
                        Membership start date: {dateToday()}
                      </li>
                      <li>
                        <img className="icon" src={checkIcon} />
                        Total value of contract: $590
                      </li>
                    </ul>
                  </div>
                  <div className={show.monthly ? "monthly content" : "none"}>
                    <p className="total amount">
                      <span className="price">$ 54.50</span>
                      per month
                    </p>
                    <ul className="list check">
                      <li>
                        <img className="icon" src={checkIcon} />
                        Plan Paid Monthly (Period of 12 Months)
                      </li>
                      <li>
                        <img className="icon" src={checkIcon} />
                        12 monthly payments of: $54.50
                      </li>
                      <li>
                        <img className="icon" src={checkIcon} />
                        First payment date: {dateToday()}
                      </li>
                      <li>
                        <img className="icon" src={checkIcon} />
                        Total value of contract: $654
                      </li>
                    </ul>
                  </div>
                </div>
                <p>
                  <a
                    href={`${THRIVING_URL}/thriving-pets/`}
                    className="btn bordered-purple btn-white"
                  >
                    <img src={calculatorIcon} />
                    Savings Calculator
                  </a>
                </p>
                <p>
                  <a
                    href={`${THRIVING_URL}/faqs/`}
                    className="btn btn-white bordered-purple"
                  >
                    <img src={faqIcon} />
                    View our FAQ
                  </a>
                </p>
                <p>
                  <br />
                  Please review the &nbsp;
                  <a onClick={() => setShow({ ...show, terms: true })}>
                    terms and conditions.
                  </a>{" "}
                  Membership is on annual basis commencing from the date of
                  subscription. A renewal notice will be sent at least one month
                  prior to the expiry of the annual membership. Memberships paid
                  monthly automatically renews for a further twelve-month period
                  unless the member notifies Thriving Pets otherwise.
                </p>
              </div>
            </div>
          </div>
          <Modal
            isOpen={show.terms}
            closeModal={() => setShow({ ...show, terms: false })}
          >
            <TermsConditions />
          </Modal>
        </div>
        <p>
          <button
            className="btn btn-purple square"
            onClick={() => {
              history.push("/onboarding/account-setup");
            }}
          >
            Start Membership
          </button>
        </p>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {OnboardingRender()}
    </div>
  );
};
export default Onboarding;
