import React, { useEffect, useState } from "react";
import Form from "../Form/Form";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { AddressInput, TextInput } from "../../input";
import { Button, Spinner } from "../../common";
import {
  isInteger,
  isMobile,
  isNotEmpty,
} from "../../../utils/formik-validate";
import { actionCreators } from "../../../store/User";
import { bindActionCreators } from "redux";
import { UserUpdateData } from "../../../types/user";
import { Row } from "../../layout";
import { PhoneInput } from "../../input";
import { formatMobileNumber, stripCountry } from "../../../utils/mobile-number";
import history from "../../../utils/history";
const UserDetailsForm: React.VFC = () => {
  const { updateUserData, fetchUserData } = bindActionCreators(
    actionCreators,
    useDispatch()
  );

  const user = useSelector((state: ApplicationState) => state.user);
  const [countryCode, setCountryCode] = useState("+61");

  /**
   * Form initial values.
   */
  const getInitialValues = () => {
    return {
      phone: user?.phone ? stripCountry(user?.phone) : "",
      email: user?.email || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      address1: user?.address.address1 || "",
      address2: user?.address.address2 || "",
      city: user?.address.city || "",
      state: user?.address.state || "",
      country: user?.address.country || "",
      province: user?.address.province || "",
      postcode: user?.address.postcode || "",
      address: user?.address || "",
      interests: user?.interests || [],
      imageUrl: user?.imageUrl || "",
    };
  };

  let initialValues = getInitialValues();

  useEffect(() => {
    initialValues = getInitialValues();
  }, [user]);

  /**
   * Create and configure the formik form.
   */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: ({
      firstName,
      lastName,
      address1,
      city,
      state,
      country,
      postcode,
      phone,
    }) => {
      return {
        ...isNotEmpty(firstName, "firstName"),
        ...isNotEmpty(lastName, "lastName"),
        ...isNotEmpty(address1, "address1"),
        ...isNotEmpty(city, "city"),
        ...isNotEmpty(state, "state"),
        ...isNotEmpty(country, "country"),
        ...isNotEmpty(postcode, "postcode"),
        ...isNotEmpty(phone, "phone"),
        ...isInteger(formatMobileNumber(phone), "phone"),
        ...isMobile(`(${countryCode})${phone}`, "phone"),
      };
    },
    onSubmit: async (values) => {
      const updateData: UserUpdateData = {
        phone: `(${countryCode})${
          values.phone.includes("+") ? stripCountry(values.phone) : values.phone
        }`,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        address: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          country: values.country,
          province: values.province,
          postcode: values.postcode,
        },
        interests: values.interests,
        imageUrl: values.imageUrl,
      };
      await updateUserData(updateData);
      fetchUserData();
    },
  });

  if (user) {
    return (
      <Form onSubmit={() => {}}>
        <TextInput
          label="Email"
          value={formik.values.email}
          onChange={() => {}}
          disabled
        />
        <Row>
          <TextInput
            label="First Name"
            value={formik.values.firstName}
            error={formik.errors.firstName}
            touched={formik.touched.firstName}
            onChange={formik.handleChange("firstName")}
          />
          <TextInput
            label="Last Name"
            value={formik.values.lastName}
            error={formik.errors.lastName}
            touched={formik.touched.lastName}
            onChange={formik.handleChange("lastName")}
          />
        </Row>
        <PhoneInput
          label="Phone"
          value={stripCountry(formik.values.phone)}
          onChange={formik.handleChange("phone")}
          error={formik.errors.phone}
          touched={formik.touched.email}
          country={formik.values.country}
          onRegionSelect={(code: string) => setCountryCode(code)}
        />
        <AddressInput form={formik} />
        <div>
          <Row removeSpacing>
            <Button label="Update" onClick={formik.handleSubmit} />
            <Button
              type="danger"
              label="Reset Password"
              onClick={() => history.push("/password/reset")}
            />
          </Row>
        </div>
      </Form>
    );
  } else {
    return <Spinner />;
  }
};

export default UserDetailsForm;
