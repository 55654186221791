import React from "react";
import { useSelector } from "react-redux";
import { Accordian } from "../../components/layout";
import { ApplicationState } from "../../store";
import { Spinner } from "../../components/common";
import UserDetailsForm from "../../components/form/UserDetailsForm/UserDetailsForm";
import icon from "../../assets/icons/my details.svg";

const MyDetails: React.VFC = () => {
  const userState = useSelector((state: ApplicationState) => state.user);

  if (userState) {
    return (
      <Accordian title="My Details" iconUrl={icon}>
        <UserDetailsForm />
      </Accordian>
    );
  } else {
    return <Spinner />;
  }
};

export default MyDetails;
