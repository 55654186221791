import "./Dashboard.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useFormik } from "formik";
import { isNotEmpty, isValidBSB } from "../../utils/formik-validate";
import { TextInput } from "../../components/input";
import { Button } from "../../components/common";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import { thrivingPetsLogoDarkReverse } from "./Module";

const EditPaymentDetails: React.FC = () => {
  const location = useLocation();
  const { fetchUserData, updateUserBankInfo } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [isLoading, setIsLoading] = useState(false);
  const [userBank, setUserBank] = useState({
    accountName: "",
    acc: "",
    bsb: "",
  });
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding/");
    fetchUserData();
    /*(async () => {
      const url = await getPaymentMethods();
      setChargebeeURL(url);
      console.log({ url, user });
    })();*/
    if (!user || !user.bank) return;
    const bank: any = user.bank;
    bank.bsb = !isValidBSB(bank.bsb, "bsb").bsb ? bank.bsb : "";
    if (bank) setUserBank(bank);
  }, [isAuthenticated, location]);
  const EditBank = useFormik({
    enableReinitialize: true,
    initialValues: userBank,
    validate: async ({ accountName, acc, bsb }) => {
      let errors = {
        ...isNotEmpty(accountName, "accountName"),
        ...isNotEmpty(acc, "acc"),
        ...isNotEmpty(bsb, "bsb"),
        ...isValidBSB(bsb, "bsb"),
      };
      if (user?.address?.country != "Australia")
        errors = {
          ...isNotEmpty(accountName, "accountName"),
          ...isNotEmpty(acc, "acc"),
        };
      return errors;
    },
    onSubmit: async (values) => {
      const { accountName, acc, bsb } = values;
      const bank = { accountName, acc, bsb };
      console.log({ bank });
      setIsLoading(true);
      await updateUserBankInfo(bank);
      await fetchUserData();
      toast.success(`Bank information updated!`);
      setIsLoading(false);
      history.push("/dashboard");
    },
  });
  /*
   * RENDERING
   */
  return (
    <div className="dashboard">
      <div className="content-header">
        <div className="flex align-center">
          <img src={thrivingPetsLogoDarkReverse} />
        </div>
      </div>
      <div className="content-body">
        <div className="field">
          <h1>Payment Details</h1>
        </div>
        <h3>BANK DETAILS FOR CLAIMS/REBATES</h3>
        <div className="field flex flex-start">
          <TextInput
            label="Account Name"
            value={EditBank.values.accountName}
            onChange={EditBank.handleChange("accountName")}
            touched={true}
            error={EditBank.errors.accountName}
          />
        </div>
        <div className="field flex flex-start">
          <TextInput
            label="Account Number"
            value={EditBank.values.acc}
            onChange={EditBank.handleChange("acc")}
            touched={true}
            error={EditBank.errors.acc}
          />
          {user?.address?.country == "Australia" && (
            <TextInput
              label="BSB"
              value={EditBank.values.bsb}
              onChange={EditBank.handleChange("bsb")}
              touched={true}
              error={EditBank.errors.bsb}
            />
          )}
        </div>
      </div>
      <div className="content-footer">
        <p>
          <Button
            label="Update"
            customClass="button btn btn-purple square"
            isDisabled={isLoading}
            onClick={() => {
              EditBank.handleSubmit();
            }}
          />
          <Button
            label="Cancel"
            customClass="button btn btn-grey square"
            onClick={() => history.push("/dashboard")}
          />
        </p>
      </div>
    </div>
  );
};

export default EditPaymentDetails;
