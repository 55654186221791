import { MenuItem } from "./Footer";

const THRIVING_URL =
  window.location.host != "account.thrivingpets.co.nz"
    ? "https://thrivingpets.com.au"
    : "https://thrivingpets.co.nz";
/**
 * Menu links to map over when rending menus.
 */
export const menuLinks: { [key: string]: MenuItem[] } = {
  quickLinks: [
    {
      label: "Inclusions",
      href: `${THRIVING_URL}/thriving-pets-inclusions/`,
    },
    { label: "Pricing", href: `${THRIVING_URL}/thriving-pets-pricing/` },
    {
      label: "Savings Calculator",
      href: `${THRIVING_URL}/thriving-pets/`,
    },
    {
      label: "Join Today",
      href: `/onboarding`,
    },
  ],
  help: [
    {
      label: "Contact Us",
      href: `${THRIVING_URL}/contact-us/`,
    },
    {
      label: "Thriving Pets Plans Terms And Conditions",
      href: `${THRIVING_URL}/thriving-pets-terms-and-conditions/`,
    },
    {
      label: "Shipping Policy",
      href: `${THRIVING_URL}/shipping-policy/`,
    },
    {
      label: "Privacy Policy",
      href: `${THRIVING_URL}/privacy-policy/`,
    },
    {
      label: "Security Policy",
      href: `${THRIVING_URL}/security-policy/`,
    },
    {
      label: "Faq's",
      href: `${THRIVING_URL}/faqs/`,
    },
  ],
};
