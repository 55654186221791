import MyAppointments from "./MyAppointments";
import MyClaims from "./MyClaims";
import MyDetails from "./MyDetails";
import MyPayments from "./MyPayments";
import MySubscriptions from "./MySubscriptions";
import HomeTemplate from "../../components/templates/HomeTemplate";
import bookChatIcon from "../../assets/icons/headset.svg";
import shopIcon from "../../assets/icons/shop.svg";
import { AccordianLookingButton } from "../../components/common";
import { Row } from "../../components/layout";
import history from "../../utils/history";

const YPPA_DOMAIN =
  window.location.host != "account.thrivingpets.co.nz"
    ? "yourpetpa.com.au"
    : "yourpetpa.co.nz";
const Home = () => {
  return (
    <HomeTemplate title="Home">
      <MyDetails />
      <MySubscriptions />
      <MyClaims />
      <MyPayments />
      <Row>
        <AccordianLookingButton
          title="Shop online at www.yourpetpa.com.au"
          icon={shopIcon}
          onClick={() => {
            window.location.href = `https://${YPPA_DOMAIN}/discount/Thriving/?redirect=/`;
          }}
        >
          <p style={{ fontSize: "18px", paddingRight: "20px" }}>
            Enjoy 10% off all purchases (excluding parasite prevention and
            pharmacy medications) at www.yourpetpa.com.au. You can purchase all
            your pet’s food, vitamins, supplements, and other pet care products.
            You will need to create an account at yourpetpa.com.au using the
            same email address as your Thriving Pets account and use the
            promotional code <b>' Thriving '</b> during the checkout process.
          </p>
        </AccordianLookingButton>
      </Row>
    </HomeTemplate>
  );
};

export default Home;
