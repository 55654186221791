import "./Onboarding.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { sendEmailVerification } from "../../api/authorisation";
import { useFormik } from "formik";
import {
  tapfiliateGetAffiliatesAU,
  tapfiliateGetAffiliatesNZ,
} from "../../api/tapfiliate";
import {
  isNotEmpty,
  isEmail,
  isValidPassword,
  isEqualValue,
} from "../../utils/formik-validate";
import { InputOption } from "../../types/inputs";
import { Row, Modal } from "../../components/layout";
import { TextInput, DropdownInput } from "../../components/input";
import { ApplicationState } from "../../store";
import { actionCreators } from "../../store/Auth";
import { actionCreators as userActionCreators } from "../../store/User";
import { UserOnboardingData } from "../../types/user";
import history from "../../utils/history";
import {
  OnboardingHeader,
  OnboardingMenus,
  arrowLeftIcon,
  arrowRightIcon,
} from "./Module";

const AccountRegistration: React.FC = () => {
  const recaptchaRef = useRef(null);
  const [recaptchaState, setRecaptchaState] = useState<string | null>(null);
  const [reload, setReload] = useState(false);
  const [showCaptcha, setshowCaptcha] = useState(false);
  const [affiliateOptions, setAffiliateOptions] = useState<InputOption[]>([]);
  const [isLoadingTAffiliate, setisLoadingTAffiliate] = useState(true);
  const [referrer, setReferrer] = useState<{
    code: string;
    name: string | undefined;
  }>({ code: "--", name: "" });

  const { fetchUserToken, createUserOnboarding } = bindActionCreators(
    actionCreators,
    useDispatch()
  );
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const Encrypt = (salt: string, text: string) => {
    const textToChars = (t: string) => t.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n: number) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code: any) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  };
  useEffect(() => {
    if (isAuthenticated) history.push("/onboarding/pet-parent-info");
  }, [isAuthenticated]);
  useEffect(() => {
    console.log(window.location.host);
    if (
      window.location.host == "app.yourpetpa.co.nz" ||
      window.location.host == "account.thrivingpets.co.nz"
    ) {
      tapfiliateGetAffiliatesNZ().then((affiliates) => {
        if (affiliates && affiliates.length) {
          const formattedOptions = affiliates.map((affiliate) => ({
            value: affiliate.id,
            label: affiliate.name,
          }));
          setAffiliateOptions([
            { label: "No referrer", value: "--" },
            ...formattedOptions,
          ]);
          setisLoadingTAffiliate(false);
        }
      });
    } else {
      tapfiliateGetAffiliatesAU().then((affiliates) => {
        console.log({ affiliates });
        if (affiliates && affiliates.length) {
          const formattedOptions = affiliates.map((affiliate) => ({
            value: affiliate.id,
            label: affiliate.name,
          }));
          setAffiliateOptions([
            { label: "No referrer", value: "--" },
            ...formattedOptions,
          ]);
          setisLoadingTAffiliate(false);
        }
      });
    }
  }, []);
  const AccountSetup = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country:
          window.location.host != "account.thrivingpets.co.nz"
            ? "Australia"
            : "New Zealand",
        province: "",
        postcode: "",
      },
    },
    onSubmit: async (values) => {
      values.email = values.email.toLocaleLowerCase();
      values.confirmEmail = values.confirmEmail.toLocaleLowerCase();
      //return setshowCaptcha(true);
      const verification = await sendEmailVerification(values.email);
      console.log({ verification });
      if (verification.pass === true) {
        setshowCaptcha(true);
      } else {
        toast.error(verification.reason);
      }
      //setshowCaptcha(true);
      //setLoading(true);
      //await addPet(values);
      //await fetchUserData();
      //setLoading(false);
      //history.push("/pet/add/successful");
    },
    validate: async ({
      firstName,
      lastName,
      email,
      confirmEmail,
      password,
    }) => {
      const errors = {
        ...isNotEmpty(firstName, "firstName"),
        ...isNotEmpty(lastName, "lastName"),
        ...isNotEmpty(email, "email"),
        ...isNotEmpty(confirmEmail, "confirmEmail"),
        ...isNotEmpty(password, "password"),
        ...isEmail(email, "email"),
        ...isEmail(email, "confirmEmail"),
        //...isMobile(`(${countryCode})${phone}`, "phone"),
        //...isInteger(formatMobileNumber(phone), "phone"),
        //...isInteger(postcode, "postcode"),
        //...isValidCountry(country, "country"),
        ...isValidPassword(password, "password"),
        ...isEqualValue(email, confirmEmail, "confirmEmail"),
      };
      return errors;
    },
  });
  const AccountSetupRender = () => {
    return (
      <div className="content">
        <OnboardingHeader step={2} stepTotal={5} />
        <div className="content-box">
          <OnboardingMenus active="account-setup" />
          <h2 className="title-2">
            Account setup for Thriving Pets Membership.
          </h2>
          <Row>
            <TextInput
              label="First Name"
              value={AccountSetup.values.firstName}
              error={AccountSetup.errors.firstName}
              touched={AccountSetup.touched.firstName}
              onChange={AccountSetup.handleChange("firstName")}
              required
            />
            <TextInput
              label="Last Name"
              value={AccountSetup.values.lastName}
              error={AccountSetup.errors.lastName}
              touched={AccountSetup.touched.lastName}
              onChange={AccountSetup.handleChange("lastName")}
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Email"
              value={AccountSetup.values.email}
              error={AccountSetup.errors.email}
              touched={AccountSetup.touched.email}
              onChange={AccountSetup.handleChange("email")}
              required
            />
            <TextInput
              label="Confirm Email"
              value={AccountSetup.values.confirmEmail}
              error={AccountSetup.errors.confirmEmail}
              touched={AccountSetup.touched.confirmEmail}
              onChange={AccountSetup.handleChange("confirmEmail")}
              required
            />
          </Row>
          <Row>
            <TextInput
              label="Create Password*"
              value={AccountSetup.values.password}
              error={AccountSetup.errors.password}
              touched={AccountSetup.touched.password}
              onChange={AccountSetup.handleChange("password")}
              type="password"
              required
            />
            <div className="form-group"></div>
          </Row>
          <div className="row">
            <p>
              Were you referred by a veterinary practice or another pet
              provider? Select your referrer from the list below. If this
              doesn't apply to you, please select 'No Referrer'.
            </p>
          </div>
          <Row>
            <DropdownInput
              label="Referrer"
              isLoading={isLoadingTAffiliate}
              options={affiliateOptions}
              onChange={(code) => {
                console.log({ code });
                if (code) {
                  const name = affiliateOptions?.find((v) => {
                    return v.value == code;
                  })?.label;
                  setReferrer({ code, name });
                }
              }}
              value={referrer.code}
            />
          </Row>
        </div>
        <p className="bottom-action text-center">
          <img
            src={arrowLeftIcon}
            onClick={() => history.push("/onboarding/")}
          />
          <button
            className="btn btn-purple square"
            onClick={() => {
              AccountSetup.handleSubmit();
            }}
          >
            Next
          </button>
          <img
            src={arrowRightIcon}
            onClick={() => history.push("/onboarding/pet-parent-info")}
          />
        </p>
      </div>
    );
  };
  const theCaptcha = () => {
    if (!showCaptcha) return;
    return (
      <div className="captcha">
        <div className="content-box">
          <span
            className="close"
            onClick={() => {
              setshowCaptcha(false);
            }}
          >
            x
          </span>
          <h2 className="title-2">Let us know that you aren't a bot.</h2>
          <ReCAPTCHA
            sitekey="6LezQ5YUAAAAAEKPX18beuQ-z6m-mNA8AZCpa4cS"
            onChange={async (value) => {
              if (!value) return;
              const code = Encrypt(
                "yppa-captcha-reference",
                AccountSetup.values.email
              );
              const { firstName, lastName, email, password, address } =
                AccountSetup.values;
              const { code: affiliateCode, name: affiliateName } = referrer;
              const data: UserOnboardingData = {
                firstName,
                lastName,
                email,
                password,
                address,
                affiliateCode,
                affiliateName,
              };
              console.log({ data, code });
              await createUserOnboarding(data, code);
              await fetchUserToken(email, password);
              history.push("/onboarding/pet-parent-info");
            }}
          />
        </div>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {AccountSetupRender()}
      {theCaptcha()}
    </div>
  );
};

export default AccountRegistration;
