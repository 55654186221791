import "./Onboarding.scss";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { Button } from "../../components/common";
import { PetOnboarding, PetSignupData } from "../../types/pet";
import { useFormik } from "formik";
import { Row } from "../../components/layout";
import { DeleteReason } from "../../types/pet";
import { isNotEmpty, isMobile } from "../../utils/formik-validate";
import {
  TextInput,
  DropdownInput,
  DateInput,
  ImageInput,
} from "../../components/input";
import {
  OnboardingHeader,
  OnboardingMenus,
  arrowLeftIcon,
  arrowRightIcon,
  dogAndCat,
  trashIcon,
  THRIVING_URL,
} from "./Module";
import ProvidedRadioInput from "../../components/input/ProvidedRadioInput/ProvidedRadioInput";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";

const PetInfo: React.FC = () => {
  const initialPets: PetOnboarding[] = Array.from(Array(Number(10)).keys()).map(
    (v) => {
      return {
        name: "",
        type: "dog",
        desexed: "desexed",
        gender: "male",
        weight: "",
        birthday: "",
        breed: "",
        color: "",
        imageUrl: "",
        image: "",
        plan: "NoPlan",
        onboarding: "yearly",
      };
    }
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const [reload, setReload] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [petsInitialize, setPetsInitialize] = useState(initialPets);
  const { fetchUserData, deletePetData, onBoardPet } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  //const user = useSelector((state: ApplicationState) => state.user);
  const petOptions = useSelector(
    (state: ApplicationState) => state.global?.petOptions
  );
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding");
    fetchUserData();
    if (
      (user && user.address && !user.address.address1) ||
      (user && !user.address)
    ) {
      alert("Missing required address");
      history.push("/onboarding/pet-parent-info");
    }
  }, [isAuthenticated]);
  const RegisterPet = useFormik({
    initialValues: {
      numberOfPets: "1",
      pets: petsInitialize,
    },
    onSubmit: async (data) => {
      console.log({ data });
      const numberOfPets = data.numberOfPets;
      let pets = data.pets;
      const errors: any = [];
      /*const pets =
        data.pets &&
        data.pets.filter((p) => {
          return p.name != "";
        });*/
      pets = pets.slice(0, Number(numberOfPets));
      console.log({ pets, numberOfPets });
      if (pets.length < 1) errors.push(`You have not entered any pets`);
      console.log({ pets });
      pets.map((pet, k) => {
        if (!pet.birthday || pet.birthday == "")
          errors.push(`Invalid Birthday for Pet#${k + 1}`);
        if (!pet.name || pet.name == "")
          errors.push(`Empty name for Pet#${k + 1}`);
        if (Number.isNaN(Number(pet.weight)) || Number(pet.weight) <= 0)
          errors.push(`Invalid weight for Pet#${k + 1}`);
        if (!pet.breed || pet.breed == "")
          errors.push(`Choose breed for Pet#${k + 1}`);
      });
      if (errors && errors.length > 0) {
        return errors.map((e: string) => {
          toast.error(e, {
            position: "bottom-center",
            autoClose: 5000,
          });
        });
      }
      setIsCompleted(true);
      await onBoardPet(pets);
      history.push("/onboarding/payment-completion");
    },
  });

  const noPlanPets = () => {
    const pets: any =
      user &&
      user.pets.filter((p: any) => {
        return p.plan == "NoPlan";
      });
    if (!pets || pets.length < 1) return;
    return (
      <div>
        <ul className="checkout-list unsubscribe-pets">
          {pets &&
            pets.map((p: any) => {
              //console.log({ p });
              return (
                <>
                  <li className="justify-center align-center">
                    <div className="flex align-center">
                      <img
                        style={{ width: "20px", cursor: "pointer" }}
                        src={trashIcon}
                        onClick={async () => {
                          if (p.plan != "NoPlan") return;
                          console.log(p._id);
                          if (!confirm(`Im sure to delete ${p.name}`)) return;
                          await deletePetData(p._id, DeleteReason.OTHER);
                          toast.error(`Deleting ${p.name} - ${p.breed}`);
                        }}
                      />
                      <img src={dogAndCat} />
                      <p className="text-capitalize">
                        <strong>No Subscription Plan </strong>
                        <br />
                        {p.name}, {p.type}, {p.gender}, {p.breed} - {""}
                        <Link to={"/dashboard/pets-plan"}>View Detail</Link>
                      </p>
                    </div>
                    <p>
                      <Link to={"/onboarding/payment-completion?id=" + p._id}>
                        Subscribe to a Plan
                      </Link>
                    </p>
                  </li>
                </>
              );
            })}
        </ul>
      </div>
    );
  };
  const PetInformation = () => {
    return (
      <div className="content">
        <OnboardingHeader step={4} stepTotal={5} />
        <div className="content-box">
          <OnboardingMenus active="pet-info" />
          <h2 className="title-2">Tell us about your pet(s)</h2>
          <Row>
            <DropdownInput
              label="Number of pets*"
              options={petsInitialize.map((o, k) => {
                const value = String(k + 1);
                return { label: value, value };
              })}
              value={RegisterPet.values.numberOfPets}
              onChange={RegisterPet.handleChange("numberOfPets")}
            />
          </Row>
          {Array.from(
            Array(Number(RegisterPet.values.numberOfPets)).keys()
          ).map((v, k) => {
            const key =
              RegisterPet.values.pets[v].type == "cat"
                ? "catBreeds"
                : "dogBreeds";
            const listBreeds: any =
              petOptions &&
              petOptions[key].map((o) => {
                return { label: o, value: o };
              });
            //console.log({ listBreeds });
            return (
              <>
                <h3 className="title-2 mb-0">Pet Info #{v + 1}</h3>
                <Row>
                  <ImageInput
                    onClickSave={(image) => {
                      RegisterPet.setFieldValue(`pets[${v}].image`, image);
                    }}
                  />
                </Row>
                <Row>
                  <TextInput
                    label="Pet Name"
                    value={RegisterPet.values.pets[v].name}
                    onChange={RegisterPet.handleChange(`pets[${v}].name`)}
                  />
                  <ProvidedRadioInput
                    label="Dog or Cat?"
                    options={[
                      {
                        key: "dog",
                        text: "",
                        Unselected: () => (
                          <Button
                            type="secondary"
                            label="Dog"
                            onClick={() => {}}
                          />
                        ),
                        Selected: () => (
                          <Button
                            type="primary"
                            label="Dog"
                            onClick={() => {}}
                          />
                        ),
                      },
                      {
                        key: "cat",
                        text: "",
                        Unselected: () => (
                          <Button
                            type="secondary"
                            label="Cat"
                            onClick={() => {}}
                          />
                        ),
                        Selected: () => (
                          <Button
                            type="primary"
                            label="Cat"
                            onClick={() => {}}
                          />
                        ),
                      },
                    ]}
                    value={RegisterPet.values.pets[v].type}
                    onChange={RegisterPet.handleChange(`pets[${v}].type`)}
                  />
                </Row>
                <Row>
                  <DateInput
                    label="Date of Birth"
                    value={RegisterPet.values.pets[v].birthday}
                    onChange={RegisterPet.handleChange(`pets[${v}].birthday`)}
                  />
                  <TextInput
                    label="Weight (kg)"
                    type="number"
                    value={RegisterPet.values.pets[v].weight}
                    onChange={RegisterPet.handleChange(`pets[${v}].weight`)}
                  />
                </Row>
                <Row>
                  <ProvidedRadioInput
                    label="Gender*"
                    options={[
                      {
                        key: "male",
                        text: "",
                        Unselected: () => (
                          <Button
                            type="secondary"
                            label="Male"
                            onClick={() => {}}
                          />
                        ),
                        Selected: () => (
                          <Button
                            type="primary"
                            label="Male"
                            onClick={() => {}}
                          />
                        ),
                      },
                      {
                        key: "female",
                        text: "",
                        Unselected: () => (
                          <Button
                            type="secondary"
                            label="Female"
                            onClick={() => {}}
                          />
                        ),
                        Selected: () => (
                          <Button
                            type="primary"
                            label="Female"
                            onClick={() => {}}
                          />
                        ),
                      },
                    ]}
                    value={RegisterPet.values.pets[v].gender}
                    onChange={RegisterPet.handleChange(`pets[${v}].gender`)}
                  />
                  <ProvidedRadioInput
                    label="Desexed*"
                    options={[
                      {
                        key: "desexed",
                        text: "",
                        Unselected: () => (
                          <Button
                            type="secondary"
                            label="Desexed"
                            onClick={() => {}}
                          />
                        ),
                        Selected: () => (
                          <Button
                            type="primary"
                            label="Desexed"
                            onClick={() => {}}
                          />
                        ),
                      },
                      {
                        key: "not desexed",
                        text: "",
                        Unselected: () => (
                          <Button
                            type="secondary"
                            label="Not Desexed"
                            onClick={() => {}}
                          />
                        ),
                        Selected: () => (
                          <Button
                            type="primary"
                            label="Not Desexed"
                            onClick={() => {}}
                          />
                        ),
                      },
                    ]}
                    value={RegisterPet.values.pets[v].desexed}
                    onChange={RegisterPet.handleChange(`pets[${v}].desexed`)}
                  />
                </Row>
                <Row>
                  <DropdownInput
                    label="Pet's Breed"
                    options={listBreeds}
                    value={RegisterPet.values.pets[v].breed}
                    onChange={RegisterPet.handleChange(`pets[${v}].breed`)}
                  />
                  <div></div>
                </Row>
                <Row>
                  <div className="membership-tab-pricing simple">
                    <p>
                      <strong>Select yearly or monthly payment plan:*</strong>
                    </p>
                    <ul className="tab">
                      <li
                        className={
                          RegisterPet.values.pets[v].onboarding == "yearly"
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          RegisterPet.values.pets[v].onboarding = "yearly";
                          setReload(!reload);
                        }}
                      >
                        <div className="pricing yearly">
                          <span>$</span>
                          <span>
                            590.00
                            <span className="subtext strike">655.00</span>
                          </span>
                          <span>/year</span>
                        </div>
                        <span className="label">
                          <span>Plan Paid Yearly</span>
                        </span>
                      </li>
                      <li
                        className={
                          RegisterPet.values.pets[v].onboarding == "monthly"
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          RegisterPet.values.pets[v].onboarding = "monthly";
                          setReload(!reload);
                        }}
                      >
                        <div className="pricing">
                          <span>$</span>
                          <span>
                            54.50
                            <span className="subtext">
                              <br />
                            </span>
                          </span>
                          <span>/month</span>
                        </div>
                        <span className="label">
                          <span>Plan Paid Monthly</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </Row>
              </>
            );
          })}
          <Row>
            <p>
              To review the plan inclusions & pricing, {""}
              <a href={`${THRIVING_URL}/thriving-pets-inclusions/`}>
                click here {""}
              </a>
              or "Inclusions & Pricing" above.
            </p>
          </Row>
        </div>
        <p className="bottom-action text-center">
          <img
            src={arrowLeftIcon}
            onClick={() => history.push("/onboarding/pet-parent-info")}
          />
          <Button
            label="Next"
            isDisabled={isCompleted}
            customClass="button btn btn-purple square"
            onClick={() => RegisterPet.handleSubmit()}
          />
          <img
            src={arrowRightIcon}
            onClick={() => history.push("/onboarding/payment-completion")}
          />
        </p>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {PetInformation()}
    </div>
  );
};

export default PetInfo;
