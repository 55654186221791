import "./Onboarding.scss";
import thrivingPetsLogo from "../../assets/icons/thrivingpets-logo-dark.svg";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import Spinner from "../../components/common/Spinner/Spinner";
import { checkCoupon, estimateSubscriptions } from "../../api/chargebee";
import {
  OnboardingHeader,
  OnboardingMenus,
  arrowLeftIcon,
  PlanAndPaymentAggreements,
  TermsConditions,
  dogAndCat,
  trashIcon,
  editIcon,
} from "./Module";
import { thrivingPetsLogoDarkReverse } from "../Dashboard/Module";
import {
  createCustomer,
  createSubscriptions,
  getCustomer,
  getPaymentMethods,
  updateBillingAddress,
} from "../../api/chargebee";
import { useFormik } from "formik";
import { Row, Modal } from "../../components/layout";
import { Button } from "../../components/common";
import { TextInput } from "../../components/input";
import { ApplicationState } from "../../store";
import { DeleteReason } from "../../types/pet";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";

const imageEndpoint = process.env.REACT_APP_BACKEND_IMAGE_ENDPOINT;
const API = process.env.REACT_APP_BACKEND_API || "";

const PaymentCompletion: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [step, setStep] = useState(1);
  const [reloaded, setReloaded] = useState(false);
  const [validCard, setValidCard] = useState(false);
  const [checkedAccount, setCheckedAccount] = useState(false);
  const [estimateDiscount, setEstimateDiscount] = useState<number | null>(null);
  const [createdAccount, setCreatedAccount] = useState(false);
  const [showChargebeeModal, setShowChargebeeModal] = useState(false);
  const [chargebeePaymentUrl, setChargebeePaymentUrl] = useState("");
  const [prices, setPrices] = useState({
    monthly: Number("54.50"),
    upfrontMonthly: Number("54.50"),
    yearly: Number("590"),
    newCycleMonthly: Number("54.50"),
  });
  const [show, setShow] = useState({
    couponDiscount: false,
    aggreements: false,
    termsConditions: false,
  });
  //const [showAgreements, setShowAgreements] = useState(false);
  //const [showTermsConditions, setShowTermsConditions] = useState(false);
  const [agreed, setAgreed] = useState({ plan: false, terms: false });
  const [isCompleted, setIsCompleted] = useState(false);
  const location = useLocation();
  const { fetchUserData, deletePetData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const [pets, setPets] = useState(user?.pets);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const applyCoupon = async (data: any) => {
    const { plans, coupon, address, total } = data;
    //return setEstimateDiscount(10);
    setShow({ ...show, couponDiscount: true });
    await estimateSubscriptions(plans, coupon, address).then((r) => {
      setShow({ ...show, couponDiscount: false });
      if (!r || !r.data || r.data.length < 1) return;
      let theEstimate = r.data.reduce((a: any, v: any) => {
        a = a + Number(v.discount_amount) / 100;
        return a;
      }, 0);
      theEstimate = (theEstimate / total) * 100;
      console.log({ theEstimate });
      setEstimateDiscount(theEstimate);
    });
    //return console.log({ data });
  };
  useEffect(() => {
    if (!isAuthenticated) return history.push("/onboarding");
    const inCart =
      user &&
      user.pets.filter((p: any) => {
        if (urlParams.get("id")) return p._id == urlParams.get("id");
        return p.plan == "NoPlan";
      });
    console.log({ pets, inCart });
    setPets(inCart);
    //fetchUserData();
  }, [isAuthenticated, user?.pets]);
  useEffect(() => {
    if (!isAuthenticated) return;
    (async () => {
      const customer = await getCustomer();
      if (customer.status !== 200 && !createdAccount) {
        await createCustomer();
        setCreatedAccount(true);
      } else {
        if (user?.address) {
          updateBillingAddress(user?.address);
        }
        if (customer.data && customer.data.card_status === "valid") {
          setValidCard(true);
        } else {
          setValidCard(false);
        }
      }
      setCheckedAccount(true);
    })();
  }, [showChargebeeModal]);
  //console.log("AUTHENTICATED--", isAuthenticated);
  const CreatePayment = useFormik({
    initialValues: {
      plans: [],
      options: {
        coupon: "",
        affiliateCode: "",
        affiliateName: "",
        total: 0,
      },
    },
    onSubmit: async (data) => {
      //return console.log({ data });
      const { plans, options } = data;
      if (!agreed.terms)
        return toast.error("You must agree on Terms & Conditions");
      setIsCompleted(true);
      if (user?.email && user.address) {
        console.log({ data });
        const response = await createSubscriptions(
          plans,
          options?.coupon,
          user.address,
          options?.affiliateCode,
          options?.affiliateName,
          options?.total
        );
        console.log({ response });
        if (response.status === 200) {
          await fetchUserData();
          history.push("/onboarding/success");
          toast.success("Your subscriptions have been created!");
        } else {
          setIsCompleted(false);
          toast.error("Payment failed!");
        }
      } else {
        toast.error(
          "There was an issue. Please refresh the page and try again."
        );
      }
    },
    validate: async ({}) => {},
  });
  const paymentCompletion = () => {
    //calculations
    const calculation = pets?.map((p: any) => {
      const { onboarding, planStart } = p;
      let { imageUrl } = p;
      let amount = prices.yearly,
        upfront = prices.yearly,
        period = "per year",
        text = "";
      p.planId = "ThrivePlusDogCatPlan";
      if (onboarding == "monthly") {
        amount = prices.monthly;
        upfront = prices.upfrontMonthly;
        period = "per month";
        //text = `(1st upfront payment of ${upfront})`;
      }
      //need to take action for directly renewal-link
      if (planStart && urlParams.get("action") == "renewal-monthly-cycle") {
        amount = prices.newCycleMonthly;
        period = "per month";
        upfront = prices.newCycleMonthly;
        p.onboarding = "monthly";
        text = "";
        p.planId = "ThrivePlusRenewals";
      } else if (
        planStart &&
        urlParams.get("action") == "renewal-yearly-cycle"
      ) {
        amount = prices.yearly;
        upfront = prices.yearly;
        period = "per year";
        text = "";
      }
      console.log({ estimateDiscount });
      amount =
        estimateDiscount && estimateDiscount > 0
          ? amount - (estimateDiscount / 100) * amount
          : amount;
      upfront =
        estimateDiscount && estimateDiscount > 0
          ? upfront - (estimateDiscount / 100) * upfront
          : upfront;

      imageUrl = imageUrl ? API + imageEndpoint + p.imageUrl : dogAndCat;
      p = {
        ...p,
        upfront: upfront.toFixed(2),
        amount: amount.toFixed(2),
        period,
        text,
        imageUrl,
      };
      return p;
    });
    const totalYearly = calculation?.reduce(
      (t: any, v: any) => {
        if (v.onboarding == "yearly") {
          t.upfront = t.upfront + Number(v.upfront);
          t.pets = t.pets + 1;
        }
        return t;
      },
      {
        upfront: 0,
        pets: 0,
      }
    );
    const totalMonthly = calculation?.reduce(
      (t: any, v: any) => {
        if (v.onboarding == "monthly") {
          t.upfront = t.upfront + Number(v.upfront);
          t.monthly = t.monthly + Number(v.amount);
          t.pets = t.pets + 1;
        }
        return t;
      },
      {
        upfront: 0,
        monthly: 0,
        pets: 0,
      }
    );
    const totalToday = calculation?.reduce(
      (t: any, v: any) => {
        t.upfront = t.upfront + Number(v.upfront);
        t.pets = t.pets + 1;
        return t;
      },
      {
        upfront: 0,
        pets: 0,
      }
    );
    //set pets plan data
    const plans: any =
      pets &&
      pets.map((pet: any) => {
        const { _id: petId, planStart, onboarding: planType } = pet;
        const planId =
          urlParams.get("action") && planStart
            ? "ThrivePlusRenewals"
            : "ThrivePlusDogCatPlan";
        return { petId, planId, planType };
      });
    const options: any = {
      coupon: CreatePayment.values.options.coupon,
      affiliateCode: user?.affiliateCode,
      affiliateName: user?.affiliateName,
      total: totalToday.upfront,
    };
    //console.log({ options, estimateDiscount });
    //applyCoupon({ plans, coupon: "THRIVINGBB", address: user?.address });
    CreatePayment.values.plans = plans;
    CreatePayment.values.options = options;
    return (
      <div className="content">
        <OnboardingHeader step={5} stepTotal={5} />
        <div className="content-box checkout-plan">
          <OnboardingMenus active="payment-completion" />
          <h2 className="title-2">Confirm Wellness Plan(s) & Payment</h2>
          <img src={thrivingPetsLogo} />
          <h3 className="title-2">
            Account Details
            <a
              className="action pull-right"
              onClick={() => history.push("/onboarding/pet-parent-info")}
            >
              <img src={editIcon} /> Edit Information
            </a>
          </h3>
          <p>
            <strong>{user?.firstName + " " + user?.lastName}</strong> <br />
            {user?.email}
            <br />
            {user?.phone}
            <br />
            {user?.address?.address1 +
              ", " +
              user?.address?.city +
              " " +
              user?.address?.state +
              " " +
              user?.address?.country +
              ", " +
              user?.address?.postcode}
            {""} <br />
          </p>
          <h3 className="title-2"> Pet(s) Plans</h3>
          {calculation && calculation.length > 0 && (
            <>
              <ul className="checkout-list">
                {calculation.map((p: any) => {
                  const {
                    name,
                    type,
                    gender,
                    breed,
                    onboarding,
                    text,
                    amount,
                    period,
                    plan,
                    imageUrl,
                  } = p;
                  return (
                    <li className="justify-center align-center">
                      <div className="flex align-center">
                        <img src={imageUrl} />
                        <p className="text-capitalize">
                          <strong>Thriving Pets Wellness Plan</strong>
                          <br />
                          {name}, {type}, {gender}, {breed}
                        </p>
                      </div>
                      <p className="text-right">
                        <img
                          className="trash"
                          src={trashIcon}
                          onClick={async () => {
                            const inCart = pets?.filter((f) => {
                              return p._id != f._id;
                            });
                            setPets(inCart);
                            //if (plan != "NoPlan") return;
                            //await deletePetData(p._id, DeleteReason.OTHER);
                            //toast.error(`Deleting ${name} - ${breed}`);
                            console.log({ pets, inCart });
                          }}
                        />
                        <strong>${amount}</strong> {period} <br />
                        {text}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
          <h3 className="title-2">
            Based on your pet(s) and payment schedule, your plan will cost:
          </h3>
          {(!pets || pets.length < 1) && (
            <p>
              <button
                className="btn btn-green square"
                onClick={async () => {
                  history.push("/onboarding/pet-info");
                }}
              >
                Add Pet Information
              </button>
            </p>
          )}
          {totalMonthly &&
            totalMonthly.upfront > 0 &&
            !urlParams.get("action") && (
              <ul className="checkout-calculation">
                <li className="justify-center">
                  <div className="flex justify-center">
                    <span className="currency">$</span>
                    <h2 className="title-1 big">
                      {totalMonthly.monthly.toFixed(2)}
                      <span>
                        Plan(s) Paid Monthly x {totalMonthly.pets} Pets
                      </span>
                    </h2>
                  </div>
                </li>
              </ul>
            )}
          {totalYearly && totalYearly.upfront > 0 && (
            <ul className="checkout-calculation">
              <li className="justify-center">
                <div className="flex justify-center">
                  <span className="currency">$</span>
                  <h2 className="title-1 big">
                    {totalYearly.upfront.toFixed(2)}
                    <span>Plan(s) Paid Yearly x {totalYearly.pets} Pets</span>
                  </h2>
                </div>
                {calculation && (
                  <>
                    <table>
                      <tbody>
                        {calculation.map((p: any) => {
                          const { name, onboarding, upfront } = p;
                          if (onboarding != "yearly") return;
                          return (
                            <tr>
                              <td>
                                Yearly for <strong>{name}</strong>
                              </td>
                              <td>
                                <strong>${upfront}</strong>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            Plan(s) Total Payment x {totalYearly.pets} pet(s):
                          </td>
                          <td>
                            <strong>${totalYearly.upfront.toFixed(2)}</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </>
                )}
              </li>
            </ul>
          )}
          <h3 className="title-2">TOTAL</h3>
          <ul className="checkout-calculation checkout-total">
            <li className="justify-center">
              <div className="flex justify-center">
                <div className="flex">
                  <span className="currency">$</span>
                  <h2 className="title-1 big">
                    {options.total.toFixed(2)}
                    <span>Plan(s) Paid for x {totalToday.pets} Pets</span>
                  </h2>
                </div>
                <span className="currency">Due Today</span>
              </div>
              <p className="flex">
                <div>
                  <div className="input-group">
                    <input
                      type="text"
                      className="input-text"
                      value={CreatePayment.values.options.coupon}
                      onChange={CreatePayment.handleChange(`options["coupon"]`)}
                    />
                    <input
                      type="button"
                      className="input-btn"
                      value={
                        !show.couponDiscount ? "Apply Coupon" : "Loading..."
                      }
                      onClick={async (v) => {
                        console.log(CreatePayment.values.options);
                        /*const petPlans =pets && pets.map((p: any) => {
                          const {
                            _id: petId,
                            planId,
                            onboarding: planType,
                          } = p;
                          return { petId, planId, planType };
                        });*/
                        //return console.log({ petPlans });
                        await applyCoupon({
                          plans: plans,
                          coupon: CreatePayment.values.options.coupon,
                          address: user?.address,
                          total: options.total,
                        });
                      }}
                    />
                  </div>
                  {estimateDiscount && estimateDiscount > 0 ? (
                    <span style={{ lineHeight: "50px", color: "red" }}>
                      {estimateDiscount.toFixed(0)}% Discounted from your
                      purchase!
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </p>
            </li>
          </ul>
          <ul className="checkout-options">
            <li className="none">
              <div className="credit-form">
                <h4>Credit Card</h4>
                <div className="fields">
                  <Row>
                    <div className="form-group">
                      <input type="text" placeholder="Card number" />
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group">
                      <input type="text" placeholder="Card number" />
                    </div>
                  </Row>
                  <Row>
                    <div className="form-group">
                      <input type="text" placeholder="Card number" />
                    </div>
                    <div className="form-group">
                      <input type="text" placeholder="Card number" />
                    </div>
                  </Row>
                </div>
              </div>
            </li>
            <li>
              <span
                className={agreed.terms ? "checkbox active" : "checkbox"}
                onClick={() => {
                  setAgreed({ ...agreed, terms: !agreed.terms });
                }}
              ></span>
              <p>
                I have read Thriving Pets Wellness Plans &nbsp;
                <a
                  onClick={() => {
                    setShow({ ...show, termsConditions: true });
                  }}
                >
                  <strong>Terms & Conditions.*</strong>
                </a>
              </p>
            </li>
            <li className="none">
              <button
                style={{ width: "auto", margin: "initial", height: "auto" }}
                className="btn btn-purple square"
                onClick={async () => {
                  const url = await getPaymentMethods();
                  console.log({ url });
                  if (url) {
                    setChargebeePaymentUrl(url);
                    setShowChargebeeModal(true);
                  }
                }}
              >
                Enter Credit Payment Information
              </button>
            </li>
          </ul>
          {showChargebeeModal && (
            <Modal
              maxWidth={700}
              isOpen={showChargebeeModal}
              closeModal={() => setShowChargebeeModal(false)}
            >
              <div className="dashboard">
                <div className="content-header">
                  <div className="flex align-center">
                    <img src={thrivingPetsLogoDarkReverse} />
                  </div>
                  <div className="content-body">
                    {chargebeePaymentUrl ? (
                      <iframe
                        src={chargebeePaymentUrl}
                        style={{
                          width: "100%",
                          minHeight: "550px",
                          background: "#999",
                          overflow: "hidden",
                          borderRadius: "5px",
                        }}
                      ></iframe>
                    ) : (
                      <h3 style={{ textAlign: "center" }}>Loading...</h3>
                    )}
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {show.aggreements && (
            <Modal
              isOpen={true}
              closeModal={() => setShow({ ...show, aggreements: false })}
            >
              <PlanAndPaymentAggreements />
            </Modal>
          )}
          {show.termsConditions && (
            <Modal
              isOpen={true}
              closeModal={() => setShow({ ...show, termsConditions: false })}
            >
              <TermsConditions />
            </Modal>
          )}
        </div>
        {calculation && calculation.length > 0 && (
          <>
            <p
              style={{ width: "700px", maxWidth: "100%" }}
              className="bottom-action text-center flex"
            >
              <img
                src={arrowLeftIcon}
                onClick={() => history.push("/onboarding/pet-info")}
              />
              <button
                style={{ width: "auto" }}
                className="btn btn-green square"
                onClick={async () => {
                  const url = await getPaymentMethods();
                  console.log({ url });
                  if (url) {
                    setChargebeePaymentUrl(url);
                    setShowChargebeeModal(true);
                  }
                }}
              >
                Enter Credit Payment Information
              </button>
              <Button
                label="Purchase the Plan(s)"
                isDisabled={isCompleted}
                customClass="button btn btn-purple square"
                onClick={() => CreatePayment.handleSubmit()}
              />
            </p>
          </>
        )}
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {paymentCompletion()}
    </div>
  );
};

export default PaymentCompletion;
