import "./Accordian.scss";
import React from "react";
import useToggle from "../../../hooks/useToggle";

interface AccordianProps {
  /**
   * The title to be shown in the header.
   */
  title: string;
  /**
   * The body content of the accordian to be collapsed/shown.
   */
  children: React.ReactNode | React.ReactNode[];
  /**
   * The URL of the icon to be displayed inside the accordian header.
   */
  iconUrl?: string;
  /**
   * Configure the optional +Link fixed to the bottom of
   * the accordian body when opened. Hidden when not configured.
   */
  fixedBottomLink?: {
    label: string;
    onClick: () => void;
  };
}

const Accordian: React.FC<AccordianProps> = ({
  title,
  children,
  iconUrl,
  fixedBottomLink,
}: AccordianProps) => {
  const [isOpen, toggleIsOpen] = useToggle();
  const modifier = isOpen ? "open" : "closed";

  return (
    <div className="accordian">
      <div className="accordian__header" onClick={toggleIsOpen}>
        {iconUrl && (
          <div className="accordian__icon">
            <img src={iconUrl} />
          </div>
        )}
        <h2 className="accordian__title">{title}</h2>
        <div className={`accordian__chevron accordian__chevron-${modifier}`} />
      </div>
      <div className={`accordian__body accordian__body-${modifier}`}>
        <div className={`accordian__content accordian__content-${modifier}`}>
          {isOpen && children}
        </div>
      </div>
      {fixedBottomLink && (
        <div
          className={`accordian__fixedlink accordian__fixedlink-${modifier}`}
          onClick={fixedBottomLink.onClick}
        >
          {fixedBottomLink.label}
        </div>
      )}
    </div>
  );
};

export default Accordian;
