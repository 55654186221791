export interface PetOptions {
  petColours: string[];
  catBreeds: string[];
  dogBreeds: string[];
}

export type Gender = "male" | "female";

export type Desexed = "desexed" | "not desexed";

export type Plan =
  | "ThrivePlusDogCatPlan"
  | "ThriveDogPlan"
  | "ThriveCatPlan"
  | "ThrivePlusPuppyKittenPlan"
  | "NoPlan";

export interface HealthInfo {
  lastHealthCheckDate: Date;
  lastTickAndFleaDate: Date;
  recentHealthExam: "yes" | "no" | "unsure";
  healthConditions: string[];
  vaccinations: "yes" | "no" | "unsure";
  medications: string;
}

export enum DeleteReason {
  MOVED = "New home",
  DECEASED = "Passed away",
  OTHER = "Rather not say",
}

/**
 * All values of a pet that has been created.
 */
export interface Pet {
  type: "cat" | "dog";
  desexed: "desexed" | "not desexed";
  name: string;
  gender: Gender;
  weight: string;
  birthday: Date;
  breed: string;
  color: string;
  imageUrl: string;
  _id: string;
  healthConditions: string;
  medications: string;
  UTDvaccinations: "yes" | "no" | "unsure";
  UTDpreventatives: "yes" | "no" | "unsure";
  lastTickAndFlea: Date;
  lastVaccination: Date;
  lastHealthCheckDate: Date;
  lastDentalCheck: Date;
  lastDentalProcedure: Date;
  preventativeProduct: string;
  dietaryProduct: string;
  vetClinic: string;
  plan: Plan;
  subscriptionId: string;
  planStart: Date;
  shipped: number;
  nextShipment: string;
  savings: number;
  claims: string[];
  planAddon: string;
  dateCreate: Date;
  dateLastUpdated: Date;
  weightUpdated: Date;
  bookedPreschool: boolean;
}

/**
 * Values of a pet about to be created.
 */
export interface PetSignupData {
  type: Pet["type"];
  desexed: Pet["desexed"];
  name: Pet["name"];
  gender: Pet["gender"] | "";
  weight: Pet["weight"];
  birthday: Pet["weight"] | "";
  breed: Pet["breed"] | "";
  color: Pet["color"] | "";
  imageUrl: Pet["imageUrl"];
  image: File | "";
  plan: Plan;
}
export interface PetOnboarding extends PetSignupData {
  onboarding: string | null;
}

/**
 * Updatable values of a pet.
 */
export interface PetUpdateData {
  _id: Pet["_id"];
  weight?: Pet["weight"];
  desexed?: Pet["desexed"];
  image?: File | "";
  imageUrl?: Pet["imageUrl"];
  healthConditions: Pet["healthConditions"];
  medications: Pet["medications"];
  UTDvaccinations: Pet["UTDvaccinations"];
  UTDpreventatives: Pet["UTDpreventatives"];
  lastTickAndFlea: Pet["lastTickAndFlea"];
  lastVaccination: Pet["lastVaccination"];
  lastHealthCheckDate: Pet["lastHealthCheckDate"];
  lastDentalCheck: Pet["lastDentalCheck"];
  lastDentalProcedure: Pet["lastDentalProcedure"];
  preventativeProduct: Pet["preventativeProduct"];
}
