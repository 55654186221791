import "./ProvidedRadioInput.scss";
import { useEffect, useState } from "react";
import { InputError, InputLabel } from "..";
import { InputProps, TwoOrMoreArray } from "../../../types/inputs";
export interface ProvidedRadioOption {
  key: string;
  text?: string;
  Selected: React.FC;
  Unselected: React.FC;
}
/**
 * When options are passed in as <Button/> include primary/secondary button types for seleced/unselected
 */
interface ProvidedRadioProps extends InputProps {
  options: TwoOrMoreArray<ProvidedRadioOption>;
}

const ProvidedRadioInput: React.FC<ProvidedRadioProps> = ({
  label,
  options,
  value,
  onChange,
  touched,
  error,
  required,
  disabled,
}) => {
  const [toggled, setToggled] = useState("");
  useEffect(() => (value ? setToggled(value) : setToggled("")), [value]);

  const disabledModifier = disabled ? "providedradio--disabled" : "";

  return (
    <div className={`providedradio ${disabledModifier}`}>
      <InputLabel label={label} required={required} />
      <div className="providedradio__options">
        {options &&
          options.map((item: ProvidedRadioOption) => {
            const selected = toggled === item.key;
            return (
              <div
                key={item.key}
                onClick={() => {
                  if (!disabled) {
                    onChange(item.key);
                    setToggled(item.key);
                  }
                }}
                className="providedradio__button"
              >
                {selected ? <item.Selected /> : <item.Unselected />}
                {item.text && (
                  <p className={`providedradio__text-${selected}`}>
                    {" "}
                    {item.text}
                  </p>
                )}
              </div>
            );
          })}
      </div>
      <InputError error={error} touched={touched} />
    </div>
  );
};

export default ProvidedRadioInput;
