import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { CallHistoryMethodAction } from "connected-react-router";
import axios from "../api/axios";
import { AxiosResponse } from "axios";
import { User, UserSignupData, UserOnboardingData } from "../types/user";
import { toast } from "react-toastify";
import { cleanupBeforeLogout } from "../utils/auth-utils";
import { CountryCode } from "../types/country";
import { MultipassLogin } from "../api/authorisation";
const API = process.env.REACT_APP_BACKEND_API;

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthState {
  isAuthenticated: boolean;
  accessToken?: string;
  refreshToken?: string;
  multiPassToken?: string;
  tokenExpiry?: Date;
  password?: string;
  rememberMe?: boolean;
  email?: string;
  /**
   * created is check for creating account.
   */
  created?: boolean;
  countryCode?: CountryCode;
}

export interface TokenResponse {
  accessToken: string;
  refreshToken: string;
}

export interface VerificationResponse {
  pass: boolean;
  reason: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface FetchUserTokenAction {
  type: "RECEIVE_USER_TOKEN";
  payload: {
    email?: string;
    accessToken: string;
    refreshToken: string;
  };
}

export interface FetchShopifyTokensAction {
  type: "RECEIVE_SHOPIFY_TOKENS";
  payload: {
    multiPassToken?: string;
  };
}

export interface RefreshUserTokenAction {
  type: "GET_USER_TOKEN";
  payload: {
    accessToken: string;
  };
}
export interface LogoutAction {
  type: "LOGOUT";
}

export interface UpdateUserDetailsAction {
  type: "UPDATE_USER_DETAILS";
}

export interface CreateUserAccountAction {
  type: "CREATE_USER_ACCOUNT";
  payload: {
    created: boolean;
  };
}

export interface ResetCreatedAction {
  type: "RESET_CREATED_VALUE";
  payload: {
    created: boolean;
  };
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | FetchUserTokenAction
  | LogoutAction
  | UpdateUserDetailsAction
  | ResetCreatedAction
  | FetchShopifyTokensAction
  | CreateUserAccountAction
  | RefreshUserTokenAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  fetchUserToken:
    (email: string, password: string): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        // Check auth with the backend
        const response: AxiosResponse<TokenResponse> = await axios.post(
          `${API}/login`,
          { email, password }
        );
        // If successful with the backend
        if (response.status === 200) {
          // Update state to reflect user's valid auth.
          const { accessToken, refreshToken } = response.data;
          dispatch({
            type: "RECEIVE_USER_TOKEN",
            payload: { email, accessToken, refreshToken },
          });

          // Use accessToken in redux state to fetch country from user details
          const user = await axios.post<User>(`${API}/login/getDetails`, {
            email,
          });
          if (user.data.accountStatus !== "removed") {
            const country = user.data.address.country;
            // Check auth with Shopify
            const multipass = await MultipassLogin(email, country).catch(
              (error) => console.error(error)
            );
            if (multipass) {
              dispatch({
                type: "RECEIVE_SHOPIFY_TOKENS",
                payload: { multiPassToken: multipass.accessToken },
              });
            } else {
              throw new Error(
                "There was an error connecting to the store. Please try logging in again."
              );
            }
          }
        } else {
          throw new Error("Login failed. Please try again.");
        }
      } catch (e) {
        toast.error(
          `Login failed. Please try again with a different email or password.`
        );
      }
    },
  refreshUserToken: (): AppThunkAction<KnownAction> => async () => {},
  logout:
    (): AppThunkAction<KnownAction | CallHistoryMethodAction> =>
    async (dispatch) => {
      await cleanupBeforeLogout();
      dispatch({ type: "LOGOUT" });
    },
  resetCreated: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: "RESET_CREATED_VALUE", payload: { created: false } });
  },
  createUserAccount:
    (
      signupRequestData: UserSignupData,
      code: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const response = await axios.post(`${API}/signup/verification`, {
          user: signupRequestData,
          code: code,
        });
        if (response.data === true) {
          dispatch({
            type: "CREATE_USER_ACCOUNT",
            payload: { created: true },
          });
          return true;
        } else if (response.data === false) {
          dispatch({
            type: "CREATE_USER_ACCOUNT",
            payload: { created: false },
          });
          return false;
        }
      } catch (e) {
        console.error(e);
      }
    },
  createUserOnboarding:
    (
      signupRequestData: UserOnboardingData,
      code: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const response = await axios.post(`${API}/signup/verification`, {
          user: signupRequestData,
          code: code,
        });
        if (response.data === true) {
          dispatch({
            type: "CREATE_USER_ACCOUNT",
            payload: { created: true },
          });
          return true;
        } else if (response.data === false) {
          dispatch({
            type: "CREATE_USER_ACCOUNT",
            payload: { created: false },
          });
          return false;
        }
      } catch (e) {
        console.error(e);
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<AuthState> = (
  state: AuthState | undefined,
  incomingAction: Action
): AuthState => {
  const defaultState = {
    isAuthenticated: false,
    token: undefined,
    password: undefined,
    rememberMe: undefined,
    created: false,
  };

  if (state === undefined) {
    return defaultState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RECEIVE_USER_TOKEN":
      if (action.payload.accessToken != null) {
        const updatedState = {
          ...state,
          email: action.payload.email,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          tokenExpiry: new Date(new Date().getTime() + 14 * 60000),
          isAuthenticated: action.payload.accessToken != null,
        };
        return updatedState;
      }
      break;
    case "RECEIVE_SHOPIFY_TOKENS":
      if (action.payload.multiPassToken != null) {
        const updatedState = {
          ...state,
          multiPassToken: action.payload.multiPassToken,
        };
        return updatedState;
      }
      break;
    case "LOGOUT":
      return defaultState;
    case "CREATE_USER_ACCOUNT": {
      const updatedState = {
        ...state,
        created: action.payload.created,
      };
      return updatedState;
    }
    case "GET_USER_TOKEN":
      const updatedState = {
        ...state,
        accessToken: action.payload.accessToken,
      };
      return updatedState;
    default:
      return state;
  }

  return state;
};
