import "./PhoneInput.scss";
import { useEffect, useState } from "react";
import { TextInput } from "..";
import { InputProps } from "../../../types/inputs";
import { DropdownInput } from "..";
interface PhoneInputProps extends InputProps {
  country?: string;
  onRegionSelect: (code: string) => void;
}

type PhonePickerType = {
  value: string;
  label: string;
  imageUrl: string;
};

const PhoneInput: React.FC<PhoneInputProps> = ({
  onChange,
  value,
  error,
  touched,
  country,
  onRegionSelect,
}) => {
  /**
   * Internal state.
   */
  const [countryCode, setCountryCode] = useState("+61");

  /**
   * useEffect for updating the country code in the parent based on what country is typed in
   */
  useEffect(() => {
    onRegionSelect(countryCode);
  }, [countryCode]);

  /**
   * Storage for the country information, may be able to move it elsewhere later
   */
  const countryInfo: PhonePickerType[] = [
    {
      label: "Australia (+61)",
      value: "+61",
      imageUrl:
        "https://dejpknyizje2n.cloudfront.net/svgcustom/clipart/preview/australia-flag-sticker-5783-21489-550x550.png",
    },
    {
      label: "New Zealand (+64)",
      value: "+64",
      imageUrl:
        "https://dejpknyizje2n.cloudfront.net/svgcustom/clipart/preview/new-zealand-flag-sticker-5890-21446-300x300.png",
    },
  ];

  /**
   * useEffect for changing phone country code field based on information passed in
   */
  useEffect(() => {
    if (!country) {
      setCountryCode("+61");
    } else {
      for (let i = 0; i < countryInfo.length; i++) {
        setCountryCode(
          countryInfo[i].label
            .toLowerCase()
            .replace(" ", "")
            .includes(country.toLowerCase().replace(" ", ""))
            ? countryInfo[i].value
            : "+61"
        );
      }
    }
  }, [country]);

  return (
    <div className="phoneinput">
      <DropdownInput
        label="Region"
        options={countryInfo.map((c) => ({
          label: c.value,
          value: c.value,
          image: c.imageUrl,
        }))}
        onChange={(cc) => setCountryCode(cc)}
        value={countryCode}
      />
      <TextInput
        label="Mobile Number"
        value={value}
        onChange={onChange}
        touched={touched}
        error={error}
      />
    </div>
  );
};

export default PhoneInput;
