import axios from "../api/axios";

interface AffiliateOption {
  id: string;
  name: string;
}

export const tapfiliateGetAffiliates = async (): Promise<AffiliateOption[]> => {
  const response = await axios.get<AffiliateOption[]>("/tapfiliate");
  return response.data;
};
export const tapfiliateGetAffiliatesAU = async (): Promise<
  AffiliateOption[]
> => {
  const response = await axios.get<AffiliateOption[]>("/data/affiliates/au");
  return response.data;
};
export const tapfiliateGetAffiliatesNZ = async (): Promise<
  AffiliateOption[]
> => {
  const response = await axios.get<AffiliateOption[]>("/data/affiliates/nz");
  return response.data;
};
