import React, { useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

interface ScrollToTopProps {
  history: History;
}

/**
 * Wrapper component to ensure the page is scrolled to
 * the top on page change.
 *
 * Adapted from: https://stackoverflow.com/a/54343182
 */
const ScrollToTop: React.FC<ScrollToTopProps> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(ScrollToTop);
