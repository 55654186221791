import "./Grid.scss";
import React from "react";

interface GridProps {
  /**
   *
   */
  children: React.ReactElement | React.ReactElement[];
  /**
   *
   */
  columns?: number;
}

const Grid: React.FC<GridProps> = ({ children, columns = 3 }) => {
  /**
   * Convert the children to an array to make them
   * easier to work with.
   */
  const childrenArray = React.Children.toArray(children);

  return (
    <div className={`grid grid-${columns}`}>
      {childrenArray.map((child) => (
        <div className="grid__item">{child}</div>
      ))}
    </div>
  );
};

export default Grid;
