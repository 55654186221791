import "./FileInput.scss";
import React from "react";
import { FocusProps, InputProps } from "../../../types/inputs";
import { InputError, InputLabel } from "../../input";

interface FileInputProps
  extends Omit<InputProps, "onChange" | "value">,
    FocusProps<HTMLInputElement> {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Input field for uploading a file.
 */
const FileInput: React.FC<FileInputProps> = ({
  label,
  onChange,
  touched,
  error,
  required,
  onFocus,
  onBlur,
  disabled = false,
}) => {
  return (
    <div className="fileinput">
      <InputLabel label={label} required={required} />
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        className="fileinput__input"
        type="file"
        onChange={(event) => onChange(event)}
        disabled={disabled}
        accept="image/*,application/pdf"
      />
      <InputError error={error} touched={touched} />
    </div>
  );
};

export default FileInput;
