/**
 * Removes a leading zero in a povided string.
 */
export const stripLeadingZero = (str: string): string => {
  return str[0] === "0" ? str.substring(1) : str;
};

/**
 * Remove any white spaces in a string.
 */
export const stripSpaces = (str: string): string => {
  return str.replace(/\s+/g, "");
};
/**
 * removes the country code from the start of the number
 */
export const stripCountry = (str: string): string => {
  return str.includes("(+") ? str.substring(5) : str;
};
/**
 * Formats a mobile number to accomodate for common issues
 * (e.g. spaces in the middle of numbers).
 */
export const formatMobileNumber = (str: string): string => {
  let number = str;
  number = stripCountry(number);
  number = stripSpaces(number);
  number = stripLeadingZero(number);

  return number;
};
