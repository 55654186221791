import "./Modal.scss";
import React from "react";
import ReactModal from "react-modal";

interface ModalProps {
  /**
   * Whether the modal should be visible
   */
  isOpen: boolean;
  /**
   * The function used to close the modal.
   */
  closeModal: () => void;
  /**
   * Instead of passing in children, you can pass in a URL to
   * be loaded inside the modal (children will be ignored).
   */
  url?: string;
  /**
   * Instead of passing in children, you can pass in a URL to
   * be loaded inside the modal (children will be ignored).
   */
  html?: string;
  /**
   * The maximum width of the modal.
   */
  maxWidth?: number;
  /**
   * The maximum height of the modal.
   */
  maxHeight?: number;
  /**
   *
   */
  className?: string;
  /**
   * Removes padding from Modal
   */
  disablePadding?: boolean;
}

/**
 * Component for showing content in a popup box (modal).
 * Based on react-modal (see http://reactcommunity.org/react-modal/).
 */
const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  url,
  html,
  maxWidth = 920,
  maxHeight = 800,
  closeModal,
  className,
  disablePadding,
}) => {
  const renderIframe = (src: { url?: string; html?: string }) => {
    if (src.url) {
      return <iframe className="modal__iframe" src={src.url} />;
    } else if (src.html) {
      return <iframe className="modal__iframe" srcDoc={src.html} />;
    }
  };

  const iframeModifer = url || html ? "modal__body-iframe" : "";
  const disablePaddingModifier = disablePadding
    ? "modal__body-disablepadding"
    : "";

  return (
    <ReactModal
      className={`modal__box ${className}`}
      overlayClassName="modal__overlay"
      style={{ content: { maxWidth, maxHeight } }}
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
    >
      <div className="modal__header">
        <div className="modal__close" onClick={closeModal}>
          Close
        </div>
      </div>
      <div
        className={`modal__body  ${iframeModifer} ${disablePaddingModifier}`}
      >
        {url || html ? renderIframe({ url, html }) : children}
      </div>
    </ReactModal>
  );
};

export default Modal;
