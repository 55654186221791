import "./HTMLRadioInput.scss";
import { InputWithOptionsProps } from "../../../types/inputs";
import { RadioButton, InputError, InputLabel } from "..";

interface HTMLRadioInputProps extends InputWithOptionsProps {
  /**
   * The value that ties all the radio buttons into a single input.
   * Should be a unique value representatite of the set of data.
   */
  groupName: string;
}

/**
 * A standard multi-option html radio input.
 */
const HTMLRadioInput: React.VFC<HTMLRadioInputProps> = ({
  label,
  options,
  value,
  onChange,
  touched,
  error,
  required,
  groupName,
  disabled,
}) => {
  return (
    <div className="htmlradioinput">
      <InputLabel label={label} required={required} />
      {!!options.length &&
        options.map((option) => (
          <RadioButton
            groupName={groupName}
            id={option.value}
            label={option.label}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        ))}
      <InputError error={error} touched={touched} />
    </div>
  );
};

export default HTMLRadioInput;
