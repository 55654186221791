import "./Onboarding.scss";

import thrivingPetsLogoLight from "../../assets/icons/thrivingpets-logo-light.svg";
import { checkIcon, homeIcon, shopIcon, YPPA_URL } from "./Module";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import history from "../../utils/history";
import { ApplicationState } from "../../store";

const SuccessOnboarding: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const petOptions = useSelector(
    (state: ApplicationState) => state.global?.petOptions
  );
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  useEffect(() => {
    if (!isAuthenticated) history.push("/onboarding");
  }, [isAuthenticated]);

  const successResponse = () => {
    return (
      <div className="content">
        <div className="steps align-center">
          <h2 className="flex mb-0 align-center">
            Welcome to
            <img src={thrivingPetsLogoLight} />
          </h2>
        </div>
        <div className="content-box checkout-plan">
          <br />
          <h2 className="title-2">
            Thanks for signing up for simple & affordable pet wellness!
          </h2>
          <h3 className="flex align-center margin-0">
            <span className="image-icon" style={{ background: "#7dc9ce" }}>
              <img src={checkIcon} />
            </span>
            Your account has been created!
          </h3>
          <p className="none">
            Account benefits for <strong>Frank & Trent</strong> will be
            available after confirmation of email address
          </p>
          <ul className="checkout-list align-left">
            <li className="align-center">
              <span className="image-icon">
                <img src={homeIcon} />
              </span>
              <div>
                <p className="margin-0">
                  You can review your plan benefits, account details and make
                  claims via your Thriving Pets account
                </p>
                <h3 className="margin-0">
                  <a href="/">Login to my Thriving Pets Account</a>
                </h3>
              </div>
            </li>
            <li className="align-center">
              <span className="image-icon">
                <img src={shopIcon} />
              </span>
              <div>
                <p className="margin-0">
                  Visit yourpetpa.com.au to use your 10% off pet store discount.
                  This excludes all parasite prevention and pharmacy
                  medications. You will need to use both your Thriving Pets
                  account email and the discount code Thriving to be eligible
                  for 10% off
                </p>
                <h3 className="margin-0">
                  <a
                    href={`${YPPA_URL}/discount/Thriving/?redirect=/`}
                    target="_blank"
                  >
                    Shop Affordable Pet Products
                  </a>
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {successResponse()}
    </div>
  );
};

export default SuccessOnboarding;
