import "./TabbedCard.scss";
import React from "react";
import { MenuItem } from "../../../types/menu";
import { Card, Overflow } from "../../layout";

interface TabbedCardProps {
  /**
   * Items to be rendered in the menu.
   */
  menuItems: MenuItem[];
  /**
   * The value to compare the MenuItem route
   * with to determine active status.
   */
  activeRouteCompare?: string;
  /**
   * The content to be rendered inside the body.
   * You're responsible for rendering the correct
   * content on tab changes.
   */
  children: React.ReactNode | React.ReactNode[];
  /**
   * Class name to be added to the component
   */
  className?: string;
}

const TabbedCard: React.FC<TabbedCardProps> = ({
  menuItems,
  activeRouteCompare,
  children,
  className,
}) => {
  /**
   * Determine if route is active.
   */
  const isActiveRoute = (route: string | undefined): boolean => {
    return !!route && !!activeRouteCompare && route === activeRouteCompare;
  };

  /**
   * Renders tabs that trigger the menuItem's onClick when clicked.
   * Horizontally scrolls when it overflows off the screen.
   */
  const renderTabs = () => {
    return (
      <nav className="tabbedcard__nav">
        <Overflow>
          <ul className="tabbedcard__ul">
            {menuItems.map(({ label, iconUrl, onClick, route }) => {
              const active = isActiveRoute(route)
                ? "tabbedcard__li-active"
                : "";
              return (
                <li className={`tabbedcard__li ${active}`} onClick={onClick}>
                  <img className="tabbedcard__icon" src={iconUrl} />
                  {label}
                </li>
              );
            })}
          </ul>
        </Overflow>
      </nav>
    );
  };

  return (
    <div className="tabbedcard">
      {menuItems.length > 1 && renderTabs()}
      <Card className={className}>{children}</Card>
    </div>
  );
};

export default TabbedCard;
