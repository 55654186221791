import gql from "graphql-tag";

export const checkoutQuery = gql`
  query getCheckout($id: ID!) {
    node(id: $id) {
      id
      ... on Checkout {
        id
        ready
        completedAt
        currencyCode
        lineItemsSubtotalPrice {
          amount
        }
        taxesIncluded
        totalTax
        totalPrice
        webUrl
        lineItems(first: 250) {
          edges {
            node {
              id
              title
              quantity
              variant {
                id
                price
                title
                image {
                  src
                }
              }
            }
          }
        }
        discountApplications(first: 1) {
          edges {
            node {
              ... on DiscountCodeApplication {
                __typename
                code
                value {
                  ... on MoneyV2 {
                    __typename
                    amount
                    currencyCode
                  }
                  ... on PricingPercentageValue {
                    __typename
                    percentage
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
