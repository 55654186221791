import React, { useEffect, useState } from "react";
import { Accordian, Modal } from "../../components/layout";
import icon from "../../assets/icons/payment info.svg";
import { Button } from "../../components/common";
import useCountryCode from "../../hooks/useCountryCode";
const chargebeePortalAU = process.env.REACT_APP_CHARGEBEE_PORTAL_AU;
const chargebeePortalNZ = process.env.REACT_APP_CHARGEBEE_PORTAL_NZ;

const MyPayments: React.VFC = () => {
  const [chargebeeModal, setChargebeeModal] = useState(false);
  const [chargebeePortal, setChargebeePortal] = useState(chargebeePortalAU);
  const countryCode = useCountryCode();

  /**
   * Get the right portal depending on user's country.
   */
  useEffect(() => {
    switch (countryCode) {
      case "AU":
        setChargebeePortal(chargebeePortalAU);
        break;
      case "NZ":
        setChargebeePortal(chargebeePortalNZ);
        break;
      default:
        setChargebeePortal(chargebeePortalAU);
    }
  }, [countryCode]);

  return (
    <Accordian title="My Payments" iconUrl={icon}>
      {/* Modal that shows chargebee */}
      <Button label="Payment Info" onClick={() => setChargebeeModal(true)} />
      <Modal
        closeModal={() => setChargebeeModal(false)}
        isOpen={chargebeeModal}
        url={chargebeePortal}
      />
    </Accordian>
  );
};

export default MyPayments;
