import axiosConfig from "./axios";
import { ClaimData } from "../types/claims";
import { toast } from "react-toastify";
const API = process.env.REACT_APP_BACKEND_API;

export const getClaims = async (claims: string[]): Promise<ClaimData[]> => {
  const res = await axiosConfig.post(`${API}/portal/claims/getClaims`, claims);
  if (res && res.status === 200) {
    return res.data;
  } else {
    toast.error("Failed to fetch claims");
    return [];
  }
};
