import "./Dashboard.scss";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  createCustomer,
  getCustomer,
  getCustomersSubscription,
  getPaymentMethods,
} from "../../api/chargebee";
import { actionCreators as userActionCreators } from "../../store/User";
import { DeleteReason } from "../../types/pet";
import { ApplicationState } from "../../store";
import history from "../../utils/history";
import {
  trashIcon,
  checkIcon,
  dogCatIcon,
  catDogd3Icon,
  arrowLeftIcon,
  hospitalIcon,
  listIcon,
  claimIcon,
  claimHistoryIcon,
  membershipIcon,
  thrivingPetsLogoDarkReverse,
} from "./Module";
import PersonalAndDelivery from "./PersonalAndDelivery";
import MembershipInvoices from "./MembershipInvoices";
import EditPaymentDetails from "./EditPaymentDetails";
import ChangePassword from "./ChangePassword";
import BookAVet from "./BookAVet";
import CreateAClaim from "./CreateAClaim";
import ClaimsHistory from "./ClaimsHistory";
import EditPetDetail from "./EditPetDetail";
import { Modal } from "../../components/layout";
import { Button } from "../../components/common";
import { YPPA_URL } from "../Onboarding/Module";
const imageEndpoint = process.env.REACT_APP_BACKEND_IMAGE_ENDPOINT;
const API = process.env.REACT_APP_BACKEND_API || "";
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
type SubscriptionType = {
  card: any;
  customer: any;
  subscription: any;
};
const Dashboard: React.FC = () => {
  const { deletePetData, fetchUserData, fetchChargeBeeData, fetchBankData } =
    bindActionCreators(userActionCreators, useDispatch());
  const location = useLocation();
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [planView, setPlanView] = useState<string>("benefits");
  const [isLoading, setIsLoading] = useState(false);
  const [nextDueDate, setNextDueDate] = useState(0);
  const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);
  const [chargebeePaymentUrl, setChargebeePaymentUrl] = useState("");
  const [show, setShow] = useState({
    bookAVet: false,
    createAClaim: false,
    claimsHistory: false,
    membershipInvoice: false,
    personalAndDelivery: false,
    editPaymentDetails: false,
    editPassword: false,
    deletePet: false,
    addPlan: false,
    editCreditCard: false,
    petId: "null",
  });
  const [data, setData] = useState<any>({
    deletePet: [],
  });
  useEffect(() => {
    if (!isAuthenticated) return history.push("/");
    if (location.pathname == "/dashboard/create-claim")
      return setShow({ ...show, createAClaim: true });
    if (location.pathname == "/dashboard/claims-history")
      return setShow({ ...show, claimsHistory: true });
    if (location.pathname == "/dashboard/book-a-vetchat")
      return setShow({ ...show, bookAVet: true });
    if (location.pathname == "/dashboard/personal-delivery-details")
      return setShow({ ...show, personalAndDelivery: true });
    if (location.pathname == "/dashboard/edit-payment-details")
      return setShow({ ...show, editPaymentDetails: true });
    if (location.pathname == "/dashboard/change-password")
      return setShow({ ...show, editPassword: true });
    if (location.pathname == "/dashboard/membership-invoices")
      return setShow({ ...show, membershipInvoice: true });
    //return history.push("/dashboard");
    if (location.pathname == "/dashboard/pets-plan") {
      const e = document.getElementById("pet-plans");
      if (e) e.scrollIntoView({ behavior: "smooth" });
    }
    (async () => {
      if (!user || !user?._id) return;
      setIsLoading(true);
      await fetchUserData();
      await fetchChargeBeeData();
      await fetchBankData();
      await getCustomer().then(async (r) => {
        if (!r || (r && r.status !== 200)) {
          return createCustomer();
        }
      });
      const subs = await getCustomersSubscription(
        user?._id,
        user?.address?.country
      );
      setSubscriptions(subs);
      setIsLoading(false);
    })();
    //setPetInformation();
    if (
      (user && user.address && !user.address.address1) ||
      (user && !user.address)
    ) {
      history.push("/dashboard/personal-delivery-details");
    }
  }, [isAuthenticated, location]);

  const PetsList = () => {
    const pets = user?.pets;
    if (!pets) return;
    return (
      <ul className="list-items">
        {pets.map((pet: any) => {
          //console.log({ pet }, pet?.name, pet?.subscriptionId, pet?.plan);
          let plan = "";
          //period = "";
          const sub =
            Array.isArray(subscriptions) &&
            subscriptions.find((s: any) => {
              return s.subscription.id == pet?.subscriptionId;
            });
          if (sub) {
            const { billing_period_unit: bpu } = sub.subscription;
            plan = bpu == "month" ? "Paid Monthly" : "Paid Yearly";
            //period = bpu == "month" ? "MONTHLY" : "YEARLY";
          }
          //console.log({ sub });
          const petImage = !pet.imageUrl
            ? dogCatIcon
            : API + imageEndpoint + pet.imageUrl;
          const planDate = () => {
            if (isLoading) return "Loading...";
            if (pet.plan == "NoPlan") return <>No Plan</>;
            if (!sub) return; // || !pet.planStart
            //console.log({ sub });
            const {
              started_at: started,
              status,
              billing_period_unit: bpu,
              current_term_end: termEnd,
              id: subId,
            } = sub.subscription;
            const end = new Date(termEnd * 1000);
            //if (bpu == "month") end.setMonth(end.getMonth() + 1);
            //else end.setFullYear(end.getFullYear() + 1);
            //console.log({ status, end });
            if (
              status != "cancelled" &&
              (nextDueDate == 0 || Date.parse(end.toDateString()) < nextDueDate)
            ) {
              //console.log({ status });
              setNextDueDate(Date.parse(end.toDateString()));
            }
            return (
              <>
                <strong>{plan}</strong>
                <br />
                Plan Renewal Date:
                {""} {end.toLocaleString("en-GB").substring(0, 10)}
                <br />
              </>
            );
          };

          const petButtonOptions = () => {
            //console.log({ pet });
            let showRenewPlan = false;
            if (
              !pet.planStart ||
              new Date(pet.planStart).getTime() + 86400 * 1000 * 335 <
                new Date().getTime() ||
              pet.plan == "NoPlan"
            ) {
              showRenewPlan = true;
            }
            console.log({
              showRenewPlan,
              name: pet.name,
              start: pet.planStart,
              s: new Date(pet.planStart).getTime() + 86400 * 1000 * 335,
              c: new Date().getTime() - 86400 * 1000 * 30,
            });
            return (
              <div className="flex detail-benefits">
                <button
                  className="button button-secondary"
                  onClick={() => {
                    setShow({ ...show, petId: pet._id });
                    setPlanView("detail");
                  }}
                >
                  Pet's Details
                </button>
                {showRenewPlan ? (
                  <span
                    style={{
                      minWidth: "145px",
                      maxWidth: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    <button
                      style={{ cursor: "default" }}
                      className="button btn btn-green square"
                    >
                      <strong>Add Plan</strong>
                      <br />
                      <a
                        style={{ color: "#fff", fontSize: "16px" }}
                        href={`/onboarding/payment-completion?id=${pet._id}&action=renewal-monthly-cycle`}
                      >
                        MONTHLY
                      </a>
                      &nbsp; &nbsp;
                      <a
                        style={{ color: "#fff", fontSize: "16px" }}
                        href={`/onboarding/payment-completion?id=${pet._id}&action=renewal-yearly-cycle`}
                      >
                        YEARLY
                      </a>
                    </button>
                  </span>
                ) : (
                  <button
                    className="button button-primary"
                    onClick={() => {
                      setShow({ ...show, petId: pet._id });
                      setPlanView("benefits");
                    }}
                  >
                    Plan Benefits
                  </button>
                )}
              </div>
            );
          };
          return (
            <li className={show.petId == pet._id ? "show-item" : ""}>
              <div className="li-header">
                <div className="container flex">
                  <div className="description">
                    <img
                      className="trash"
                      style={{ cursor: "pointer" }}
                      src={trashIcon}
                      onClick={() => {
                        setShow({ ...show, deletePet: true });
                        setData({ ...data, deletePet: pet });
                      }}
                    />
                    <img src={petImage} />
                    <p>
                      <strong>
                        Thriving Pets Wellness Plan for {pet.name}
                      </strong>
                      <br />
                    </p>
                  </div>
                  {petButtonOptions()}
                  <div className="flex flex-row align-center plan-detail">
                    <p className="plan-date">{planDate()}</p>
                    <p
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        maxWidth: "40px",
                        minWidth: "40px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        const petId = show.petId == pet._id ? "null" : pet._id;
                        setShow({ ...show, petId });
                      }}
                    >
                      <img className="arrow" src={arrowLeftIcon} />
                    </p>
                  </div>
                </div>
              </div>
              <div className="li-body container">
                <div className="inner-container">
                  <EditPetDetail
                    pet={pet}
                    cancel={() => {
                      setShow({ ...show, petId: "null" });
                    }}
                    planView={planView}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };
  const Modals = () => {
    return (
      <>
        <Modal
          isOpen={show.bookAVet}
          closeModal={() => history.push("/dashboard")}
        >
          <BookAVet closeModal={() => setShow({ ...show, bookAVet: false })} />
        </Modal>
        <Modal
          maxWidth={760}
          isOpen={show.createAClaim}
          closeModal={() => history.push("/dashboard")}
        >
          <CreateAClaim
            closeModal={() => setShow({ ...show, createAClaim: false })}
          />
        </Modal>
        <Modal
          isOpen={show.claimsHistory}
          closeModal={() => history.push("/dashboard")}
        >
          <ClaimsHistory
            closeModal={() => setShow({ ...show, claimsHistory: false })}
          />
        </Modal>
        <Modal
          isOpen={show.membershipInvoice}
          maxWidth={1000}
          closeModal={() => history.push("/dashboard")}
        >
          <MembershipInvoices />
        </Modal>
        <Modal
          maxWidth={760}
          isOpen={show.personalAndDelivery}
          closeModal={() => history.push("/dashboard")}
        >
          <PersonalAndDelivery />
        </Modal>
        <Modal
          maxWidth={760}
          isOpen={show.editPaymentDetails}
          closeModal={() => history.push("/dashboard")}
        >
          <EditPaymentDetails />
        </Modal>
        <Modal
          maxWidth={760}
          isOpen={show.editPassword}
          closeModal={() => history.push("/dashboard")}
        >
          <ChangePassword />
        </Modal>
        <Modal
          maxWidth={750}
          isOpen={show.editCreditCard}
          closeModal={() => setShow({ ...show, editCreditCard: false })}
        >
          <div className="dashboard">
            <div className="content-header">
              <div className="flex align-center">
                <img src={thrivingPetsLogoDarkReverse} />
              </div>
              <div className="content-body">
                {isLoading ? (
                  <h3 style={{ textAlign: "center" }}>Please wait...</h3>
                ) : (
                  <iframe
                    src={chargebeePaymentUrl}
                    style={{
                      width: "100%",
                      minHeight: "550px",
                      background: "#999",
                      overflow: "hidden",
                      borderRadius: "5px",
                    }}
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          maxWidth={400}
          maxHeight={400}
          isOpen={show.addPlan}
          closeModal={() => setShow({ ...show, addPlan: false })}
        >
          <div className="dashboard" style={{ textAlign: "center" }}>
            <br />
            <h3>Choose Option</h3>
            <div>
              <p>
                <strong>590.00 /per year</strong>
              </p>
              <Button
                label="Yearly"
                customClass="button btn btn-purple square active"
                onClick={async () => {
                  alert("Yearly");
                }}
              />
              <br />
              <br />
              <p>
                45.95 /month <br />
                <strong>149.95 </strong>first payment
              </p>
              <Button
                label="Monthly"
                customClass="button btn btn-green square active"
                onClick={async () => {
                  alert("Monthly");
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          maxWidth={400}
          maxHeight={400}
          isOpen={show.deletePet}
          closeModal={() => setShow({ ...show, deletePet: false })}
        >
          <div className="dashboard" style={{ textAlign: "center" }}>
            <br />
            {data.deletePet?.plan == "NoPlan" ? (
              <>
                <h3>
                  Are you sure you wanted to Delete {""}
                  {data.deletePet?.name?.toUpperCase()}?
                </h3>
                <Button
                  label="Delete"
                  customClass="button btn btn-red square"
                  onClick={async () => {
                    const { deletePet: pet } = data;
                    console.log({ pet });
                    await deletePetData(pet._id, DeleteReason.OTHER);
                    setShow({ ...show, deletePet: false });
                    history.push("/dashboard");
                  }}
                />
                <br />
                <Button
                  label="Cancel"
                  customClass="button btn btn-grey square"
                  onClick={() => setShow({ ...show, deletePet: false })}
                />
              </>
            ) : (
              <>
                <h3>
                  {data.deletePet?.name?.toUpperCase()} cannot be deleted!
                </h3>
                <Button
                  label="Close"
                  customClass="button btn btn-grey square"
                  onClick={() => setShow({ ...show, deletePet: false })}
                />
              </>
            )}
          </div>
        </Modal>
      </>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="dashboard">
      <div style={{ padding: "40px 0" }} className="container flex flex-center">
        <div>
          <h2 className="title-2">
            Hi {user?.firstName} {user?.lastName}, welcome to your Thriving Pets
            {""} account
            <span className="subtitle">
              Manage your pet's wellness plan(s) using the options below:
            </span>
          </h2>
          <p style={{ color: "#9284c3", fontSize: "17px;" }}>
            If you use the Shop Now button, the 10% discount will be
            <br />
            applied to your cart automatically at {""}
            {`${YPPA_URL.replace("https:", "").replaceAll("/", "")}`}
          </p>
        </div>
        <img src={catDogd3Icon} />
      </div>
      <div className="section icons-row">
        <div className="container row">
          <div
            style={{ cursor: "pointer" }}
            className="icon-box"
            onClick={() => history.push("/dashboard/pets-plan")}
          >
            <img src={listIcon} />
            <a>
              Current Wellness <br /> Plan(s) & Benefits
            </a>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="icon-box"
            onClick={() => history.push("/dashboard/create-claim")}
          >
            <img src={claimIcon} />
            <a>
              Make a <br />
              Claim
            </a>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="icon-box"
            onClick={() => history.push("/dashboard/claims-history")}
          >
            <img src={claimHistoryIcon} />
            <a>
              Claims <br />
              History
            </a>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="icon-box"
            onClick={() => history.push("/dashboard/membership-invoices")}
          >
            <img src={membershipIcon} />
            <a>
              Membership <br />
              Invoices
            </a>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="icon-box"
            onClick={() => {
              window.open(
                `${YPPA_URL}/discount/Thriving/?redirect=/?ref=${user?.affiliateCode}`,
                "_blank"
              );
            }}
          >
            <img src={hospitalIcon} />
            <a>
              Shop <br />
              Now
            </a>
          </div>
        </div>
      </div>
      <div className="section bordered-row">
        <div className="container">
          <div className="content">
            <h3>
              Account Details
              <a
                className="subtext"
                onClick={() => history.push("/dashboard/change-password")}
              >
                Change Email or Password
              </a>
            </h3>
            <p>
              Email: {user?.email} <br />
              Password: *******
            </p>
            <h3>
              Personal & Delivery Details
              <a
                className="subtext"
                onClick={() =>
                  history.push("/dashboard/personal-delivery-details")
                }
              >
                Edit Personal & Delivery Details
              </a>
            </h3>
            <p>
              <strong>
                {user?.firstName}
                {user?.lastName}
              </strong>
              <br />
              {user?.phone} <br />
              {user?.address?.address1}, {user?.address?.state}
              {""} {user?.address?.city}, <br />
              {user?.address?.country}, {user?.address?.postcode} <br />
            </p>
            <p className="note">
              Note: This address will be used for the delivery of
              <br />
              your parasite prevention products.
            </p>
          </div>
          <div className="content">
            <h3>Payment Details</h3>
            {nextDueDate > 0 && (
              <p style={{ color: "#9284c1" }}>
                Your next payment will be due {""}
                {new Date(nextDueDate).getDate() + `th`} {""}
                of {month[new Date(nextDueDate).getMonth()]} {""}
                {new Date(nextDueDate).getFullYear()}
              </p>
            )}
            <p>
              <strong>Subscriptions payments:</strong> <br />
              <span
                style={{
                  display: "flex",
                  columnGap: "5px",
                }}
              >
                <img src={checkIcon} /> {""}
                CREDIT CARD <br />
              </span>
              NAME: {user?.firstName} {user?.lastName}
              <br />
              CARD: {""}
              {user && user.card && user.card.masked_number
                ? user.card.masked_number.replaceAll("*", "X")
                : "No Information"}
              <br />
              EXP: {""}
              {user && user.card ? (
                <>
                  {user.card.expiry_month}/{user.card.expiry_year}
                </>
              ) : (
                <>No Information</>
              )}
              <br />
              <a
                className="subtext"
                onClick={async () => {
                  setIsLoading(true);
                  setShow({ ...show, editCreditCard: true });
                  const url = await getPaymentMethods();
                  console.log({ url });
                  if (url) {
                    setChargebeePaymentUrl(url);
                    setIsLoading(false);
                  }
                }}
              >
                Update Credit Card Information
              </a>
            </p>
            <p>
              <strong>Account information for claims:</strong> <br />
              <span
                style={{
                  display: "flex",
                  columnGap: "5px",
                }}
              >
                <img src={checkIcon} /> {""}
                BANK ACCOUNT <br />
              </span>
              NAME: {""}
              {user?.bank?.accountName
                ? user?.bank?.accountName
                : "No Information"}
              <br />
              ACC: {""}
              {user?.bank?.acc ? user?.bank?.acc : "No Information"}
              <br />
              BSB: {""}
              {user?.bank?.bsb ? user?.bank?.bsb : "No Information"}
              <br />
              <a
                className="subtext"
                onClick={() => history.push("/dashboard/edit-payment-details")}
              >
                Update Bank Information
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="section header-row">
        <div className="container flex flex-center">
          <div id="pets-plans">
            <h3> Thriving Pets Plans & Benefits</h3>
          </div>
          <h3>
            <a className="subtext">
              Use the buttons below to review your pet’s details and plan
              benefits
            </a>
          </h3>
        </div>
      </div>
      <div className="section" id="pet-plans" style={{ paddingTop: "0" }}>
        {PetsList()}
      </div>
      <div className="section header-row">
        <div className="container" style={{ alignItems: "flex-end" }}>
          <Button
            label="Add Another Thriving Pets Plan"
            customClass="button btn btn-green square"
            onClick={() => history.push("/onboarding/pet-info")}
          />
        </div>
      </div>
      <br />
      {Modals()}
    </div>
  );
};
export default Dashboard;
