import "./Header.scss";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store/Auth";
import { actionCreators as cartActionCreators } from "../../../store/Cart";
import Cart from "../../shop/Cart/Cart";
import Sidebar from "../../app/Sidebar/Sidebar";
import logo from "../../../assets/logo.svg";
import history from "../../../utils/history";
import { MenuItem } from "../../../types/menu";
import shopIcon from "../../../assets/icons/shopHeader.svg";
import arrowLeftIcon from "../../../assets/icons/arrow-left.svg";
import chatIcon from "../../../assets/icons/headset.svg";
import petPlansIcon from "../../../assets/icons/list.svg";
import claimIcon from "../../../assets/icons/claim.svg";
import claimHistoryIcon from "../../../assets/icons/claim-history.svg";
import membershipIcon from "../../../assets/icons/membership.svg";
import padlockIcon from "../../../assets/icons/padlock.svg";
import ambulanceIcon from "../../../assets/icons/ambulance.svg";
import paymentIcon from "../../../assets/icons/payment.svg";
import logoutIcon from "../../../assets/icons/logout-d2.svg";
import logoD3 from "../../../assets/images/thrivingpets-logo-d3.svg";
import { Modal } from "../../layout";

/**
 * The header of the website.
 */
const Header = () => {
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );

  const cart = useSelector((state: ApplicationState) => state.cart?.cart);
  const user = useSelector((state: ApplicationState) => state.user);
  const open = useSelector((state: ApplicationState) => state.cart?.open);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { logout } = bindActionCreators(actionCreators, useDispatch());
  const { updateCartOpen } = bindActionCreators(
    cartActionCreators,
    useDispatch()
  );
  if (!isAuthenticated) return null;

  const initial = () => {
    return `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`;
  };
  /**
   * Items to be rendered in the menu.
   */
  const menuItems: MenuItem[] = [
    {
      label: "Current Wellness Plan(s) & Benefits",
      iconUrl: petPlansIcon,
      onClick: () => history.push("/dashboard/pets-plan"),
    },
    {
      label: `Make a Claim`,
      iconUrl: claimIcon,
      onClick: () => history.push("/dashboard/create-claim"),
    },
    {
      label: `Claims History`,
      iconUrl: claimHistoryIcon,
      onClick: () => history.push("/dashboard/claims-history"),
    },
    {
      label: `Membership Invoices`,
      iconUrl: membershipIcon,
      onClick: () => history.push("/dashboard/membership-invoices"),
    },
    {
      label: `Accounts Details`,
      iconUrl: padlockIcon,
      onClick: () => history.push("/dashboard/change-password"),
    },
    {
      label: `Personal & Delivery Details`,
      iconUrl: ambulanceIcon,
      onClick: () => history.push("/dashboard/personal-delivery-details"),
    },
    {
      label: `Payment Details`,
      iconUrl: paymentIcon,
      onClick: () => history.push("/dashboard/edit-payment-details"),
    },
    {
      label: "Logout",
      iconUrl: logoutIcon,
      onClick: () => logout(),
    },
  ];

  /**
   * Hamburger icon for triggering the opening of the sidebar.
   */
  const renderSidebarTrigger = () => {
    return (
      <div
        className="header__sidebartrigger"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="initialIcon">
          <span>{initial()}</span>
          <img src={arrowLeftIcon} />
        </span>
      </div>
    );
  };

  return (
    <header className="header">
      <div className="header__container">
        <div className="container">
          <div className="flex">
            <Link className="header__logo" to="/">
              <img src={logoD3} />
            </Link>
            {renderSidebarTrigger()}
          </div>
          <Sidebar
            isOpen={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            menuItems={menuItems}
          />
        </div>
      </div>
      <Modal
        isOpen={open ?? false}
        closeModal={() => updateCartOpen(false)}
        maxWidth={600}
        disablePadding
      >
        <Cart isCartOpen={open ?? false} handleCartClose={() => null} />
      </Modal>
    </header>
  );
};

export default Header;
