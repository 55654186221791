import "./PlanBenefitsModal.scss";
import { useEffect, useState } from "react";
import { Modal, Row } from "../../layout";
import { useRemainingClaims } from "../../../hooks/useRemainingClaims";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { Spinner } from "../../../components/common/index";

interface PlanBenefitsProps {
  petId: string;
  modalOpen: boolean;
  closeModal: () => void;
}

const PlanBenefitsModal: React.FC<PlanBenefitsProps> = ({
  petId,
  modalOpen,
  closeModal,
}) => {
  const [claimsMap, setClaimsMap] = useState<Map<any, any>>(new Map());
  const [planExpiry, setPlanExpiry] = useState<undefined | string>(undefined);

  const { getRemainingClaims } = useRemainingClaims(petId);

  const pet = useSelector((state: ApplicationState) =>
    state.user?.pets.find((p) => p._id === petId)
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  if (!pet) throw new Error("Could not find pet with specified ID.");

  useEffect(() => {
    getRemainingClaims().then((res) => {
      setClaimsMap(res);
      setIsLoading(false);
    });
    const planStart = new Date(pet.planStart);
    const planStartPlusYear = new Date(
      planStart.setFullYear(planStart.getFullYear() + 1)
    );
    const pad = (n: number) => (n < 10 ? "0" + n : n);
    setPlanExpiry(
      [
        pad(planStartPlusYear.getDate()),
        pad(planStartPlusYear.getMonth() + 1),
        planStartPlusYear.getFullYear(),
      ].join("/")
    );
  }, [isLoading, pet.plan]);

  if (isLoading === true) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    return (
      <Modal
        isOpen={modalOpen}
        closeModal={closeModal}
        maxHeight={700}
        maxWidth={375}
      >
        <div className="benefits-rows">
          {claimsMap.has("clinicConsultation") && (
            <Row>
              <p className="benefits-rows__type">In-Clinic Consults:</p>
              <p className="benefits-rows__value">
                {claimsMap.get("clinicConsultation")}
              </p>
            </Row>
          )}
          {claimsMap.has("telehealthConsults") && (
            <Row>
              <p className="benefits-rows__type">Online Consults:</p>
              <p className="benefits-rows__value">
                {claimsMap.get("telehealthConsults")}
              </p>
            </Row>
          )}
          {claimsMap.has("discount") && (
            <Row>
              <p className="benefits-rows__type">Discount:</p>
              <p className="benefits-rows__value">
                {((1 - claimsMap.get("discount")) * 100).toFixed(0)}%
              </p>
            </Row>
          )}
          {claimsMap.has("vaccination") && (
            <Row>
              <p className="benefits-rows__type">Vaccination Claims:</p>
              <p className="benefits-rows__value">
                {claimsMap.get("vaccination")}
              </p>
            </Row>
          )}
          {claimsMap.has("dental") && (
            <Row>
              <p className="benefits-rows__type">Dental Claims:</p>
              <p className="benefits-rows__value">{claimsMap.get("dental")}</p>
            </Row>
          )}
          {claimsMap.has("desex") && (
            <Row>
              <p className="benefits-rows__type">Desex Claims:</p>
              <p className="benefits-rows__value">{claimsMap.get("desex")}</p>
            </Row>
          )}
          {claimsMap.has("wellnessBloodTest") && (
            <Row>
              <p className="benefits-rows__type">Wellness Blood Tests:</p>
              <p className="benefits-rows__value">
                {claimsMap.get("wellnessBloodTest")}
              </p>
            </Row>
          )}
          {pet.plan === "ThrivePlusDogCatPlan" && pet.planAddon && (
            <Row>
              <p className="benefits-rows__type">Annual Preventatives:</p>
              <p className="benefits-rows__value">Active</p>
            </Row>
          )}
          {planExpiry && (
            <Row>
              <p className="benefits-rows__type">Benefit Renewal:</p>
              <p className="benefits-rows__value">{planExpiry}</p>
            </Row>
          )}
          {pet.shipped && (
            <Row>
              <p className="benefits-rows__type">Shipped:</p>
              <p className="benefits-rows__value">{pet.shipped}/12</p>
            </Row>
          )}
          {pet.nextShipment && (
            <Row>
              <p className="benefits-rows__type">Next Shipment:</p>
              <p className="benefits-rows__value">{pet.nextShipment}</p>
            </Row>
          )}
        </div>
      </Modal>
    );
  }
};

export default PlanBenefitsModal;
