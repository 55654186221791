import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useFormik } from "formik";
import { isNotEmpty, isValidBSB } from "../../utils/formik-validate";
import {
  TextInput,
  DropdownMultipleInput,
  FileInput,
} from "../../components/input";
import { Button } from "../../components/common";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import { useRemainingClaims } from "../../hooks/useRemainingClaims";
import history from "../../utils/history";
import { thrivingPetsLogoDarkReverse, claimIcon, checkIcon } from "./Module";

/*interface FormTypes {
  vet: string;
  puppyPreschool: string;
  behaviourConsult: string;
  free: string;
}*/
interface BookAVetType {
  closeModal: () => void;
}
/*const cogsworth: { [key: string]: FormTypes } = {
  forms: {
    vet: "https://www.cogsworth.com/book/ckqblndj6005j0nud66hx6rm6",
    puppyPreschool: "https://www.cogsworth.com/book/ckqhbpcxu004w0nzyhsgvgjsi",
    behaviourConsult:
      "https://www.cogsworth.com/book/ckqhbe8h3006n0nx54jgzc2tx",
    free: "https://www.cogsworth.com/book/ckr453ta1009c0ntc2pfq1v0c",
  },
  forms_nz: {
    vet: "https://www.cogsworth.com/book/ckv0blj7j003k0tksctqr1m6v",
    puppyPreschool: "https://www.cogsworth.com/book/ckvgjyw3h02750skwa4vc3jhx",
    behaviourConsult:
      "https://www.cogsworth.com/book/ckvhhzws8039k0umb9y2l403k",
    free: "https://www.cogsworth.com/book/ckvism1r5047a0skw6sv4dqi4",
  },
};*/

const CreateAClaim: React.FC<BookAVetType> = ({ closeModal }) => {
  const location = useLocation();
  const { fetchUserData, addMultipleClaim } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => {
    if (!state.user) return state.user;
    state.user.pets = state.user.pets.filter((p) => p.plan != "NoPlan");
    return state.user;
  });
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [usedBankInfo, setUsedBankInfo] = useState(true);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [theValues, setTheValues] = useState<{
    type: {
      value: string;
      label: string;
      amount: string;
    }[];
    pet: string;
    invoice: File | "";
    vet: string | "";
    reimbursementType: "bank";
    bsb: string | "";
    acc: string | "";
    accountName: string | "";
    amount: string;
  }>({
    type: [],
    pet: user?.pets && user?.pets[0] ? user.pets[0]._id : "",
    invoice: "",
    vet: user?.affiliateName ? user?.affiliateName : "",
    reimbursementType: "bank",
    bsb: user?.bank?.bsb ? user.bank.bsb : "",
    acc: user?.bank?.acc ? user.bank.acc : "",
    accountName: user?.bank?.accountName ? user.bank.accountName : "",
    amount: "",
  });
  const [availableClaims, setAvailableClaims] = useState<any[]>([]);
  const claimChoices = [
    { value: "vaccination", label: "Annual Vaccination Allowance", max: 100 },
    { value: "clinicConsultation", label: "Clinic Consultation", max: 75 },
    { value: "dental", label: "Dental Allowance", max: 100 },
    {
      value: "wellnessBloodTest",
      label: "Wellness Screen (Blood Test) Allowance",
      max: 100,
    },
    { value: "desex", label: "Desexing Allowance", max: 100 },
  ];
  const CreateClaim = useFormik({
    initialValues: theValues,
    validate: async ({ bsb, accountName, acc, invoice }) => {
      let errors = {
        ...isNotEmpty(accountName, "accountName"),
        ...isNotEmpty(bsb, "bsb"),
        ...isValidBSB(bsb, "bsb"),
        ...isNotEmpty(acc, "acc"),
        ...isNotEmpty(invoice && invoice.toString(), "invoice"),
      };
      if (user?.address?.country != "Australia")
        errors = {
          ...isNotEmpty(accountName, "accountName"),
          ...isNotEmpty(acc, "acc"),
          ...isNotEmpty(invoice && invoice.toString(), "invoice"),
        };
      return errors;
    },
    onSubmit: async (values) => {
      //return console.log({ values });
      const { type, invoice, vet } = values;
      type.map((m: any) => {
        m.amount = Number(m.amount) > Number(m.max) ? String(m.max) : m.amount;
        return m;
      });
      if (!user) return history.push("/");
      const pet = user.pets.find((p) => p._id === values.pet);
      if (!pet) return toast.error("Please select a pet!");
      if (!invoice) return toast.error("Please upload invoice!");
      if (!Array.isArray(type) || type.length < 1)
        return toast.error("Please add claim type!");
      const errors: any = [];
      type.map((t) => {
        if (!t.amount || !Number(t.amount))
          errors.push(`Invalid ${t.label} Amount`);
      });
      if (errors.length > 0) {
        errors.map((e: string) => {
          return toast.error(e);
        });
        return;
      }
      setIsLoading(true);
      const data = {
        claim: { type, invoice, vet },
        pet: {
          id: values.pet,
          name: pet.name,
        },
        user: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        },
        reimbursement: {
          type: values.reimbursementType,
          bsb: values.bsb,
          accountNumber: values.acc,
          amount: values.amount,
        },
      };
      console.log({ data });
      await addMultipleClaim(data);
      await fetchUserData();
      setIsLoading(false);
      history.push("/dashboard");
    },
  });
  useEffect(() => {
    if (!isAuthenticated) history.push("/");
    else fetchUserData();
  }, [isAuthenticated, location]);

  const { getRemainingClaims } = useRemainingClaims(CreateClaim.values.pet);
  useEffect(() => {
    if (!CreateClaim.values.pet || CreateClaim.values.pet == "") return;
    getRemainingClaims().then((map) => {
      const set = claimChoices.filter(
        (claim) =>
          map.get(claim.value) > 0 || map.get(claim.value) === "Unlimited"
      );
      setAvailableClaims(set);
    });
    if (!user?.bank) setUsedBankInfo(false);
  }, [CreateClaim.values.pet]);
  /*
   * RENDERING
   */
  const choosePet = () => {
    if (!user || !user.pets) return;
    return user.pets.map((p: any) => {
      return (
        <>
          <button
            className={
              p._id == CreateClaim.values.pet
                ? "button btn btn-purple square"
                : "button btn btn-purple-light square"
            }
            onClick={() => {
              //CreateClaim.values.type = "";
              CreateClaim.values.pet = p._id;
              setReload(!reload);
            }}
          >
            {p.name}
          </button>
        </>
      );
    });
  };
  return (
    <div className="dashboard">
      <div className="content-header">
        <div className="flex align-center">
          <img src={thrivingPetsLogoDarkReverse} />
        </div>
      </div>
      <div className="content-body">
        <div className="field flex flex-start flex-align-center">
          <img style={{ width: "100px", maxWidth: "100%" }} src={claimIcon} />
          <h1>Make a Claim</h1>
        </div>
        <label>
          <strong>Select a Pet:</strong>
        </label>
        <div>{choosePet()}</div>
      </div>
      {CreateClaim.values.pet != "" && availableClaims.length > 0 ? (
        <>
          <div className="field flex flex-start">
            <DropdownMultipleInput
              label="Claim Type – you can select and add multiple claims to be processed at the same time."
              options={availableClaims}
              value=""
              touched={true}
              onChange={(opts) => {
                //console.log({ opts });
                if (!Array.isArray(opts)) return;
                const type: any = opts.map((t: any) => {
                  //t.amount = 0;
                  return t;
                });
                CreateClaim.values.type = type;
                setReload(!reload);
                //CreateClaim.handleChange("type");
              }}
            />
          </div>
          <div className="field flex flex-start image-uploader">
            <FileInput
              label="Upload Invoice"
              touched={CreateClaim.touched.invoice}
              error={CreateClaim.errors.invoice}
              onChange={(event) => {
                if (event.currentTarget.files) {
                  CreateClaim.setFieldValue(
                    "invoice",
                    event.currentTarget.files[0]
                  );
                }
              }}
            />
          </div>
          {Array.isArray(CreateClaim.values.type) &&
            CreateClaim.values.type.map((type: any, k) => {
              return (
                <div className="field flex flex-start">
                  <TextInput
                    label={`${type.label} Amount (upto $${type.max})`}
                    value={CreateClaim.values.type[k].amount}
                    type="number"
                    max={type.max}
                    touched={true}
                    onChange={CreateClaim.handleChange(`type[${k}].amount`)}
                  />
                </div>
              );
            })}
          <br />
          <h3>
            ACCOUNT DETAILS FOR CLAIM PAYMENT <br />
          </h3>
          <div>
            {user?.bank?.accountName && (
              <p>
                <strong>Are these account details correct?</strong> <br />
                <span
                  style={{
                    display: "flex",
                    columnGap: "5px",
                    margin: "10px 0",
                  }}
                >
                  <img src={checkIcon} /> {""}
                  BANK ACCOUNT <br />
                </span>
                {user?.bank?.accountName} <br />
                ACC: {user?.bank?.acc} <br />
                BSB: {user?.bank?.bsb} <br />
              </p>
            )}
            {user?.bank && (
              <ul className="radio-list">
                <li>
                  <input
                    type="radio"
                    name="iscorrect"
                    checked={usedBankInfo ? true : false}
                    onClick={() => {
                      if (user?.bank) setUsedBankInfo(true);
                    }}
                  />
                  <strong>Yes</strong>
                </li>
                <li>
                  <input
                    type="radio"
                    name="iscorrect"
                    checked={usedBankInfo ? false : true}
                    onClick={() => setUsedBankInfo(false)}
                  />
                  {""} <strong>No</strong> (Nominate New Account for Rebate)
                </li>
              </ul>
            )}
          </div>
          {!usedBankInfo && (
            <>
              <div className="field flex flex-start">
                <TextInput
                  label="Account Name"
                  value={CreateClaim.values.accountName}
                  error={CreateClaim.errors.accountName}
                  touched={true}
                  onChange={CreateClaim.handleChange("accountName")}
                />
              </div>
              <div className="field flex flex-start">
                <TextInput
                  label="ACC"
                  value={CreateClaim.values.acc}
                  error={CreateClaim.errors.acc}
                  touched={true}
                  onChange={CreateClaim.handleChange("acc")}
                />
                {user?.address?.country == "Australia" && (
                  <TextInput
                    label="BSB"
                    value={CreateClaim.values.bsb}
                    error={CreateClaim.errors.bsb}
                    touched={true}
                    onChange={CreateClaim.handleChange("bsb")}
                  />
                )}
              </div>
            </>
          )}
          <div className="content-footer">
            {CreateClaim?.errors?.bsb && (
              <span style={{ color: "red" }}>{CreateClaim?.errors?.bsb}</span>
            )}
            <p>
              <i>
                Claims are reviewed daily (Monday to Friday) for approval. Once
                a claim is approved, please allow up to 48 hours for payment
                processing. You will be notified via email once a claim is
                approved and paid.
              </i>
            </p>
            <div>
              <Button
                label="Create A Claim"
                isDisabled={isLoading}
                customClass="button btn btn-purple square"
                onClick={() => {
                  CreateClaim.handleSubmit();
                }}
              />
              <Button
                label="Cancel"
                customClass="button btn btn-grey square"
                onClick={() => history.push("/dashboard")}
              />
            </div>
          </div>
        </>
      ) : (
        <h3>
          This pet is either not on a plan or has no more claimable items
          available on their plan.
        </h3>
      )}
    </div>
  );
};

export default CreateAClaim;
