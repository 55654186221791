import "./LineItem.scss";
import React from "react";
import { ShoppingCartLineItem } from "../../../store/Cart";

function LineItemm(props: {
  loading: boolean;
  line_item: ShoppingCartLineItem;
  updateLineItemInCart: (arg0: any, arg1: any) => void;
  removeLineItemInCart: (arg0: any) => void;
}) {
  const decrementQuantity = (lineItemId: any) => {
    const updatedQuantity = (props?.line_item?.quantity ?? 0) - 1;
    props.updateLineItemInCart(lineItemId, updatedQuantity);
  };

  const incrementQuantity = (lineItemId: any) => {
    const updatedQuantity = (props?.line_item?.quantity ?? 0) + 1;
    props.updateLineItemInCart(lineItemId, updatedQuantity);
  };

  return (
    <li className={`line-item ${props?.loading ? "line-item-loading" : ""}`}>
      <div className="line-item-img">
        {props.line_item.variant.image ? (
          <img
            src={props.line_item.variant.image.src}
            alt={`${props.line_item.title} product shot`}
          />
        ) : null}
      </div>
      <div className="line-item-content">
        <div className="line-item-content-row">
          <span className="line-item-title">
            {props.line_item.title} {props.line_item.variant.title}
          </span>
        </div>
        <div className="line-item-content-row">
          <div className="line-item-quantity-container">
            <button
              className="line-item-quantity-update"
              onClick={() => decrementQuantity(props.line_item.id)}
            >
              -
            </button>
            <span className="line-item-quantity">
              {props.line_item.quantity}
            </span>
            <button
              className="line-item-quantity-update"
              onClick={() => incrementQuantity(props.line_item.id)}
            >
              +
            </button>
          </div>
          <span className="line-item-price">
            $
            {(props.line_item.quantity * props.line_item.variant.price).toFixed(
              2
            )}
          </span>
          <button
            className="line-item-remove"
            onClick={() => props.removeLineItemInCart(props.line_item.id)}
          >
            ×
          </button>
        </div>
      </div>
    </li>
  );
}

export default LineItemm;
