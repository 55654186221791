import "./Footer.scss";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { Container } from "../../layout";
import logo from "../../../assets/logo.svg";
import logoFooterIcon from "../../../assets/images/thrivingpets-logo-footer.svg";
import phoneIcon from "../../../assets/images/phone.png";
import emailIcon from "../../../assets/icons/email.svg";
import homeIcon from "../../../assets/icons/home-light.svg";
import { menuLinks } from "./FooterLinks";

const YPPA_DOMAIN =
  window.location.host != "account.thrivingpets.co.nz"
    ? "yourpetpa.com.au"
    : "yourpetpa.co.nz";
export interface MenuItem {
  /**
   * The label to be displayed in the menu link.
   */
  label: string;
  /**
   * The function to be called when the menu link is clicked.
   */
  href: string;
}

export interface SocialItems {
  /**
   * The icon to be displayed.
   */
  icon: string;
  /**
   * The function to be called when the icon is clicked.
   */
  href: string;
}

/**
 * The footer of the website.
 */
const Footer: React.FC = () => {
  // const footerRef = useRef<HTMLElement>(null);
  // const [height, setHeight] = useState(0);

  // // Coming back to this to fix footer content overlap
  // useLayoutEffect(() => {
  //   if (footerRef.current) {
  //     setHeight(footerRef.current.clientHeight);
  //   }
  // }, [footerRef.current?.clientHeight]);

  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );

  if (!isAuthenticated) {
    return null;
  }

  const renderMenuItems = (menu: MenuItem[]) => {
    const listItems = menu.map(({ label, href }) => (
      <li>
        <a href={href}>{label}</a>
      </li>
    ));
    return <ul>{listItems}</ul>;
  };

  const renderSocialIcons = (social: SocialItems[]) => {
    const socialList = social.map(({ icon, href }) => (
      <a href={href}>
        <img className="footer__socialicons" src={icon} />
      </a>
    ));
    return <div className="footer__social">{socialList}</div>;
  };

  return (
    <footer className="footer">
      <div className="container border-d2">
        <img src={logoFooterIcon} />
      </div>
      <Container>
        <div className="footer__row">
          <div className="footer__col">
            <a className="footer__phone" href="tel:1300473872">
              <img className="footer__phoneicon" src={phoneIcon} />
              <span className="footer__phonelabel">1300 473 872</span>
            </a>
            <a
              className="footer__phone"
              href={`mailto:info@thrivingpets.com.au`}
            >
              <img className="footer__phoneicon" src={emailIcon} />
              <span className="footer__phonelabel">
                info@thrivingpets.com.au
              </span>
            </a>
            <a className="footer__phone" href="tel:1300473872">
              <img className="footer__phoneicon" src={homeIcon} />
              <span className="footer__phonelabel">
                12/45-47 Townshopship Drive <br />
                Burleight Heads, QLD <br />
                4220
              </span>
            </a>
          </div>
          <div className="footer__col">
            <h4>Quick Links</h4>
            {renderMenuItems(menuLinks.quickLinks)}
          </div>
          <div className="footer__col">
            <h4>Help</h4>
            {renderMenuItems(menuLinks.help)}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
