import React from "react";
import { useSelector } from "react-redux";
import { Container, Page, Section, Overflow } from "../../components/layout";
import { ApplicationState } from "../../store";
import { getFullName } from "../../utils/user-utils";
import history from "../../utils/history";
import SecondaryMenu from "../common/SecondaryMenu/SecondaryMenu";
import { ProfileImage } from "../common";
const imageEndpoint = process.env.REACT_APP_BACKEND_IMAGE_ENDPOINT;
const API = process.env.REACT_APP_BACKEND_API || "";
import plusImage from "../../assets/images/plus.png";
import { useLocation } from "react-router-dom";
import { MenuItem } from "../../types/menu";
import homeIcon from "../../assets/icons/home.svg";
import shopIcon from "../../assets/icons/shop.svg";
import criticalIcon from "../../assets/icons/critical concern.svg";
import educationIcon from "../../assets/icons/education.svg";

const menuItems: MenuItem[] = [
  {
    label: "Home",
    iconUrl: homeIcon,
    route: "/",
    onClick: () => {
      history.push("/");
    },
  },
  /*{
    label: "Shop Pet Store and Pharmacy",
    iconUrl: shopIcon,
    route: "/shop",
    onClick: () => {
      history.push("/shop");
    },
  },
  {
    label: "Helpful Pet Calculators",
    iconUrl: criticalIcon,
    route: "/assistant",
    onClick: () => {
      history.push("/assistant");
    },
  },
  /*{
    label: "Education Resources",
    iconUrl: educationIcon,
    route: "/education",
    onClick: () => {
      history.push("/education/cats");
    },
  },*/
];

const HomeTemplate: React.FC<{ title: string }> = ({ children, title }) => {
  const userState = useSelector((state: ApplicationState) => state.user);
  const pets = userState?.pets || [];
  const location = useLocation();

  return (
    <Page title={title}>
      <Section>
        <Container>
          <h1>Welcome {getFullName(userState)}!</h1>
          <Overflow>
            {pets.map((pet) => (
              <ProfileImage
                label={pet.name}
                imageUrl={API + imageEndpoint + pet.imageUrl}
                onClick={() => {
                  history.push(`/pet/id/${pet._id}`);
                }}
              />
            ))}
            <ProfileImage
              label="Add Pet"
              imageUrl={plusImage}
              onClick={() => {
                history.push(`/pet/add`);
              }}
            />
          </Overflow>
          <SecondaryMenu
            menuItems={menuItems}
            activeRouteCompare={location.pathname}
          />
          {children}
        </Container>
      </Section>
    </Page>
  );
};

export default HomeTemplate;
