import React, { useEffect, useState } from "react";
import { Accordian } from "../../components/layout";
import icon from "../../assets/icons/my subscriptions.svg";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import Subscription from "../../components/common/Subscription/Subscription";
import { bindActionCreators } from "redux";
import { actionCreators as authActionCreators } from "../../store/Auth";
import { actionCreators as userActionCreators } from "../../store/User";
import { Spinner } from "../../components/common";
import history from "../../utils/history";

const MySubscriptions: React.VFC = () => {
  const plans = useSelector((state: ApplicationState) =>
    state.global?.planConfig ? state.global?.planConfig.plans : []
  );
  const [isFetching, setIsFetching] = useState(true);
  const { fetchUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );

  useEffect(() => {
    const fetchPetSubscriptions = async () => {
      await fetchUserData();
      setIsFetching(false);
    };
    fetchPetSubscriptions();
  }, []);

  const pets = useSelector((state: ApplicationState) => state?.user?.pets);
  const petsOnPlan = pets?.filter((pet) => pet.plan !== "NoPlan");

  const renderContent = () => {
    if (!isFetching && !petsOnPlan?.length) {
      return <p>You have no subscriptions.</p>;
    } else if (!isFetching && petsOnPlan?.length) {
      return petsOnPlan?.map((pet) => {
        return (
          <Subscription
            petId={pet._id}
            planName={plans.find((p) => p.planKey === pet.plan)?.name}
            petName={pet.name}
          />
        );
      });
    } else {
      return <Spinner />;
    }
  };

  return (
    <Accordian
      title="My Subscriptions"
      iconUrl={icon}
      fixedBottomLink={{
        label: "+ new subscription",
        onClick: () => {
          history.push("/onboarding/pet-info");
        },
      }}
    >
      {renderContent()}
    </Accordian>
  );
};

export default MySubscriptions;
