import React from "react";
import { Helmet } from "react-helmet";

/**
 * Component used load and manage the HelpScout chatbot.
 */
const HelpScout: React.VFC = () => {
  // Scripts to be added to the header
  const headerScripts = [
    `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`,
    `window.Beacon('init', 'a2864b52-5b48-46f7-981f-bdb3dd1d727f')`,
  ];

  const renderScripts = () => {
    return headerScripts.map((script) => {
      return <script type="text/javascript">{script}</script>;
    });
  };

  return <Helmet>{renderScripts()}</Helmet>;
};

export default HelpScout;
