import Login from "../../../pages/Login/Login";
//import Signup from "../../../pages/Signup/Signup";
import Onboarding from "../../../pages/Onboarding/Onboarding";
import PaymentCompletion from "../../../pages/Onboarding/PaymentComplete";
import PetInfo from "../../../pages/Onboarding/PetInfo";
import PetParentInfo from "../../../pages/Onboarding/PetParentInfo";
import AccountRegistration from "../../../pages/Onboarding/AccountSetup";
import SuccessOnboarding from "../../../pages/Onboarding/SuccessOnboarding";
import Dashboard from "../../../pages/Dashboard/Dashboard";
import Home from "../../../pages/Home/Home";
/*import Contact from "../../../pages/Contact/Contact";
import Shop from "../../../pages/Shop/Shop";
import Pet from "../../../pages/Pet/Pet";
import PetAssistance from "../../../pages/PetAssistance/PetAssistance";
import EducationResources from "../../../pages/EducationResources/EducationResources";
import EducationArticle from "../../../pages/EducationArticle/EducationArticle";
import PetAdd from "../../../pages/PetAdd/PetAdd";*/
//import PetAddSuccessful from "../../../pages/PetAddSuccessful/PetAddSuccessful";
//import ClaimAdd from "../../../pages/ClaimAdd/ClaimAdd";
/*import ShopCategory from "../../../pages/ShopCategory/ShopCategory";
import ProductDetails from "../../../pages/Product/ProductDetails";
import PurchaseComplete from "../../shop/PurchaseComplete/PurchaseComplete";*/
import PasswordReset from "../../../pages/PasswordReset/PasswordReset";
/*import AppointmentChoosePet from "../../../pages/AppointmentChoosePet/AppointmentChoosePet";
import AppointmentChooseBooking from "../../../pages/AppointmentChooseBooking/AppointmentChooseBooking";*/
/*import WellnessPlanOverview from "../../../pages/WellnessPlanOverview/WellnessPlanOverview";
import WellnessPlansCheckout from "../../../pages/WellnessPlansCheckout/WellnessPlansCheckout";
import WellnessPlanSuccess from "../../../pages/WellnessPlanSuccess/WellnessPlanSuccess";
import EducationChoose from "../../../pages/EducationChoose/EducationChoose";
import ShopSearch from "../../../pages/ShopSearch/ShopSearch";*/
import FourZeroFour from "../../../pages/FourZeroFour/FourZeroFour";
//import MediaQueries from "../../../pages/Dev/MediaQueries";
//import StepperTesting from "../../../pages/Dev/StepperTesting";

/*const devRoutes = [
  {
    path: "/dev/mediaqueries",
    name: "MediaQueries",
    exact: true,
    Component: MediaQueries,
    secured: true,
  },
  {
    path: "/dev/stepper",
    name: "StepperTesting",
    exact: true,
    Component: StepperTesting,
    secured: true,
  },
];*/

export const routes = [
  //...devRoutes,
  {
    path: "/login",
    name: "Login",
    exact: true,
    Component: Login,
  },
  /*{
    path: "/signup",
    name: "Sign-Up",
    exact: true,
    Component: Signup,
  },*/
  {
    path: "/onboarding",
    name: "On-boarding",
    exact: true,
    Component: Onboarding,
  },
  {
    path: "/onboarding/account-setup",
    name: "On-boarding",
    exact: true,
    Component: AccountRegistration,
  },
  {
    path: "/onboarding/pet-parent-info",
    name: "On-boarding",
    exact: true,
    Component: PetParentInfo,
  },
  {
    path: "/onboarding/pet-info",
    name: "On-boarding",
    exact: true,
    Component: PetInfo,
  },
  {
    path: "/onboarding/payment-completion",
    name: "On-boarding",
    exact: true,
    Component: PaymentCompletion,
  },
  {
    path: "/onboarding/success",
    name: "On-boarding",
    exact: true,
    Component: SuccessOnboarding,
  },
  {
    path: "/dashboard/",
    name: "Dashboard",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/pets-plan",
    name: "Pets Plan",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/create-claim",
    name: "Create Claim",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/claims-history",
    name: "Claims History",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/personal-delivery-details",
    name: "Personal & Delivery Details",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/change-password",
    name: "Change Password",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/edit-payment-details",
    name: "Edit payment details",
    exact: true,
    Component: Dashboard,
  },
  {
    path: "/dashboard/membership-invoices",
    name: "Membership Invoices",
    exact: true,
    Component: Dashboard,
  },
  /*{
    path: "/dashboard/book-a-vetchat",
    name: "Book a VetChat",
    exact: true,
    Component: Dashboard,
  },*/
  {
    path: "/",
    name: "Home",
    exact: true,
    Component: Home,
    secured: true,
  },
  /*{
    path: "/pet/id/:petId",
    name: "Pet",
    exact: true,
    Component: Pet,
    secured: true,
  },
  {
    path: "/pet/add",
    name: "PetAdd",
    exact: true,
    Component: PetAdd,
    secured: true,
  },
  {
    path: "/pet/add/successful",
    name: "PetAddSuccessful",
    exact: true,
    Component: PetAddSuccessful,
    secured: true,
  },*/
  /*{
    path: "/shop",
    name: "Shop",
    exact: true,
    Component: Shop,
    secured: true,
  },
  {
    path: "/shop/search/",
    name: "Search",
    exact: true,
    Component: ShopSearch,
    secured: true,
  },
  {
    path: "/shop/category/:id",
    name: "Category",
    exact: true,
    Component: ShopCategory,
    secured: true,
  },
  {
    path: "/shop/product/:id",
    name: "Product",
    exact: true,
    Component: ProductDetails,
    secured: true,
  },
  {
    path: "/shop/complete/:id",
    name: "PurchaseComplete",
    exact: true,
    Component: PurchaseComplete,
    secured: true,
  },
  {
    path: "/assistant",
    name: "PetAssistance",
    exact: true,
    Component: PetAssistance,
    secured: true,
  },
  {
    path: "/education/",
    name: "EducationChoose",
    exact: true,
    Component: EducationChoose,
    secured: true,
  },
  {
    path: "/education/:resourceType",
    name: "EducationResources",
    exact: true,
    Component: EducationResources,
    secured: true,
  },*/
  // {
  //   path: "/education/:resourceType/:articleId",
  //   name: "EducationArticle",
  //   exact: true,
  //   Component: EducationArticle,
  //   secured: true,
  // },
  /*{
    path: "/wellnessplans",
    name: "WellnessPlanOverview",
    exact: true,
    Component: WellnessPlanOverview,
    secured: true,
  },
  {
    path: "/wellnessplans/checkout",
    name: "WellnessPlans",
    exact: true,
    Component: WellnessPlansCheckout,
    secured: true,
  },
  {
    path: "/wellnessplans/success",
    name: "WellnessPlanSuccess",
    exact: true,
    Component: WellnessPlanSuccess,
    secured: true,
  },
  {
    path: "/contact",
    name: "Contact",
    exact: true,
    Component: Contact,
    secured: true,
  },*/
  {
    path: "/password/reset",
    name: "PasswordReset",
    exact: true,
    Component: PasswordReset,
    secured: false,
  },
  /*{
    path: "/claim/add",
    name: "ClaimAdd",
    exact: true,
    Component: ClaimAdd,
    secured: true,
  },
  /*{
    path: "/appointment/pet",
    name: "AppointmentChoosePet",
    exact: true,
    Component: AppointmentChoosePet,
    secured: true,
  },
  {
    path: "/appointment/booking",
    name: "AppointmentChooseBooking",
    exact: true,
    Component: AppointmentChooseBooking,
    secured: true,
  },*/
  {
    path: "/",
    name: "Empty",
    exact: false,
    Component: () => <FourZeroFour />,
  },
];
