import { BackLink } from "../../components/common";
import { Card, Container, Page, Section } from "../../components/layout";

const FourZeroFour: React.VFC = () => {
  return (
    <Page title="Add Pet">
      <Section>
        <Container>
          <BackLink />
          <Card>
            <h1>404 - Page not found</h1>
          </Card>
        </Container>
      </Section>
    </Page>
  );
};

export default FourZeroFour;
