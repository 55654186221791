type BasicValidator = (value: string) => boolean;

interface Validate {
  [key: string]: BasicValidator;
}

export const validate: Validate = {
  /**
   * Returns whether a provided string is not empty.
   */
  isNotEmpty: (value) => {
    return !!value;
  },

  /**
   * Checks to see in provided value is a valid email address.
   */
  isEmail: (value) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value);
  },

  /**
   * Checks to see if the string contains only digits and at most one decimal place.
   */
  isFloat: (value) => {
    const regex = /^\d+(\.\d{1,2})?$/;
    return regex.test(value);
  },
  /**
   *
   */
  isValidCountry: (value) => {
    if (value === "Australia" || value === "New Zealand") {
      return true;
    } else {
      return false;
    }
  },
  /**
   * Checks to see if the string only contains digits.
   */
  isInteger: (value) => {
    const regex = /^\d+$/g;
    return regex.test(value);
  },

  /**
   * Checks if a date string is in the past.
   */
  isInPast: (value) => {
    const targetDate = new Date(value);
    const currentDate = new Date();
    if (!targetDate) {
      return false;
    }
    return targetDate < currentDate;
  },
};
