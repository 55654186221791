import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Claim, Spinner } from "../../components/common";
import { Accordian, Modal } from "../../components/layout";
import { ApplicationState } from "../../store";
import { ClaimData } from "../../types/claims";
import { getClaims } from "../../api/claim";
import _ from "lodash";
import history from "../../utils/history";
import icon from "../../assets/icons/my claims.svg";

const MyClaims: React.VFC = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [claims, setClaims] = useState<ClaimData[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const clickedClaim = claims[0];

  const pets = useSelector((state: ApplicationState) => state?.user?.pets);

  /**
   * Fetch claims everytime the pets change.
   */
  useEffect(() => {
    if (pets && pets.length) {
      const fetchAndSetClaims = async () => {
        const claimList = [];
        for (const pet in pets) {
          claimList.push(...pets[pet].claims);
        }
        setClaims((await getClaims(claimList)).reverse());
        setIsFetching(false);
      };
      fetchAndSetClaims();
    }
  }, [pets]);

  const renderClaimModal = () => {
    return (
      <Modal isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <Button
          label="Cancel"
          type="primary"
          onClick={() => {
            setModalOpen(false);
          }}
        />
      </Modal>
    );
  };

  const renderContent = () => {
    if (!isFetching && !claims.length) {
      return <p>You have made no claims</p>;
    } else if (!isFetching && claims.length) {
      return (
        <>
          {claims.map((claim: ClaimData) => (
            <Claim claim={claim} />
          ))}
          {renderClaimModal()}
        </>
      );
    } else {
      return <Spinner />;
    }
  };

  return (
    <Accordian
      title="My Claims"
      iconUrl={icon}
      fixedBottomLink={{
        label: "+ new claim",
        onClick: () => {
          history.push("/claim/add");
        },
      }}
    >
      {renderContent()}
    </Accordian>
  );
};

export default MyClaims;
