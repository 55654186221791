import "./AccordianLookingButton.scss";
import React from "react";

interface AccordianLookingButtonProps {
  /**
   * The title to be shown in the button.
   */
  title: string;
  /**
   * The function to be called when the button is clicked.
   */
  icon?: string;

  onClick: () => void;
}

const AccordianLookingButton: React.FC<AccordianLookingButtonProps> = ({
  children,
  title,
  icon = undefined,
  onClick,
}) => {
  const renderIcon = () => {
    if (!icon) return;
    return (
      <div className="accordian__icon">
        <img src={icon} />
      </div>
    );
  };
  return (
    <div className="accordianlookingbutton" onClick={onClick}>
      {renderIcon()}
      <div className="accordianlookingbutton__title">
        {title}
        {children}
      </div>
      <div className="accordianlookingbutton__chevron" />
    </div>
  );
};

export default AccordianLookingButton;
