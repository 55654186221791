import "./Onboarding.scss";
import thrivingPetsLogoLight from "../../assets/icons/thrivingpets-logo-light.svg";
import thrivingPetsLogoDarkReverse from "../../assets/icons/thrivingpets-logo-dark-reverse.svg";
import truckIcon from "../../assets/images/icons/truck.svg";
import thrivingPetsLogo from "../../assets/icons/thrivingpets-logo-dark.svg";
import chatIcon from "../../assets/icons/chat.svg";
import checkIcon from "../../assets/icons/check.svg";
import arrowLeftIcon from "../../assets/icons/arrow-left.svg";
import arrowRightIcon from "../../assets/icons/arrow-right.svg";
import hospitalIcon from "../../assets/icons/hospital.svg";
import injectionIcon from "../../assets/icons/injection.svg";
import stethoscopeIcon from "../../assets/icons/stethoscope.svg";
import toothbrushIcon from "../../assets/icons/toothbrush.svg";
import desexedIcon from "../../assets/icons/desexed.svg";
import storeIcon from "../../assets/icons/store.svg";
import calculatorIcon from "../../assets/icons/calculator.svg";
import faqIcon from "../../assets/icons/faq.svg";
import dogAndCat from "../../assets/icons/dog-and-cat.svg";
import trashIcon from "../../assets/icons/trash.svg";
import editIcon from "../../assets/icons/edit.svg";
import homeIcon from "../../assets/icons/home-light.svg";
import shopIcon from "../../assets/icons/shop-light.svg";

import { useEffect, useState, useRef } from "react";
import { Row, Modal } from "../../components/layout";
import history from "../../utils/history";

type HeaderProps = {
  step: number;
  stepTotal: number;
};

const YPPA_URL =
  window.location.host != "account.thrivingpets.co.nz"
    ? "https://yourpetpa.com.au/"
    : "https://yourpetpa.co.nz";

const THRIVING_URL =
  window.location.host != "account.thrivingpets.co.nz"
    ? "https://thrivingpets.com.au"
    : "https://thrivingpets.co.nz";
const PlanAndPaymentAggreements: React.VFC<any> = () => {
  return (
    <div className="onboarding page">
      <h4>Plan & Payment Aggreements</h4>
      <p>
        Thriving Pets is an annual preventative wellness plans for pets provided
        to you by My Pet PA Pty Ltd ACN 649 066 473 trading as Your Pet PA
        (‘YPP’).
      </p>
      <p>
        The plans allow members access to pet related products and veterinary
        services on an annual basis subject to these terms and conditions. The
        plans are designed to be preventative care and wellness support to help
        keep pets healthy, it is not an insurance program or a substitute for
        pet insurance. Not all veterinary services are covered by the plan, and
        we recommend that you confirm and understand the services that are part
        of the plan. By joining a Thriving Pets Wellness Plan, members agree to
        be bound by these terms and conditions as between Thriving Pets and YPP,
        the member and any participating veterinary clinic, and any additional
        terms and conditions notified to the member by any participating
        veterinary clinic. Members can contact the Thriving Pets support team
        (info@thrivingpets.com.au) or seek your own independent advice about
        these terms and conditions.
      </p>
    </div>
  );
};
const TermsConditions: React.VFC<any> = () => {
  return (
    <div className="onboarding page">
      <h4>Terms & Conditions</h4>
      <p>
        Thriving Pets is an annual preventative wellness plans for pets provided
        to you by My Pet PA Pty Ltd ACN 649 066 473 trading as Your Pet PA
        (‘YPP’).
      </p>
      <p>
        The plans allow members access to pet related products and veterinary
        services on an annual basis subject to these terms and conditions. The
        plans are designed to be preventative care and wellness support to help
        keep pets healthy, it is not an insurance program or a substitute for
        pet insurance. Not all veterinary services are covered by the plan, and
        we recommend that you confirm and understand the services that are part
        of the plan. By joining a Thriving Pets Wellness Plan, members agree to
        be bound by these terms and conditions as between Thriving Pets and YPP,
        the member and any participating veterinary clinic, and any additional
        terms and conditions notified to the member by any participating
        veterinary clinic. Members can contact the Thriving Pets support team
        (info@thrivingpets.com.au) or seek your own independent advice about
        these terms and conditions.
      </p>
      <h5>Membership</h5>
      <ol>
        <li>
          Members must sign up to a Thriving Pets Wellness Plan for a 12-month
          period.
        </li>
        <li>
          A Thriving Pets Wellness Plan paid for monthly will automatically
          renew for further consecutive 12-month periods unless membership is
          terminated by the member or Thriving Pets and YPP in accordance with
          these terms.
        </li>
        <li>
          The Thriving Pets team will contact you at least 30 days before the
          end of the then current 12-month period with any adjustments in the
          monthly fees. We will make reasonable efforts to contact members via
          their registered email address.
        </li>
        <li>
          Unless the member notifies Thriving Pets and YPP at least 14 days
          before the end of any 12-month period of membership that the member
          does not wish to renew, their membership will automatically renew for
          another 12 months as set out in 2 above.
        </li>
        <li>
          Members are solely responsible for checking the terms of their
          membership, including renewal, and must ensure that they update
          Thriving Pets and YPP of any changes to their contact details,
          including their email address and phone number. Thriving Pets and YPP
          will not be liable for any delay or disruption in communication due to
          incorrect or outdated member contact details.
        </li>
        <li>
          Members can only register their own dog or cat; the plans are not
          available to other species of pets
        </li>
        <li>
          An annual membership is limited to the pet nominated by the member. A
          separate membership must be purchased for each additional pet owned by
          a member to be part of the plan.
        </li>
        <li>
          Details of inclusions in the plans will be always available on the
          Thriving Pets website.
        </li>
      </ol>
      <h5>Membership Fees</h5>
      <ol>
        <li>
          The membership fees, instalment details and payment options are as
          specified from time to time on the Thriving Pets website
          (www.thrivingpets.com.au).
        </li>
        <li>
          Renewed memberships must be paid on the new instalment amount as
          communicated to members at least 30 days before the renewal date.
          Members may also choose to pay the full annual membership fee upfront.
          Payment must be made by direct debit authority from the member’s
          nominated debit or credit card. Thriving Pets and YPP may engage a
          third party provider to facilitate the payment of membership fees, and
          members will be responsible for compliance with the terms of any such
          third party payment services provider. Any dishonoured direct debit
          will incur a dishonour fee which is payable at the next direct debit
          drawing, which the member acknowledges and agrees is a reasonable
          initial pre-estimate of the costs to be incurred by Thriving Pets and
          YPP or its agents in connection with that default.
        </li>
        <li>
          If a member fails to pay an instalment of their membership fee
          Thriving Pets and YPP may, directly or through a third-party provider,
          recover (at the member’s cost) any outstanding membership fee,
          including any administrative, service, or legal fees, and applicable
          interest associated with the outstanding membership fee or the
          recovery process.
        </li>
        <li>
          The annual membership fee will be reviewed each year on the
          anniversary of the date the member joined the plan. Members will be
          advised of any change in the membership fee, which will apply from the
          next anniversary of their membership unless the member notifies
          Thriving Pets and YPP not to renew their membership at least 14 days
          before the end of the then current membership period.
        </li>
      </ol>
      <h5>Cancellations</h5>
      <ol>
        <li>
          Memberships are for a 12-month period. Members may choose not to renew
          their membership by sending an email to Thriving Pets and YPP up to 14
          days before expiry of the current membership period.
        </li>
        <li>
          Members wishing to cancel within their 12-month period should contact
          Thriving Pets and YPP to discuss cancellation. Members that cancel
          within their 12-month membership period may be required to pay, the
          difference between the savings obtained on the services provided by
          Thriving Pets and YPP or their participating veterinary clinic through
          their membership in the plan and the total amount of membership fees
          paid to date.
        </li>
        <li>
          Any reconciliation payment must be paid by direct debit prior to the
          cancellation becoming effective. If the reconciliation payment is not
          received, cancellation will not occur and the member will continue to
          incur liability for regularly scheduled payments.
        </li>
        <li>
          Members may cancel their membership at any time without penalty if
          their pet has passed away.
        </li>
      </ol>
      <h5>Membership Benefits</h5>
      <p>
        Subject to the specific benefits and services offered at a member’s
        participating veterinary clinic, members may have the opportunity to
        enjoy the following benefits and services provided by Thriving Pets and
        YPP or at a participating veterinary clinic in each 12-month membership
        period:
      </p>
      <ul>
        <li>
          Unlimited free access to online telehealth vet chats and advice with a
          Your PetPA registered veterinarian (not available through veterinary
          clinics); and
        </li>
        <li>
          All your flea, tick, intestinal worm, and heart worm prevention
          delivered direct to your home address at the end of each relevant
          month dependant on your local vet recommendation. This is not
          available for collection via your veterinary clinic.
        </li>
        <li>
          Unlimited in-clinic consultations (reimbursed up to $75 per
          consultation), provided that an appointment has been made with the
          veterinary clinic during normal clinic hours. Consultations performed
          outside of normal clinic hours, including weekends and/or public
          holidays, veterinary specialists or behaviour consultations aren’t
          included in the plan. Consultations must be performed by a
          Veterinarian.
        </li>
        <li>
          One standard annual vaccination as recommended by your veterinarian
          (reimbursement up to a cost of $100);
        </li>
        <li>
          $100 Wellness Screen Allowance (blood test) annually (reimbursed);
        </li>
        <li>$100 Dental Allowance annually* (reimbursed);</li>
        <li>$100 Desexing Allowance annually* (reimbursed);</li>
        <li>
          10% off all purchases made at
          <a
            href="http://yourpetpa.com.au"
            target="_blank"
            rel="noreferrer noopener"
          >
            www.yourpetpa.com.au
          </a>
          Excluding pharmacy medications.
        </li>
      </ul>
      <p>
        * $100 Dental Allowance is for pets 12 months of age or older and the
        $100 Desexing Allowance is for a Puppy or Kitten that is less than 12
        months old and not desexed.
      </p>
      <p>
        All membership benefits and services are not guaranteed to be available
        at a participating veterinary clinic and will be subject to the
        professional recommendation of the treating veterinarian at the clinic
        and availability. Membership benefits are subject to change and
        appropriate substitutions by Thriving Pets and YPP and/or the
        participating veterinary clinic’s reasonable discretion. Membership
        benefits and discounts are not to be used in conjunction with any other
        offer and cannot be exchanged or redeemed for cash or other
        consideration.
      </p>
      <p>
        Membership benefits may be redeemed within the membership period only
        and expire at the end of each 12-month membership period or on
        termination for any reason and do not accumulate. Any additional goods,
        services, consultations and/or treatments not covered by the plans
        (including any additional consultation hours, diagnostic work and
        medications) will be payable at the participating veterinary clinic’s
        standard current fee schedule.
      </p>
      <h5>Fair use and member conduct</h5>
      <p>
        Members accept that membership benefits are subject to reasonable usage,
        as determined by Thriving Pets and YPP at its sole discretion.
        Participating veterinary clinics may exclude membership entitlements to
        members on the basis of unreasonable or excessive use, by notifying the
        member of the unreasonable use in writing and if such usage continues,
        Thriving Pets and YPP may terminate the membership.
      </p>
      <p>
        Members must always comply with these terms during any membership
        period. In addition to any other rights under these terms, if a member
        breaches these terms then Thriving Pets and YPP may notify the member of
        such breach in writing. If the breach is not remedied in the timeframe
        required by Thriving Pets and YPP (acting reasonably), then Thriving
        Pets and YPP may terminate the membership.
      </p>
      <h5>Termination</h5>
      <p>
        Members must always comply with these terms during any membership
        period. In addition to any other rights under these terms, if a member
        breaches these terms then Thriving Pets and YPP may notify the member of
        such breach in writing. If the breach is not remedied in the timeframe
        required by Thriving Pets and YPP (acting reasonably), then Thriving
        Pets and YPP may terminate the membership.
      </p>
      <h5>Liability</h5>
      <p>
        To the maximum extent permitted by law, Thriving Pets and YPP will not
        be liable for any loss, cost, damage, expense, or claim arising from any
        failure, default, or delay in the supply of services or the membership
        benefits to members if, and during the period in which, such failure,
        default, or delay is caused by events or circumstances beyond Thriving
        Pets and YPP’s reasonable control. If such circumstances occur, Thriving
        Pets and YPP may elect to suspend all or part of the supply of services
        or benefits to members or terminate the Thriving Pets membership plan on
        notice to members.
      </p>
      <p>
        Thriving Pets and YPP does not warrant that the Thriving Pets Wellness
        Plans or any of the membership benefits will be available to members at
        any particular time or on an ongoing basis. Subject to compliance with
        applicable law, YPP may otherwise elect to discontinue the Thriving Pets
        Wellness Plan at any time on notice to members. Subject to the
        non-excludable provisions of the Australian Consumer Law (Schedule 2 of
        the Competition and Consumer Act 2010) and any other non-excludable term
        implied by law, the benefits and services are provided on an “as-is”
        basis without warranties of any kind (including the availability of any
        of the services provided), either express or implied, and Thriving Pets
        and YPP excludes all warranties, representations and liability of any
        kind (including in negligence) in connection with the benefits and the
        services.
      </p>
      <p>
        To the extent that Thriving Pets and YPP’s liability cannot be excluded
        in accordance with the foregoing, Thriving Pets and YPP limits that
        liability, to the maximum extent permitted by law, to (at its
        discretion):
      </p>
      <ul>
        <li>
          refund of membership fees paid in connection with affected membership
          benefits
        </li>
        <li>re-supply of the services or membership benefits;</li>
        <li>
          payment of the cost of having the services or membership resupplied;
          or
        </li>
        <li>
          payment in the amount of $10 or such greater amount that is the
          minimum permitted by law.
        </li>
      </ul>
      <p>
        Thriving Pets and YPP provides the plans via participating veterinary
        clinics and any right, benefit, exclusion or limitation of liability in
        these terms for Thriving Pets and YPP also applies for the benefit of
        each of the participating veterinary clinics to the extent permitted by
        applicable law.
      </p>
      <h5>General</h5>
      <p>
        These terms are governed by the laws in force in New South Wales,
        Australia. You irrevocably and unconditionally submit to the
        non-exclusive jurisdiction of the courts of New South Wales, Australia
        and any courts which may hear appeals from those courts. Invalidity of
        any provisions in these terms does not affect any other provisions. Any
        delay or failure by Thriving Pets and YPP to enforce any rights under
        these terms does not constitute waiver. Thriving Pets and YPP collects,
        stores, uses, and discloses personal information we receive from you or
        third parties in connection with the Thriving Pets membership plans in
        accordance with YPP’s Privacy Policy . YPP may from time to time make
        changes to these terms and will use reasonable endeavours to bring those
        changes to your attention. Your continued use of the membership benefits
        means after a change to the terms has been notified to members means
        that you have accepted any changes that have been made (subject to your
        cancellation right above). You are responsible for ensuring that the
        contact details we have for you are correct and up to date.
      </p>
    </div>
  );
};
const Inclusions: React.VFC<any> = () => {
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  return (
    <div className="onboarding inclusions">
      <div className="content-box">
        <h2 className="title-1">
          <div className="flex align-center">
            <img src={thrivingPetsLogoDarkReverse} />
          </div>
          <span>Inclusions & Pricing for Thriving Pets Wellness Plans.</span>
        </h2>
        <div className="row column two mt-40">
          <div className="box card primary">
            <h3 className="header">Wellness Plan Inclusions</h3>
            <div className="card-content">
              <div className="icon-content left-align">
                <img className="icon" src={checkIcon} />
                <p className="icon-text">
                  Flea, Tick & Worming Home Delivered
                  <span className="subtext">
                    (Sent automatically to you at the end of each relevant
                    month)
                  </span>
                </p>
              </div>
              <div className="icon-content left-align">
                <img className="icon" src={checkIcon} />
                <p className="icon-text">
                  Unlimited Consultations at any Vet Clinic
                  <span className="subtext">
                    (Claim up to $75 per consultation)
                  </span>
                </p>
              </div>
              <div className="icon-content left-align">
                <img className="icon" src={checkIcon} />
                <p className="icon-text">
                  Annual Vaccination
                  <span className="subtext">(Claim up to $100)</span>
                </p>
              </div>
              <div className="icon-content left-align">
                <img className="icon" src={checkIcon} />
                <p className="icon-text">
                  $100 Wellness Screen Allowance
                  <span className="subtext">
                    (Wellness blood test as recommended by your Vet)
                  </span>
                </p>
              </div>
              <div className="icon-content left-align or-option">
                <img className="icon" src={checkIcon} />
                <p className="icon-text">
                  $100 Dental Allowance
                  <span className="subtext">
                    (From 12 months of age onwards)
                  </span>
                </p>
              </div>
              <div className="icon-content left-align">
                <img className="icon" src={checkIcon} />
                <p className="icon-text">
                  $100 Desexing Allowance
                  <span className="subtext">(Up to 12 months of age)</span>
                </p>
              </div>
              <div className="icon-content left-align">
                <img className="icon" src={checkIcon} />
                <p className="icon-text">
                  10% Online Pet Store Discount
                  <span className="subtext">
                    (Visit yourpetpa.com.au. Terms and Conditions apply).
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="box card secondary">
            <h3 className="header">Membership Costs</h3>
            <div className="card-content">
              <div className="membership-tab-pricing pricing">
                <ul className="tab">
                  <li>
                    <span className="label">Yearly</span>
                    <span className="costing">
                      <span className="price">
                        <span className="currency">$</span>
                        590
                      </span>
                      <span className="cycle">per year</span>
                      <span className="saver">SAVE 10%</span>
                    </span>
                  </li>
                  <li>
                    <span className="label">Monthly</span>
                    <span className="costing">
                      <span className="price">
                        <span className="currency">$</span>
                        45.95
                      </span>
                      <span className="cycle">per year</span>
                      <span>
                        (1st upfront payment of {""}
                        <strong>$149.95</strong>)
                      </span>
                    </span>
                  </li>
                </ul>
                <div>
                  <ul className="list check">
                    <li>
                      <img className="icon" src={checkIcon} />
                      Plan Paid Monthly or Yearly
                    </li>
                    <li>
                      <img className="icon" src={checkIcon} />
                      Easily Manage Payment Schedules
                    </li>
                    <li>
                      <img className="icon" src={checkIcon} />
                      Automated Stree-free Pet Wellness Plan
                    </li>
                    <li>
                      <img className="icon" src={checkIcon} />
                      Access to Experts 7 Days a Week
                    </li>
                    <li>
                      <img className="icon" src={checkIcon} />
                      Auto-Delivery of Parasite Prevention
                    </li>
                  </ul>
                </div>
              </div>
              <p className="flex">
                <a
                  href={`${THRIVING_URL}/thriving-pets/`}
                  className="btn bordered-purple btn-white btn-small-font"
                >
                  <img src={calculatorIcon} />
                  Savings Calculator
                </a>
                <a
                  href={`${THRIVING_URL}/thriving-pets/`}
                  className="btn btn-white bordered-purple btn-small-font"
                >
                  <img src={faqIcon} />
                  View our FAQ
                </a>
              </p>
              <p>
                <br />
                Please review the &nbsp;
                <a onClick={() => setShowTermsConditions(true)}>
                  terms and conditions.
                </a>{" "}
                Membership is on annual basis commencing from the date of
                subscription. A renewal notice will be sent at least one month
                prior to the expiry of the annual membership. Membership
                automatically renews for a further twelve-month period unless
                the member notifies Thriving Pets (YourPetPA) otherwise.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showTermsConditions}
        closeModal={() => setShowTermsConditions(false)}
      >
        <TermsConditions />
      </Modal>
    </div>
  );
};
const OnboardingHeader: React.VFC<HeaderProps> = ({ step, stepTotal }) => {
  const [showInclusions, setShowInclusions] = useState(false);
  const steps = Array.from(Array(Number(stepTotal + 1)).keys());
  return (
    <>
      <div className="steps align-center">
        <h2
          className="flex mb-0 align-center"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/dashboard")}
        >
          Join
          <img src={thrivingPetsLogoLight} />
        </h2>
        <h5>
          <a
            style={{ textDecoration: "underline" }}
            onClick={() => setShowInclusions(true)}
          >
            Inclusions & Pricing
          </a>
          <Modal
            isOpen={showInclusions}
            closeModal={() => setShowInclusions(false)}
          >
            <Inclusions />
          </Modal>
        </h5>
        <h3>
          Step {step} of {stepTotal}
        </h3>
      </div>
      <div className="progress-bar">
        {steps &&
          steps.map((v) => {
            const className = v + 1 == step ? "progress active" : "progress";
            return <span className={className}></span>;
          })}
      </div>
    </>
  );
};
const OnboardingMenus: React.VFC<{ active: string }> = ({ active }) => {
  const menus = [
    { k: "", l: "Onboarding", class: "" },
    { k: "account-setup", l: "Account Setup", class: "" },
    { k: "pet-parent-info", l: "Pet Parent Info", class: "" },
    { k: "pet-info", l: "Pet Info" },
    { k: "payment-completion", l: "Payment & Complete", class: "" },
  ];
  menus.map((v) => {
    v.class = v.k == active ? "active" : "";
    return v;
  });
  return (
    <ul className="steps-menus">
      {menus &&
        menus.map((m) => {
          return (
            <>
              <li className={m.class}>
                <a
                  onClick={() => {
                    history.push(`/onboarding/${m.k}`);
                  }}
                >
                  {m.l}
                </a>
              </li>
            </>
          );
        })}
    </ul>
  );
};
export {
  OnboardingHeader,
  OnboardingMenus,
  Inclusions,
  TermsConditions,
  PlanAndPaymentAggreements,
  truckIcon,
  thrivingPetsLogo,
  thrivingPetsLogoLight,
  chatIcon,
  arrowLeftIcon,
  arrowRightIcon,
  hospitalIcon,
  injectionIcon,
  stethoscopeIcon,
  toothbrushIcon,
  desexedIcon,
  storeIcon,
  calculatorIcon,
  faqIcon,
  dogAndCat,
  trashIcon,
  editIcon,
  checkIcon,
  homeIcon,
  shopIcon,
  YPPA_URL,
  THRIVING_URL,
};
