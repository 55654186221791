type RadioButtonProps = {
  /**
   * The input group name for the radio inputs (to show a collection of inputs are related).
   */
  groupName?: string;
  /**
   * The value associated with this input. Is compared to 'value' to determine if it should be marked as checked.
   */
  id: string;
  /**
   * The text to be displayed next to the radio input.
   */
  label: string;
  /**
   * The currently selected option. Is compared with 'id' to determine if it should it should be marked as checked.
   */
  value: string;
  /**
   * The function to be invoked when the input is clicked. Passes the 'id' as the input to the function.
   */
  onChange: (e: string) => void;
  /**
   *
   */
  disabled?: boolean;
};

/**
 * A single html radio button. Used as a sub-component of HTMLRadioInput.
 */
const RadioButton: React.FC<RadioButtonProps> = ({
  groupName,
  id,
  label,
  value,
  onChange,
  disabled = false,
}: RadioButtonProps) => {
  return (
    <div onClick={() => onChange(id)}>
      <input
        type="radio"
        name={groupName}
        value={value}
        checked={value === id}
        disabled={disabled}
      />
      <label>{label}</label>
    </div>
  );
};

export default RadioButton;
