import "./Onboarding.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { UserUpdateData } from "../../types/user";
import { useFormik } from "formik";
import { Row, Modal } from "../../components/layout";
import { isNotEmpty, isMobile } from "../../utils/formik-validate";
import { stripCountry } from "../../utils/mobile-number";
import { PhoneInput } from "../../components/input";
import AddressInput from "../../components/input/AddressInput/AddressInput";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import {
  OnboardingHeader,
  OnboardingMenus,
  arrowLeftIcon,
  arrowRightIcon,
} from "./Module";

const PetParentInfo: React.FC = () => {
  const location = useLocation();
  const { fetchUserData, updateUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [countryCode, setCountryCode] = useState("+61");
  const [userAddress, setUserAddress] = useState({
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    province: "",
    postcode: "",
  });
  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/onboarding/");
    } else {
      fetchUserData();
    }
    if (user && isAuthenticated) {
      //const address = user?.address;
      const initialAddress = Object.assign(userAddress, user.address);
      initialAddress.phone = user.phone.includes("+")
        ? stripCountry(user.phone)
        : user.phone;
      setUserAddress(initialAddress);
    }
  }, [isAuthenticated, location]);
  const DeliveryInformation = useFormik({
    initialValues: userAddress,
    onSubmit: async (values) => {
      console.log({ values, user });
      const { address1, address2, city, state, country, province, postcode } =
        values;
      const imageUrl = user ? user.imageUrl : "",
        interests = user ? user.interests : [],
        email = user ? user.email : "",
        firstName = user ? user.firstName : "",
        lastName = user ? user.lastName : "",
        phone = `(${countryCode})${
          values.phone.includes("+") ? stripCountry(values.phone) : values.phone
        }`;
      const data: UserUpdateData = {
        phone,
        email,
        firstName,
        lastName,
        address: {
          address1,
          address2,
          city,
          state,
          country,
          province,
          postcode,
        },
        interests,
        imageUrl,
      };
      console.log({ data });
      await updateUserData(data);
      history.push("/onboarding/pet-info");
      //const signUpData = {};
    },
    validate: async ({
      address1,
      address2,
      city,
      state,
      country,
      postcode,
      phone,
    }) => {
      const errors = {
        ...isNotEmpty(address1, "address1"),
        ...isNotEmpty(city, "city"),
        ...isNotEmpty(state, "state"),
        ...isNotEmpty(country, "country"),
        ...isNotEmpty(postcode, "postcode"),
        ...isMobile(`(${countryCode})${phone}`, "phone"),
      };
      //console.log(errors);
      return errors;
    },
  });
  const PetParentInformation = () => {
    return (
      <div className="content">
        <OnboardingHeader step={3} stepTotal={5} />
        <div className="content-box">
          <OnboardingMenus active="pet-parent-info" />
          <h2 className="title-2">
            Let us know your contact & delivery information
          </h2>
          <Row>
            <PhoneInput
              label="Phone"
              value={DeliveryInformation.values.phone}
              onChange={DeliveryInformation.handleChange("phone")}
              touched={DeliveryInformation.touched.phone}
              error={DeliveryInformation.errors.phone}
              country={DeliveryInformation.values.country}
              onRegionSelect={(code: string) => setCountryCode(code)}
              required
            />
          </Row>
          <Row>
            <AddressInput form={DeliveryInformation} />
          </Row>
          <div className="row">
            <p>
              At the end of each relevant month, the parasite prevention
              included in your Thriving Pets Wellness Plan will be sent to the
              address your register here. You can expect this to arrive around
              the 30th of each month.
            </p>
          </div>
        </div>
        <p className="bottom-action text-center">
          <img
            src={arrowLeftIcon}
            onClick={() => history.push("/onboarding/")}
          />
          <button
            className="btn btn-purple square"
            onClick={() => {
              DeliveryInformation.handleSubmit();
            }}
          >
            Next
          </button>
          <img
            src={arrowRightIcon}
            onClick={() => history.push("/onboarding/pet-info")}
          />
        </p>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="onboarding">
      <div className="banner"></div>
      {PetParentInformation()}
    </div>
  );
};

export default PetParentInfo;
