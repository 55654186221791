import "./Cart.scss";
import React, { useEffect, useState } from "react";
import LineItem from "../LineItem/LineItem";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/Cart";
import { bindActionCreators } from "redux";
import {
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
  addDiscount,
} from "../../../api/queries/checkout";
import { checkoutQuery } from "../../../api/queries/checkoutQuery";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Row } from "../../layout";
import { uploadPrescription } from "../../../api/upload";
import { FileInput } from "../../input";
import { Button } from "../../common";

let cartInterval: NodeJS.Timeout;
let checkoutWindow: Window | null;

export const clearCheckout = async () => {
  await clearInterval(cartInterval);
  await checkoutWindow?.close();
  return;
};

const Cart: React.VFC<{
  isCartOpen: boolean;
  handleCartClose: () => void;
}> = () => {
  const { updateCart, updateCartOpen, clearCart } = bindActionCreators(
    actionCreators,
    useDispatch()
  );

  const history = useHistory();

  const cart = useSelector((state: ApplicationState) => state.cart?.cart);
  const prescribeProducts = useSelector(
    (state: ApplicationState) => state.cart?.prescribeProducts
  );
  const open = useSelector((state: ApplicationState) => state.cart?.open);
  const user = useSelector((state: ApplicationState) => state.user);
  const [addDiscountMutation] = useMutation(addDiscount);

  const [medicationFile, setMedicationFile] = useState<null | File>(null);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState<boolean>(false);
  const {
    loading: cartLoading,
    data: cartData,
    refetch: cartRefetch,
  } = useQuery(checkoutQuery, {
    fetchPolicy: "no-cache",
    variables: { id: cart?.id },
  });

  /**
   * Applies a coupon to the current cart.
   */
  const applyDiscount = async () => {
    const variables = {
      discountCode: process.env.REACT_APP_SHOPIFY_SUBSCRIBER_COUPON,
      checkoutId: cart?.id,
    };
    await addDiscountMutation({ variables });
  };

  /**
   * Determines if the user is allowed to use the Thriving Plus coupon.
   */
  const checkAllowedDiscount = () => {
    if (user) {
      return user.pets.filter((pet) => pet.plan.includes("ThrivePlus")).length >
        0
        ? true
        : false;
    } else {
      return false;
    }
  };

  /**
   * I think this handles the completion check for when the user is
   * redirected to Shopify to complete their checkout process.
   */
  const checkPopup = (pu: Window | null) => {
    setTimeout(async () => {
      if (checkAllowedDiscount() && !cart?.coupon) {
        await applyDiscount();
      }
      checkoutWindow = pu;
      //console.log(checkoutWindow);
      cartInterval = setInterval(() => {
        cartRefetch({ id: cart?.id }).then((result) => {
          //console.log(result);
          const couponCode =
            result?.data?.node?.discountApplications.edges[0]?.node?.code;
          const coupon = !couponCode
            ? {}
            : {
                coupon: {
                  code: result?.data?.node?.discountApplications.edges[0]?.node
                    ?.code,
                  percentDiscount:
                    result?.data?.node?.discountApplications.edges[0]?.node
                      ?.value?.percentage,
                },
              };
          updateCart({
            id: result?.data?.node?.id,
            ready: result?.data?.node?.ready,
            currencyCode: result?.data?.node?.currencyCode,
            subtotalPrice: result?.data?.node?.lineItemsSubtotalPrice?.amount,
            taxesIncluded: result?.data?.node?.taxesIncluded,
            totalTax: result?.data?.node?.totalTax,
            totalPrice: result?.data?.node?.totalPrice,
            webUrl: result?.data?.node?.webUrl,
            completedAt: result?.data?.node?.completedAt,
            lineItems: result?.data?.node?.lineItems.edges.map(
              (edge: any) => edge.node
            ),
            ...coupon,
          });
          if (
            result?.data.node?.lineItems?.edges?.length === 0 ||
            cart?.lineItems?.length === 0
          ) {
            updateCartOpen(false);
            clearCart();
            pu?.close();
            // Shopping cart was emptied after going to checkout
            clearInterval(cartInterval);
            return;
          } else if (
            result?.data?.node?.completedAt != null ||
            cart?.completedAt != null
          ) {
            updateCartOpen(false);
            clearCart();
            pu?.close();
            history.push(`/shop/complete/${result?.data?.node?.id}`);
            clearInterval(cartInterval);
            return;
          } else if (pu?.closed) {
            clearInterval(cartInterval);
            return;
          }
        });
      }, 1000);
    });
  };

  /**
   * Update state when fetched data is updated.
   */
  useEffect(() => {
    (async () => {
      if (cartData?.node !== null) {
        if (checkAllowedDiscount() && !cart?.coupon) {
          await applyDiscount();
        }
        const couponCode =
          cartData?.node?.discountApplications.edges[0]?.node?.code;
        const coupon = !couponCode
          ? {}
          : {
              coupon: {
                code: cartData?.node?.discountApplications.edges[0]?.node?.code,
                percentDiscount:
                  cartData?.node?.discountApplications.edges[0]?.node?.value
                    ?.percentage,
              },
            };
        updateCart({
          id: cartData?.node?.id,
          ready: cartData?.node?.ready,
          currencyCode: cartData?.node?.currencyCode,
          subtotalPrice: cartData?.node?.lineItemsSubtotalPrice?.amount,
          taxesIncluded: cartData?.node?.taxesIncluded,
          totalTax: cartData?.node?.totalTax,
          totalPrice: cartData?.node?.totalPrice,
          webUrl: cartData?.node?.webUrl,
          completedAt: cartData?.node?.completedAt,
          lineItems: cartData?.node?.lineItems.edges.map(
            (edge: any) => edge.node
          ),
          ...coupon,
        });
        cartRefetch();
      }
    })();
  }, [cartData, cartLoading, open]);

  /*
   * Check if prescription required product existed here
   */
  const prescribeProductsExists = () => {
    const lineItemsIds = cart?.lineItems?.map((line_item: any) => {
      return line_item.variant?.id;
    });
    const isExists = prescribeProducts?.filter((v) => {
      return lineItemsIds?.indexOf(v) !== -1;
    });
    if (isExists && isExists?.length < 1) return false;
    return true;
  };

  /**
   * Opens the checkout in a new window.
   */
  const openCheckout = () => {
    (async () => {
      if (prescribeProductsExists()) {
        if (!medicationFile)
          return alert("Please upload medication prescription");
        setIsCheckoutLoading(true);
        const email = user?.email ? user?.email : "";
        uploadPrescription(medicationFile, email);
        /*const r = await uploadPrescription(medicationFile, email);
        console.log(r);
        if (!r?.data)
          return alert("Uploading Medication Failed! Please try again.");*/
      }
      //return console.log(checkAllowedDiscount());
      if (checkAllowedDiscount()) {
        await cartRefetch();
      }
      const popup = window.open(
        cart?.webUrl?.replace(
          "https://your-pet-pa.myshopify.com",
          "https://shop.yourpetpa.com.au"
        )
      );
      setIsCheckoutLoading(false);
      checkPopup(popup);
    })();
  };

  const [lineItemUpdateMutation, { loading: lineItemUpdateLoading }] =
    useMutation(checkoutLineItemsUpdate);

  const [lineItemRemoveMutation, { loading: lineItemRemoveLoading }] =
    useMutation(checkoutLineItemsRemove);

  const removeLineItemInCart = async (lineItemId: any) => {
    const variables = { checkoutId: cart?.id, lineItemIds: [lineItemId] };
    await lineItemRemoveMutation({ variables });
    await cartRefetch({ id: cart?.id });
  };

  const updateLineItemInCart = async (lineItemId: any, quantity: string) => {
    const variables = {
      checkoutId: cart?.id,
      lineItems: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
    };
    await lineItemUpdateMutation({ variables });
    await cartRefetch({ id: cart?.id });
  };

  const lineItems = cart?.lineItems?.map((line_item: any) => {
    //console.log("line-item----", line_item?.variant?.id);
    //await prescribeProductsAction(false, true, line_item?.variant?.id);
    return (
      <LineItem
        removeLineItemInCart={removeLineItemInCart}
        updateLineItemInCart={updateLineItemInCart}
        loading={lineItemUpdateLoading || lineItemRemoveLoading}
        key={line_item.id.toString()}
        line_item={line_item}
      />
    );
  });

  return (
    <div className={`cart ${open ? "cart--open" : ""}`}>
      <header className="cart-header">
        <h2>Your cart</h2>
      </header>
      <ul className="cart-line-items">{lineItems}</ul>
      <footer className="cart-footer">
        {prescribeProductsExists() && (
          <FileInput
            label="You need to upload prescription medication"
            onChange={(event) => {
              const image = event?.currentTarget?.files
                ? event?.currentTarget?.files[0]
                : null;
              if (!image) return;
              setMedicationFile(image);
              console.log(image);
              //console.log(URL.createObjectURL(event.currentTarget.files[0]));
            }}
          />
        )}
        <div className="cart-info clearfix">
          <div className="cart-info-total cart-info-small">Subtotal</div>
          <div className="cart-info-pricing">
            <span className="pricing">
              $ {Number(cart?.subtotalPrice).toFixed(2)}
            </span>
          </div>
        </div>
        {cart?.coupon && (
          <div className="cart-info clearfix">
            <div className="cart-info-total cart-info-small">
              Thriving Pets Plus (-10%)
            </div>
            <div className="cart-info-pricing">
              <span className="pricing">
                -$
                {(Number(cart.subtotalPrice) - Number(cart.totalPrice)).toFixed(
                  2
                )}
              </span>
            </div>
          </div>
        )}
        <div className="cart-info clearfix">
          <div className="cart-info-total cart-info-small">Total</div>
          <div className="cart-info-pricing">
            <span className="pricing">
              $ {Number(cart?.totalPrice).toFixed(2)}
            </span>
          </div>
        </div>
        <Button
          isLoading={isCheckoutLoading}
          onClick={openCheckout}
          label="Proceed To Checkout"
          fullwidth
          rounded
        />
      </footer>
    </div>
  );
};

export default Cart;
