import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { generateCoupon, generatePuppyCoupon } from "../../api/cogsworth";
import useCountryCode from "../../hooks/useCountryCode";
import { bindActionCreators } from "redux";
import { useFormik } from "formik";
import { Modal } from "../../components/layout";
import { Button } from "../../components/common";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import { thrivingPetsLogoDarkReverse, headsetIcon } from "./Module";

interface FormTypes {
  vet: string;
  puppyPreschool: string;
  behaviourConsult: string;
  free: string;
}
interface BookAVetType {
  closeModal: () => void;
}
const cogsworth: { [key: string]: FormTypes } = {
  forms: {
    vet: "https://www.cogsworth.com/book/ckqblndj6005j0nud66hx6rm6",
    puppyPreschool: "https://www.cogsworth.com/book/ckqhbpcxu004w0nzyhsgvgjsi",
    behaviourConsult:
      "https://www.cogsworth.com/book/ckqhbe8h3006n0nx54jgzc2tx",
    free: "https://www.cogsworth.com/book/ckr453ta1009c0ntc2pfq1v0c",
  },
  forms_nz: {
    vet: "https://www.cogsworth.com/book/ckv0blj7j003k0tksctqr1m6v",
    puppyPreschool: "https://www.cogsworth.com/book/ckvgjyw3h02750skwa4vc3jhx",
    behaviourConsult:
      "https://www.cogsworth.com/book/ckvhhzws8039k0umb9y2l403k",
    free: "https://www.cogsworth.com/book/ckvism1r5047a0skw6sv4dqi4",
  },
};

const BookAVet: React.FC<BookAVetType> = ({ closeModal }) => {
  const location = useLocation();
  const { fetchUserData, updateUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [selectedOptions, setSelectedOptions] = useState<{
    pet: string;
    chat: "vet" | "behaviourConsult" | "puppyPreschool";
  }>({ pet: "", chat: "vet" });
  const [booked, setBooked] = useState({ show: false, url: "" });
  useEffect(() => {
    if (!isAuthenticated) history.push("/");
    else fetchUserData();
  }, [isAuthenticated, location]);

  const countryCode = useCountryCode();
  const generateUrl = async (pet: any, formType: keyof FormTypes) => {
    if (!user || !countryCode) return;
    const { firstName, lastName, email, address } = user;
    const form = address.country == "Australia" ? "forms" : "forms_nz";
    const name = `${firstName} ${lastName}`;
    let chatUrl = "";
    chatUrl += cogsworth[form][formType];
    if (name && email)
      chatUrl += `?answer[name]=${name}&answer[email]=${email}&reset=true`;
    if (pet.plan !== "NoPlan") {
      if (formType === "vet") {
        const coupon = await generateCoupon(countryCode);
        chatUrl += coupon ? `&option[coupon]=${coupon}` : "";
      } else if (formType === "puppyPreschool") {
        const coupon = await generatePuppyCoupon(countryCode);
        chatUrl += coupon ? `&option[coupon]=${coupon}` : "";
      }
    }
    return chatUrl;
  };
  const getPreschoolEligibility = (pet: any) => {
    if (!pet) return false;
    const YEAR_IN_MS = 1000 * 60 * 60 * 24 * 365;
    const isDog = pet?.type === "dog";
    const isLessThanOne =
      new Date().getTime() - new Date(pet?.birthday).getTime() < YEAR_IN_MS;
    if (isDog && isLessThanOne) return true;
    return false;
  };
  const BookChat = useFormik({
    initialValues: selectedOptions,
    onSubmit: async (values) => {
      const { pet: petId, chat } = selectedOptions;
      const pet =
        user &&
        user.pets.find((p) => {
          return p._id == petId;
        });
      const bookingURL = await generateUrl(pet, chat);
      if (!bookingURL || !pet) return;
      setBooked({ show: true, url: bookingURL });
      console.log({ pet, bookingURL });
      console.log({ selectedOptions });
    },
  });
  /*
   * RENDERING
   */
  const choosePet = () => {
    if (!user || !user.pets) return;
    return user.pets.map((p) => {
      return (
        <>
          <button
            className={
              p._id == selectedOptions.pet
                ? "button btn btn-purple square"
                : "button btn btn-purple-light square"
            }
            onClick={() => {
              setSelectedOptions({
                ...selectedOptions,
                pet: p._id,
                chat: "vet",
              });
            }}
          >
            {p.name}
          </button>
        </>
      );
    });
  };
  const chooseChat = () => {
    const chats: {
      label: string;
      url: "vet" | "behaviourConsult" | "puppyPreschool";
      puppyEligibility: boolean;
    }[] = [
      {
        label: "Vet Chat",
        url: "vet",
        puppyEligibility: false,
      },
      {
        label: "Behavior Chat",
        url: "behaviourConsult",
        puppyEligibility: false,
      },
      {
        label: "Puppy Preschool",
        url: "puppyPreschool",
        puppyEligibility: true,
      },
    ];
    return Object.keys(chats).map((k: any) => {
      const { pet: petId } = selectedOptions;
      const pet =
        user &&
        user.pets.find((p) => {
          return p._id == petId;
        });
      const eligibility = getPreschoolEligibility(pet);
      console.log({ eligibility });
      if (!eligibility && chats[k].puppyEligibility) return;
      return (
        <>
          <button
            className={
              chats[k].url == selectedOptions.chat
                ? "button btn btn-purple square"
                : "button btn btn-purple-light square"
            }
            onClick={() => {
              setSelectedOptions({ ...selectedOptions, chat: chats[k].url });
            }}
          >
            {chats[k].label}
          </button>
        </>
      );
    });
  };
  return (
    <div className="dashboard">
      <div className="content-header">
        <div className="flex align-center">
          <img src={thrivingPetsLogoDarkReverse} />
        </div>
      </div>
      <div className="content-body">
        <div className="field flex flex-start flex-align-center">
          <img style={{ width: "100px", maxWidth: "100%" }} src={headsetIcon} />
          <h1>Book a Vet Chat</h1>
        </div>
        <h3>WHO IS THE APPOINTMENT FOR?</h3>
        <label>Select a Pet:</label>
        <div className="field">{choosePet()}</div>
        <label>What type of chat do you need:</label>
        <div className="field">{chooseChat()}</div>
        <hr />
        <br />
        <div className="field">
          <Button
            label="Select Appointment"
            customClass="button btn btn-purple square"
            onClick={() => {
              BookChat.handleSubmit();
            }}
          />
          <Button
            label="Cancel"
            customClass="button btn btn-grey square"
            onClick={() => history.push("/dashboard")}
          />
        </div>
      </div>
      <Modal
        isOpen={booked.show}
        closeModal={() => {
          setBooked({ ...booked, show: false, url: "" });
          history.push("/dashboard");
        }}
        url={booked.url}
      />
    </div>
  );
};

export default BookAVet;
