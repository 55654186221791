import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { updatePassword } from "../../api/authorisation";
import { useFormik } from "formik";
import { isValidPassword, isEqualValue } from "../../utils/formik-validate";
import { TextInput } from "../../components/input";
import { Button } from "../../components/common";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import { thrivingPetsLogoDarkReverse } from "./Module";

const ChangePassword: React.FC = () => {
  const { fetchUserData, updateUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  useEffect(() => {
    if (!isAuthenticated) return history.push("/");
    fetchUserData();
  }, [isAuthenticated]);
  const EditPassword = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      const { newPassword } = values;
      console.log(newPassword, user?.email);
      if (!user?.email) return;
      setIsLoading(true);
      await updatePassword(user?.email, newPassword).then((r) => {
        if (!r) return toast.error("Your entered your old password!");
        toast.success("Successfully updated your password");
        setIsLoading(false);
        return history.push("/dashboard");
      });
    },
    validate: async ({ newPassword, confirmPassword }) => {
      const errors = {
        ...isValidPassword(newPassword, "newPassword"),
        ...isValidPassword(confirmPassword, "confirmPassword"),
        ...isEqualValue(
          newPassword,
          confirmPassword,
          "confirmPassword",
          "Password donot match"
        ),
      };
      console.log(errors);
      return errors;
    },
  });
  /*
   * RENDERING
   */
  return (
    <div className="dashboard">
      <div className="content-header">
        <div className="flex align-center">
          <img src={thrivingPetsLogoDarkReverse} />
        </div>
      </div>
      <div className="content-body">
        <div className="field">
          <h1>Account Details</h1>
        </div>
        <div className="field flex flex-start">
          <TextInput
            label="Email"
            value={user?.email ? user.email : ""}
            disabled={true}
            onChange={() => {}}
          />
        </div>
        <p>
          To change the email address associated with your account, please {""}
          <a href="mailto:info@thrivingpets.com.au">
            <strong>contact us.</strong>
          </a>
        </p>
        <div className="field flex flex-start">
          <TextInput
            label="New Password"
            type="password"
            placeholder="Enter new password:"
            value={EditPassword.values.newPassword}
            error={EditPassword.errors.newPassword}
            touched={true}
            onChange={EditPassword.handleChange("newPassword")}
          />
          <TextInput
            label="Confirm Password"
            type="password"
            placeholder="Confirm new password:"
            value={EditPassword.values.confirmPassword}
            error={EditPassword.errors.confirmPassword}
            touched={true}
            onChange={EditPassword.handleChange("confirmPassword")}
          />
        </div>
      </div>
      <div className="content-footer">
        <p>
          <Button
            label="Submit"
            customClass="button btn btn-purple square"
            isDisabled={isLoading}
            onClick={() => {
              EditPassword.handleSubmit();
            }}
          />
          <Button
            label="Cancel"
            customClass="button btn btn-grey square"
            onClick={() => history.push("/dashboard")}
          />
        </p>
      </div>
    </div>
  );
};

export default ChangePassword;
