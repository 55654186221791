import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { LogoutAction } from "./Auth";

export interface ShoppingCartLineItem {
  id: string;
  title: string;
  quantity: number;
  variant: {
    id: string;
    price: number;
    title: string;
    image: {
      src: string;
    };
  };
}

export interface ShoppingCart {
  id: string;
  ready?: boolean;
  currencyCode?: string;
  subtotalPrice?: string;
  taxesIncluded?: boolean;
  totalTax?: string;
  totalPrice?: string;
  webUrl?: string;
  completedAt?: Date;
  lineItems?: ShoppingCartLineItem[];
  coupon?: {
    code: string;
    percentDiscount?: number;
  };
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CartState {
  cart?: ShoppingCart;
  open: boolean;
  prescribeProducts: string[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface UpdateCartAction {
  type: "UPDATE_CART";
  payload: {
    cart?: ShoppingCart;
  };
}

export interface ShowCartAction {
  type: "SHOW_CART";
  payload: {
    open: boolean;
  };
}

export interface ClearCartAction {
  type: "CLEAR_CART";
}

export interface AddToPrescribeProducts {
  type: "ADD_TO_PRESCRIBE_PRODUCTS";
  payload: {
    productId: string;
  };
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | LogoutAction
  | UpdateCartAction
  | ShowCartAction
  | ClearCartAction
  | AddToPrescribeProducts;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  updateCart:
    (cart: ShoppingCart): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      if (cart?.id != null) {
        dispatch({
          type: "UPDATE_CART",
          payload: { cart },
        });
      }
    },
  updateCartOpen:
    (open: boolean): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      dispatch({
        type: "SHOW_CART",
        payload: { open },
      });
    },
  prescribeProducts:
    (productId: string): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      //console.log({ toAdd, toRemove, productId });
      dispatch({
        type: "ADD_TO_PRESCRIBE_PRODUCTS",
        payload: { productId },
      });
    },
  clearCart: (): AppThunkAction<KnownAction> => async (dispatch) => {
    dispatch({
      type: "CLEAR_CART",
    });
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<CartState> = (
  state: CartState | undefined,
  incomingAction: Action
): CartState => {
  const defaultState = {
    cart: undefined,
    open: false,
    prescribeProducts: [],
  };

  if (state === undefined) {
    return defaultState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "LOGOUT":
      return defaultState;
    case "UPDATE_CART":
      if (action.payload.cart != null) {
        return {
          ...state,
          cart: action.payload.cart,
        };
      }
      break;
    case "SHOW_CART":
      if (action.payload.open != null) {
        return {
          ...state,
          open: action.payload.open,
        };
      }
      break;
    case "CLEAR_CART":
      return {
        ...state,
        cart: undefined,
      };
    case "ADD_TO_PRESCRIBE_PRODUCTS":
      const { productId } = action.payload;
      let { prescribeProducts } = state;
      prescribeProducts = prescribeProducts ? prescribeProducts : [];
      if (prescribeProducts?.indexOf(productId) == -1)
        prescribeProducts.push(productId);
      //console.log({ state, prescribeProducts, productId });
      return {
        ...state,
        prescribeProducts,
      };
    default:
      return state;
  }

  return state;
};
