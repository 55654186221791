import "./ImageInput.scss";
import React, { LegacyRef, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { Modal, Row } from "../../layout";
import { Button } from "../../common";
import { FileInput } from "..";

interface ImageInputProps {
  /**
   * The function to be called when the user is finished
   * editing their image.
   */
  onClickSave: (updatedImage: Blob, previewUrl: string) => void;
}

/**
 * Input field for choosing and editing an image.
 */
const ImageInput: React.FC<ImageInputProps> = ({ onClickSave }) => {
  const editorRef = useRef<AvatarEditor>(null);

  const [modelOpen, setModalOpen] = useState(false);
  const [scale, setScale] = useState(1.0);
  const [originalImageUrl, setOriginalImageUrl] = useState("");

  /**
   * Handles the save button click. Generates the edited image and
   * calls the provided onClickSave with it.
   */
  const saveAndReturnImage = async () => {
    (async () => {
      if (editorRef && editorRef.current) {
        const canvas = await editorRef.current.getImage().toDataURL();
        const dataUrl = await fetch(canvas);
        const blob = await dataUrl.blob();
        onClickSave(blob, dataUrl.url);
        setOriginalImageUrl("");
        setModalOpen(false);
      }
    })();
  };

  return (
    <>
      <FileInput
        label="Upload Photo"
        onChange={(event) => {
          if (event.currentTarget.files) {
            setOriginalImageUrl(
              URL.createObjectURL(event.currentTarget.files[0])
            );
            setModalOpen(true);
          }
        }}
      />
      <Modal
        isOpen={modelOpen}
        closeModal={() => {
          setModalOpen(false);
          setOriginalImageUrl("");
        }}
        className="imagecropper"
        maxWidth={450}
      >
        <h2>Image Editor</h2>
        <AvatarEditor
          image={originalImageUrl}
          border={50}
          color={[0, 0, 0, 0.6]}
          width={300}
          height={300}
          scale={scale}
          rotate={0}
          ref={editorRef as unknown as LegacyRef<AvatarEditor>}
        />
        <fieldset>
          <label>scale: {scale}</label>
          <input
            className="imagecropper__scaleinput"
            value={scale}
            onChange={(e) => {
              setScale(+e.target.value);
            }}
            type="range"
            min="1"
            max="5"
            step="0.1"
          />
        </fieldset>
        <Row>
          <Button label="Save" onClick={saveAndReturnImage} />
        </Row>
      </Modal>
    </>
  );
};

export default ImageInput;
