import "react-datepicker/dist/react-datepicker.css";
import "./DateInput.scss";
import ReactDatePicker from "react-datepicker";
import { InputError, InputLabel } from "..";
import { InputProps } from "../../../types/inputs";
import { useEffect, useState } from "react";
import { Button } from "../../common";

interface DateInputProps extends InputProps {
  /**
   * Include this prop if you want the button inside the picker.
   * Allow an option for Unix epoch date values.
   * (can be used for options like 'unsure' or 'never').
   */
  allowEpochOption?: boolean;
  /**
   * Make sure you include this prop with with allowEpochOption as it is used for various
   * functionality when allowEpochOption is used.
   * Can be passed in on disabled datePicker to display 1/1/1970 however you want.
   */
  epochInputText?: string;
}
/**
 * Input component for handling date inputs
 */
const DateInput: React.VFC<DateInputProps> = ({
  label,
  onChange,
  disabled = false,
  value,
  touched,
  required,
  error,
  allowEpochOption = false,
  epochInputText = "unsure",
}) => {
  const [savedDate, setSavedDate] = useState("");

  /**
   * Function to be called when a new date is selected.
   */
  const onDateSelect = (newDate: Date | [Date | null, Date | null] | null) => {
    // If value is a date (and not an array)
    if (newDate && !(newDate instanceof Array)) {
      onChange(newDate.toISOString());
      setSavedDate(newDate.toISOString());
      // If value is an array (and contains a date)
    } else if (newDate instanceof Array && newDate[1]) {
      onChange(newDate[1].toISOString());
      setSavedDate(newDate[1].toISOString());
    }
  };
  /**
   * Used on epoch button to handle setting the date to epoch
   */
  const handleChangeRaw = (date: string) => {
    if (date === epochInputText) {
      setSavedDate(epochInputText);
      onChange(new Date(0).toISOString());
    }
  };
  /**
   * Used for displaying already set date
   */
  useEffect(() => {
    if (value === new Date(0).toISOString()) {
      setSavedDate(epochInputText);
    } else if (value && savedDate !== epochInputText) {
      setSavedDate(value);
    }
  }, [value]);

  return (
    <div className="dateinput">
      <InputLabel label={label} required={required} />
      <ReactDatePicker
        value={
          savedDate && savedDate !== epochInputText
            ? new Date(savedDate).toLocaleDateString()
            : savedDate === epochInputText
            ? epochInputText[0].toUpperCase() +
              epochInputText.substring(1).toLowerCase()
            : ""
        }
        onChange={onDateSelect}
        disabled={disabled}
        minDate={new Date(0)}
        maxDate={new Date()}
        className="dateinput__input"
        openToDate={
          savedDate && savedDate !== epochInputText
            ? new Date(savedDate)
            : new Date()
        }
        showMonthDropdown
        useShortMonthInDropdown
        showYearDropdown
        dropdownMode="select"
      >
        {allowEpochOption && (
          <Button
            label={
              epochInputText[0].toUpperCase() +
              epochInputText.substring(1).toLowerCase()
            }
            onClick={() => handleChangeRaw(epochInputText)}
          />
        )}
      </ReactDatePicker>
      <InputError error={error} touched={touched} />
    </div>
  );
};

export default DateInput;
