import "./Card.scss";
import React from "react";

interface CardProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}

const Card: React.FC<CardProps> = ({ children, className }): JSX.Element => {
  return <div className={`card ${className}`}>{children}</div>;
};

export default Card;
