import "./DropdownInput.scss";
import React, { useEffect, useState } from "react";
import { InputError, InputLabel } from "..";
import { InputOptionWithImage, InputProps } from "../../../types/inputs";
import Select, { SingleValue } from "react-select";

export interface DropdownInputProps extends InputProps {
  /**
   * The options to be displayed within dropdown.
   */
  options: InputOptionWithImage[];
  /**
   * Function to be passed in so that the parent component can
   * be notified of any query changes. Query state will still
   * be managed internally. Will be cleared when the value prop
   * is updated.
   */
  onQueryChange?: (query: string) => void;
  /**
   * Disables the internal filtering of options. Perfect for
   * when you want to filter options outside of this component.
   */
  disableFilter?: boolean;
  /**
   * Disables the ability to type in search bar.
   */
  disableSearch?: boolean;
  /**
   * Removes borders and margins so that the component can
   * be used seemlessly inside other components.
   */
  borderless?: boolean;

  isLoading?: boolean;
}

/**
 * Component for selecting options from a dropdown.
 * Wraps the React Select component: https://react-select.com/
 */
const DropdownMultipleInput: React.VFC<DropdownInputProps> = ({
  label,
  required,
  options,
  onChange,
  onQueryChange,
  disableFilter,
  disableSearch,
  error,
  touched,
  borderless,
  isLoading = false,
}) => {
  //const [filteredOptions, setFilteredOptions] = useState(options);
  //const filteredOptions = options;
  const styleModifier = borderless ? "borderless" : "default";
  console.log({ options });
  return (
    <div className={`dropdowninput-${styleModifier}`}>
      <InputLabel label={label} required={required} />
      <Select
        isLoading={isLoading}
        className={`dropdowninput__input`}
        options={options}
        isMulti={true}
        isSearchable={true}
        onChange={(opts: any) => {
          onChange(opts);
        }}
        styles={{
          // Remove default container styles
          control: (provided) => {
            return {
              ...provided,
              border: "none",
              borderRadius: 2,
            };
          },
          // Start: Inject image if option contains image
          input: (provided, state) => {
            const image = options.find(
              (option) => option.value === state.value
            )?.image;
            if (image) {
              return {
                ...provided,
                display: "flex",
              };
            } else {
              return provided;
            }
          },
          singleValue: (provided, state) => {
            const image = options.find(
              (option) => option.value === state.data.value
            )?.image;
            if (image) {
              return {
                ...provided,
                width: 50,
                marginLeft: 50,
                ":before": {
                  content: '""',
                  width: 44,
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 5,
                  bottom: 0,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundImage: `url(${image})`,
                  backgroundPosition: "center",
                },
              };
            } else {
              return provided;
            }
          },
          option: (provided, state) => {
            const image = options.find(
              (o) => o.value === state.data.value
            )?.image;
            const optionStyles = {
              backgroundColor: state.isSelected
                ? "#71cace"
                : state.isFocused
                ? "#e8f7f7"
                : undefined,
            };
            if (image) {
              return {
                ...provided,
                ...optionStyles,
                width: "100%",
                position: "relative",
                paddingLeft: 55,
                ":before": {
                  content: '""',
                  width: 44,
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 5,
                  bottom: 0,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundImage: `url(${image})`,
                  backgroundPosition: "center",
                },
              };
            } else {
              return { ...provided, ...optionStyles };
            }
          },
          // End: Inject image if option contains image
        }}
      />
      <InputError error={error} touched={touched} />
    </div>
  );
};

export default DropdownMultipleInput;
