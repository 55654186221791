import "./ProfileImage.scss";
import React from "react";

interface ProfileImageProps {
  /**
   * The URL of the image to be displayed inside the component.
   */
  imageUrl?: string;
  /**
   * Handles action to be performed when clicked (when not run as input).
   */
  onClick?:
    | (() => void)
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void);
  /**
   * The label to be displayed above the image upload component
   */
  label?: string;
}

/**
 * Renders a profile image used to display pet profile images.
 */
const ProfileImage: React.FC<ProfileImageProps> = ({
  imageUrl,
  onClick,
  label,
}) => {
  const clickable = onClick ? "profileimage-clickable" : "";

  return (
    <div className={`profileimage ${clickable}`} onClick={onClick}>
      <div
        className="profileimage__profile"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      {label && <p className="profileimage__label">{label}</p>}
    </div>
  );
};

export default ProfileImage;
