import "./SecondaryMenu.scss";
import React from "react";
import { Overflow } from "../../layout";
import { MenuItem } from "../../../types/menu";

interface SecondaryMenuProps {
  /**
   * Items to be rendered in the menu.
   */
  menuItems: MenuItem[];
  /**
   * The value to compare the MenuItem route
   * with to determine active status.
   */
  activeRouteCompare?: string;
}

/**
 * A secondary level navigation menu. Primary used in the HomeTemplate.
 */
const SecondaryMenu: React.VFC<SecondaryMenuProps> = ({
  menuItems,
  activeRouteCompare,
}) => {
  /**
   * Determine if route is active.
   */
  const isActiveRoute = (route: string | undefined): boolean => {
    if (route && activeRouteCompare) {
      return (
        route === activeRouteCompare ||
        (route !== "/" && activeRouteCompare.includes(route))
      );
    } else {
      return false;
    }
  };

  return (
    <Overflow>
      <nav className="secondarymenu">
        <ul className="secondarymenu__ul">
          {menuItems.map(({ label, iconUrl, onClick, route }) => {
            const active = isActiveRoute(route)
              ? "secondarymenu__li-active"
              : "";
            return (
              <li className={`secondarymenu__li ${active}`} onClick={onClick}>
                <img className="secondarymenu__icon" src={iconUrl} />
                {label}
              </li>
            );
          })}
        </ul>
      </nav>
    </Overflow>
  );
};

export default SecondaryMenu;
