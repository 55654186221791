import "./Subscription.scss";
import React, { useState } from "react";
import { Button } from "../../common/";
import { PlanBenefitsModal } from "../../modals";

interface SubscriptionProps {
  petId: string;
  petName: string;
  planName: string | undefined;
}

/**
 * Renders a single subscription for a pet. Used in
 * a list view.
 */
const Subscription: React.FC<SubscriptionProps> = ({
  petId,
  petName,
  planName,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="subscription" key={petId}>
        <div className="subscription__content">
          <h3>{petName}</h3>
          <p>{planName}</p>
        </div>
        <div className="subscription__buttons">
          <Button
            fullwidth
            label="Benefits"
            onClick={() => setModalOpen(true)}
          />
        </div>
      </div>
      <PlanBenefitsModal
        petId={petId}
        modalOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      />
    </>
  );
};

export default Subscription;
