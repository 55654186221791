import "./Login.scss";
import { LoginForm } from "../../components/form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import history from "../../utils/history";
import logo from "../../assets/icons/thrivingpets-logo-light.svg";
import { SEO } from "../../components/app";

const Login: React.VFC = () => {
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
  /**
   * Check if user is authenticated and direct
   * them to the home screen.
   */
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    }
  }, [isAuthenticated]);

  const renderDisclaimer = () => {
    return (
      <div className="disclaimer__body">
        If you have created an account on
        <span> shop.yourpetpa.com.au</span> or
        <span> shop.yourpetpa.co.nz </span>
        you will have to create another account here. You can still use the
        <span> same email</span> as your account from shop.yourpetpa.com.au or
        shop.yourpetpa.co.nz as it will be linked to your new account upon
        creation.
      </div>
    );
  };

  const renderDisclaimerButton = () => {
    if (!showDisclaimer) {
      return (
        <a
          className="disclaimer__button"
          onClick={() => setShowDisclaimer(!showDisclaimer)}
        >
          <span>Info &#62;</span>
        </a>
      );
    } else {
      return (
        <a
          className="disclaimer__button"
          onClick={() => setShowDisclaimer(!showDisclaimer)}
        >
          <span>Minimize</span>
        </a>
      );
    }
  };

  return (
    <div className="login">
      <SEO title="Login" />
      <div className="login__formheader">
        <img src={logo} />
      </div>
      <div className="login__formbody">
        <div className="disclaimer">
          {renderDisclaimerButton()}
          {showDisclaimer && renderDisclaimer()}
        </div>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
