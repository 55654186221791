import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { CountryCode } from "../types/country";

/**
 * Hook for extracting the country code from state in a
 * format compatible with the backend.
 */
const useCountryCode = (): CountryCode | undefined => {
  // Get country from backend
  const country = useSelector(
    (state: ApplicationState) => state?.user?.address?.country
  );

  // Get correct country code for provided country
  const getCountryCode = (c: string | undefined) => {
    switch (c) {
      case "Australia":
        return CountryCode.AU;
      case "New Zealand":
        return CountryCode.NZ;
      default:
        return undefined;
    }
  };

  // The country code variable to be returned
  const [countryCode, setCountryCode] = useState<CountryCode | undefined>(
    getCountryCode(country)
  );

  // Set the correct country based on user's address
  useEffect(() => {
    setCountryCode(getCountryCode(country));
    // Rerender if country ever changes
  }, [country]);

  // Return the country code
  return countryCode;
};

export default useCountryCode;
