import { useEffect } from "react";
import { actionCreators as globalActionCreators } from "../store/Global";
import { actionCreators as userActionCreators } from "../store/User";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store";

/**
 * Hook for fetching initial remote content required for
 * this application to run
 */
const usePopulateInitialData = (): void => {
  const { fetchPetOptions, fetchPlanConfig, fetchUserData } =
    bindActionCreators(
      {
        ...globalActionCreators,
        ...userActionCreators,
      },
      useDispatch()
    );

  /**
   * Fetch authentication status
   */
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );

  /**
   * Refetch data on load or whenever isAuthenticated changes.
   */
  useEffect(() => {
    fetchPetOptions();
    fetchPlanConfig();

    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);
};

export default usePopulateInitialData;
