import axios from "../api/axios";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { LogoutAction } from "./Auth";
import { Pet, PetOptions } from "../types/pet";
import { toast } from "react-toastify";
import { PlanConfig, ThrivingPlan } from "../types/plans";
const API = process.env.REACT_APP_BACKEND_API;

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface GlobalState {
  /**
   * Valid pet options such as colours and breeds (fetched from the backend)
   */
  petOptions: PetOptions;
  /**
   * Data about the plans (fetched from the backend)
   */
  planConfig: PlanConfig;
  /**
   * Fetches more specific plan info from backend regarding thrive plans.
   */
  thrivingPlanInfo: ThrivingPlan[];
  /**
   * The ID of the currently selected pet.
   */
  selectedPet: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface FetchPetOptionsAction {
  type: "RECIEVE_PET_OPTIONS";
  payload: PetOptions;
}

export interface SaveSelectedPetAction {
  type: "SAVE_SELECTED_PET";
  payload: { selectedPet: Pet["_id"] };
}

export interface FetchPlanConfig {
  type: "FETCH_PLAN_CONFIG";
  payload: PlanConfig;
}

export interface FetchThrivingPlanInfo {
  type: "FETCH_THRIVING_PLAN_INFO";
  payload: ThrivingPlan[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | LogoutAction
  | FetchPetOptionsAction
  | SaveSelectedPetAction
  | FetchPlanConfig
  | FetchThrivingPlanInfo;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  fetchPetOptions:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get<PetOptions>(`${API}/data/petoptions`);
          if (res.status === 200) {
            dispatch({ type: "RECIEVE_PET_OPTIONS", payload: res.data });
          }
        } catch (error) {
          toast.error("Something went wrong, please try refreshing your page.");
        }
      }
    },
  saveSelectedPet:
    (petId: Pet["_id"]): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: "SAVE_SELECTED_PET",
          payload: { selectedPet: petId },
        });
      }
    },
  fetchPlanConfig:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get<PlanConfig>(`${API}/portal/data/plans`);
          if (res.status === 200) {
            dispatch({ type: "FETCH_PLAN_CONFIG", payload: res.data });
          }
        } catch (error) {
          toast.error("Something went wrong, please try refreshing your page.");
        }
      }
    },
  fetchThrivingPlanInfo:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get<{ plans: ThrivingPlan[] }>(
            `${API}/data/thrivingPlans`
          );
          if (res.status === 200) {
            dispatch({
              type: "FETCH_THRIVING_PLAN_INFO",
              payload: res.data.plans,
            });
          }
        } catch (error) {
          toast.error("Something went wrong, please try refreshing your page.");
        }
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<GlobalState | undefined> = (
  state,
  incomingAction: Action
): GlobalState => {
  const defaultState: GlobalState = {
    petOptions: {
      petColours: [],
      catBreeds: [],
      dogBreeds: [],
    },
    selectedPet: "",
    planConfig: {
      planCollectionID: "",
      plans: [],
    },
    thrivingPlanInfo: [],
  };

  if (state === undefined) {
    return defaultState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "LOGOUT":
      return defaultState;
    case "RECIEVE_PET_OPTIONS":
      return { ...state, petOptions: action.payload };
    case "SAVE_SELECTED_PET":
      return { ...state, ...action.payload };
    case "FETCH_PLAN_CONFIG":
      return { ...state, planConfig: action.payload };
    case "FETCH_THRIVING_PLAN_INFO":
      return { ...state, thrivingPlanInfo: action.payload };
    default:
      return state;
  }
};
