import { useFormik } from "formik";
import { useState } from "react";
import { Form } from "..";
import { Button } from "../../common";
import { TextInput } from "../../input";
import { Row } from "../../layout";

interface EmailFormProps {
  /**
   * used for passing email to next part of form
   */
  passEmail: (email: string, skip: boolean) => void;
}
const EmailForm: React.FC<EmailFormProps> = ({ passEmail }) => {
  const initialValues = {
    email: "",
  };
  const [skip, setSkip] = useState(false);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      passEmail(values.email, skip);
    },
    validate: async ({}) => {
      const errors = {};
      return errors;
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <TextInput
          label="Email"
          value={formik.values.email}
          error={formik.errors.email}
          touched={formik.touched.email}
          type="email"
          onChange={formik.handleChange("email")}
          required
        />
      </Row>
      <div className="flex">
        <Button
          label="Verify Account"
          onClick={() => {
            setSkip(false);
            formik.handleSubmit();
          }}
        />
        <Button
          label="I have a Code"
          type="warning"
          onClick={() => {
            setSkip(true);
            formik.handleSubmit();
          }}
        />
      </div>
    </Form>
  );
};

export default EmailForm;
