import { User } from "../types/user";

/**
 * Takes a user object and returns the full name.
 */
export const getFullName = (user: User | undefined): string => {
  let name = "";

  if (user?.firstName) {
    name += user.firstName;
  }

  if (user?.lastName) {
    name += " ";
    name += user.lastName;
  }

  return name;
};
