import "./Dashboard.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useFormik } from "formik";
import { Row } from "../../components/layout";
import {
  TextInput,
  DropdownInput,
  DateInput,
  ImageInput,
  ProvidedRadioInput,
} from "../../components/input";
import { Button } from "../../components/common";
import { InputOption, TwoOrMoreArray } from "../../types/inputs";
import { ProvidedRadioOption } from "../../components/input/ProvidedRadioInput/ProvidedRadioInput";
import { ApplicationState } from "../../store";
import { useRemainingClaims } from "../../hooks/useRemainingClaims";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import {
  thrivingPetsLogoDarkReverse,
  headsetIcon,
  chatIcon,
  checkIcon,
  minusIcon,
  truckIcon,
  hospitalIcon,
  injectionIcon,
  stethoscopeIcon,
  toothbrushIcon,
  desexedIcon,
  storeIcon,
} from "./Module";

const claimChoices = [
  { value: "vaccination", label: "Vaccination" },
  { value: "clinicConsultation", label: "Clinic Consultation" },
  { value: "dental", label: "Dental" },
  { value: "wellnessBloodTest", label: "Wellness Blood Test" },
  { value: "desex", label: "Desexing Procedure" },
];
//const CreateAClaim: React.FC<BookAVetType> = ({ closeModal }) => {
const EditPetDetail: React.FC<any> = ({ pet, cancel, planView }) => {
  const location = useLocation();
  const { updatePetData, fetchUserData, updateUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const petOptions = useSelector(
    (state: ApplicationState) => state.global?.petOptions
  );
  const [previewUrl, setPreviewUrl] = useState("");
  const [petBreeds, setPetBreeds] = useState<any>([]);
  const [availableClaims, setAvailableClaims] = useState<any>(null);
  //const [planView, setPlanView] = useState<string>("detail");
  const [petInformation, setPetInformation] = useState({
    _id: pet?._id || "",
    desexed: pet?.desexed || "desexed",
    weight: pet?.weight || "",
    breed: pet?.breed || "",
    color: pet?.color || "",
    imageUrl: pet?.imageUrl || "",
    image: undefined,
    plan: pet?.plan || "NoPlan",
    healthConditions: pet?.healthConditions || "",
    medications: pet?.medications || "",
    UTDvaccinations: pet?.UTDvaccinations || "unsure",
    UTDpreventatives: pet?.UTDpreventatives || "unsure",
    lastTickAndFlea: pet?.lastTickAndFlea || new Date(0),
    lastVaccination: pet?.lastVaccination || new Date(0),
    lastHealthCheckDate: pet?.lastHealthCheckDate || new Date(0),
    lastDentalCheck: pet?.lastDentalCheck || new Date(0),
    lastDentalProcedure: pet?.lastDentalProcedure || new Date(0),
    preventativeProduct: pet?.preventativeProduct || "",
  });
  const checkForEpoch = (date: Date) => {
    if (
      new Date(date) === new Date(0) ||
      new Date(date).getFullYear() === 1970
    ) {
      return new Date(date).toISOString();
    } else {
      return date.toLocaleString();
    }
  };
  const desexOptions: TwoOrMoreArray<ProvidedRadioOption> = [
    {
      key: "desexed",
      text: "",
      Unselected: () => (
        <Button type="secondary" label="Desexed" onClick={() => {}} />
      ),
      Selected: () => (
        <Button type="primary" label="Desexed" onClick={() => {}} />
      ),
    },
    {
      key: "not desexed",
      text: "",
      Unselected: () => (
        <Button type="secondary" label="Not Desexed" onClick={() => {}} />
      ),
      Selected: () => (
        <Button type="primary" label="Not Desexed" onClick={() => {}} />
      ),
    },
  ];
  const fieldOptions: TwoOrMoreArray<ProvidedRadioOption> = [
    {
      key: "yes",
      text: "",
      Unselected: () => (
        <Button type="secondary" label="Yes" onClick={() => {}} />
      ),
      Selected: () => <Button type="primary" label="Yes" onClick={() => {}} />,
    },
    {
      key: "no",
      text: "",
      Unselected: () => (
        <Button type="secondary" label="No" onClick={() => {}} />
      ),
      Selected: () => <Button type="primary" label="No" onClick={() => {}} />,
    },
    {
      key: "unsure",
      text: "",
      Unselected: () => (
        <Button type="secondary" label="Unsure" onClick={() => {}} />
      ),
      Selected: () => (
        <Button type="primary" label="Unsure" onClick={() => {}} />
      ),
    },
  ];
  const healthConditions: InputOption[] = [
    { value: "none", label: "None" },
    { value: "cardiac", label: "Cardiac" },
    { value: "itchySkin", label: "Itchy Skin" },
    { value: "allergy", label: "Allergy" },
    { value: "arthritis", label: "Arthritis" },
    { value: "earConcerns", label: "Ear Concerns" },
    { value: "behavioural", label: "Behavioural" },
    { value: "diabetes", label: "Diabetes" },
    { value: "gastrointestinal", label: "Gastrointestinal" },
    { value: "urinary", label: "Urinary" },
    { value: "liverDisease", label: "Liver Disease" },
    { value: "pancreaticDisease", label: "Pancreatic disease" },
    { value: "kidneyDisease", label: "Kidney disease" },
    { value: "respiratory", label: "Respiratory" },
    { value: "seizures", label: "Seizures" },
    { value: "neurological", label: "Neurological" },
    { value: "eyeDisorder", label: "Eye Disorder" },
    { value: "other", label: "Other" },
  ];
  const UpdatePetInformation = useFormik({
    initialValues: petInformation,
    onSubmit: async (values) => {
      console.log({ values });
      //setLoading(true);
      await updatePetData({ ...values });
      await fetchUserData();
      history.push("/dashboard");
    },
  });

  useEffect(() => {
    if (!isAuthenticated) return history.push("/");
  }, [isAuthenticated, location]);
  const { getRemainingClaims } = useRemainingClaims(pet._id);
  useEffect(() => {
    getRemainingClaims().then((map) => {
      const set = claimChoices
        .filter(
          (claim) =>
            map.get(claim.value) > 0 || map.get(claim.value) === "Unlimited"
        )
        .map((m) => {
          return m.value;
        });
      setAvailableClaims(set);
    });
    //if cat or dog
    let theBreeds =
      petOptions &&
      petOptions.dogBreeds.map((b) => {
        return { label: b, value: b };
      });
    if (pet?.type == "cat")
      theBreeds =
        petOptions &&
        petOptions.catBreeds.map((b) => {
          return { label: b, value: b };
        });
    setPetBreeds(theBreeds);
  }, [pet._id]);

  const WellnessInformation = () => {
    return (
      <>
        <Row>
          <TextInput
            label={`Is ${pet?.name.toUpperCase()} currently on any medication?`}
            value={UpdatePetInformation.values.medications}
            onChange={UpdatePetInformation.handleChange("medications")}
          />
          <TextInput
            label={`What preventative products does ${pet?.name.toUpperCase()} currently take?`}
            value={UpdatePetInformation.values.preventativeProduct}
            onChange={UpdatePetInformation.handleChange("preventativeProduct")}
          />
        </Row>
        <Row>
          <DropdownInput
            label={`Does ${pet?.name.toUpperCase()} have any underlying health conditions?`}
            options={healthConditions}
            value={UpdatePetInformation.values.healthConditions}
            onChange={UpdatePetInformation.handleChange("healthConditions")}
          />
          <></>
        </Row>
        <Row>
          <ProvidedRadioInput
            label={`Is ${pet?.name.toUpperCase()} up to date on all vaccinations?`}
            options={fieldOptions}
            value={UpdatePetInformation.values.UTDvaccinations}
            onChange={UpdatePetInformation.handleChange("UTDvaccinations")}
          />
          <ProvidedRadioInput
            label={`Is ${pet?.name.toUpperCase()} up to date on all preventative treatments?`}
            options={fieldOptions}
            value={UpdatePetInformation.values.UTDpreventatives}
            onChange={UpdatePetInformation.handleChange("UTDpreventatives")}
          />
        </Row>
        <Row>
          <DateInput
            label="When was the last administration of paralysis tick & flea protection?"
            value={checkForEpoch(UpdatePetInformation.values.lastTickAndFlea)}
            onChange={UpdatePetInformation.handleChange("lastTickAndFlea")}
            allowEpochOption
            epochInputText="Unsure"
          />
          <DateInput
            label={`When was ${pet?.name.toUpperCase()} last vaccination?`}
            value={checkForEpoch(UpdatePetInformation.values.lastVaccination)}
            onChange={UpdatePetInformation.handleChange("lastVaccination")}
            allowEpochOption
            epochInputText="Unsure"
          />
        </Row>
        <Row>
          <DateInput
            label={`When was ${pet?.name.toUpperCase()} last health check?`}
            value={checkForEpoch(
              UpdatePetInformation.values.lastHealthCheckDate
            )}
            onChange={UpdatePetInformation.handleChange("lastHealthCheckDate")}
            allowEpochOption
            epochInputText="Unsure"
          />
          <DateInput
            label={`When was ${pet?.name.toUpperCase()} last dental health check?`}
            value={checkForEpoch(UpdatePetInformation.values.lastDentalCheck)}
            onChange={UpdatePetInformation.handleChange("lastDentalCheck")}
            allowEpochOption
            epochInputText="Unsure"
          />
          <DateInput
            label={`When was ${pet?.name.toUpperCase()} last dental procedure?`}
            value={checkForEpoch(
              UpdatePetInformation.values.lastDentalProcedure
            )}
            onChange={UpdatePetInformation.handleChange("lastDentalProcedure")}
            allowEpochOption
            epochInputText="Unsure"
          />
        </Row>
        <br />
        <div>
          <Button
            label="Update Pet"
            customClass="button btn btn-purple square"
            onClick={() => UpdatePetInformation.handleSubmit()}
          />
          <Button
            label="Cancel"
            customClass="button btn btn-grey square"
            onClick={() => cancel()}
          />
        </div>
      </>
    );
  };
  const BasicInformation = () => {
    return (
      <>
        <div className="image-uploader">
          <Row>
            <div className="flex" style={{ alignItems: "end" }}>
              {UpdatePetInformation.values.image && (
                <img className="preview" src={previewUrl} height="200" />
              )}
              <ImageInput
                onClickSave={(image, previewImgUrl) => {
                  UpdatePetInformation.setFieldValue("image", image);
                  setPreviewUrl(previewImgUrl);
                }}
              />
            </div>
            <TextInput
              label="Pet Name"
              disabled={true}
              value={pet?.name}
              onChange={() => {
                console.log();
              }}
            />
          </Row>
        </div>
        <Row>
          <ProvidedRadioInput
            label="Dog or Cat?"
            disabled={true}
            options={[
              {
                key: "dog",
                text: "",
                Unselected: () => (
                  <Button
                    type="secondary"
                    label="Dog"
                    onClick={() => {
                      console.log();
                    }}
                  />
                ),
                Selected: () => (
                  <Button
                    type="primary"
                    label="Dog"
                    onClick={() => {
                      console.log();
                    }}
                  />
                ),
              },
              {
                key: "cat",
                text: "",
                Unselected: () => (
                  <Button
                    type="secondary"
                    label="Cat"
                    onClick={() => {
                      console.log();
                    }}
                  />
                ),
                Selected: () => (
                  <Button
                    type="primary"
                    label="Cat"
                    onClick={() => {
                      console.log();
                    }}
                  />
                ),
              },
            ]}
            value={pet?.type}
            onChange={() => {
              console.log();
            }}
          />
          <ProvidedRadioInput
            label="Gender*"
            disabled={true}
            options={[
              {
                key: "male",
                text: "",
                Unselected: () => (
                  <Button
                    type="secondary"
                    label="Male"
                    onClick={() => {
                      console.log();
                    }}
                  />
                ),
                Selected: () => (
                  <Button
                    type="primary"
                    label="Male"
                    onClick={() => {
                      console.log();
                    }}
                  />
                ),
              },
              {
                key: "female",
                text: "",
                Unselected: () => (
                  <Button
                    type="secondary"
                    label="Female"
                    onClick={() => {
                      console.log();
                    }}
                  />
                ),
                Selected: () => (
                  <Button
                    type="primary"
                    label="Female"
                    onClick={() => {
                      console.log();
                    }}
                  />
                ),
              },
            ]}
            value={pet?.gender}
            onChange={() => {}}
          />
        </Row>
        <Row>
          <DateInput
            label="Date of Birth"
            value={pet?.birthday}
            disabled={true}
            onChange={() => {
              console.log();
            }}
          />
          <TextInput
            label="Weight (kg)"
            value={UpdatePetInformation.values.weight}
            touched={true}
            onChange={UpdatePetInformation.handleChange("weight")}
          />
        </Row>
        <Row>
          <DropdownInput
            label="Pet's Breed"
            disabled={true}
            options={petBreeds}
            value={pet?.breed}
            onChange={UpdatePetInformation.handleChange("breed")}
          />
          <ProvidedRadioInput
            label={"Desexed"}
            options={desexOptions}
            value={UpdatePetInformation.values.desexed}
            onChange={UpdatePetInformation.handleChange("desexed")}
          />
        </Row>
        <Row>
          <TextInput
            label="Pet's Parasite Prevention"
            value={UpdatePetInformation.values.preventativeProduct}
            touched={true}
            disabled={true}
            onChange={UpdatePetInformation.handleChange("preventativeProduct")}
          />
          <div></div>
        </Row>
        <br />
        <div>
          <Button
            label="Update Pet"
            customClass="button btn btn-purple square"
            onClick={() => UpdatePetInformation.handleSubmit()}
          />
          <Button
            label="Cancel"
            customClass="button btn btn-grey square"
            onClick={() => cancel()}
          />
        </div>
      </>
    );
  };

  const Benefits = () => {
    if (!availableClaims || availableClaims.length < 1)
      return <h5 style={{ textAlign: "right" }}>No Plan, No Benefits.</h5>;
    const claim = {
      vaccination: availableClaims.includes("vaccination") ? true : false,
      consultation: availableClaims.includes("clinicConsultation")
        ? true
        : false,
      bloodTest: availableClaims.includes("wellnessBloodTest") ? true : false,
      dental: availableClaims.includes("dental") ? true : false,
      desex: availableClaims.includes("desex") ? true : false,
    };
    return (
      <Row>
        <div className="responsive-table">
          <h3>Benefits</h3>
          <table>
            <tr className="none">
              <td>
                <div className="flex">
                  <img className="icon" src={chatIcon} />
                  <p className="icon-text">
                    Unlimited Online Vet Chat & Advice
                    <span className="subtext">
                      (Every Evening - Monday to Sunday)
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex">
                  <img className="icon" src={checkIcon} />
                  <p className="icon-text normal">Unlimited</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex">
                  <img className="icon" src={truckIcon} />
                  <p className="icon-text">
                    Flea, Tick & Worming Home Delivered
                    <span className="subtext">
                      (Sent automatically to you at the end of each relevant
                      month)
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex">
                  <img className="icon" src={checkIcon} />
                  <p className="icon-text normal">Available</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex">
                  <img className="icon" src={hospitalIcon} />
                  <p className="icon-text">
                    Unlimited Consultations at any Vet Clinic
                    <span className="subtext">
                      (Claim up to $75 per consultation)
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex">
                  {claim.consultation ? (
                    <>
                      <img className="icon check" src={checkIcon} />
                      <p className="icon-text normal">Available</p>
                    </>
                  ) : (
                    <>
                      <img className="icon minus" src={minusIcon} />
                      <p className="icon-text normal">Used</p>
                    </>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex">
                  <img className="icon" src={injectionIcon} />
                  <p className="icon-text">
                    Annual Vaccination
                    <span className="subtext">(Claim up to $100)</span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex">
                  {claim.vaccination ? (
                    <>
                      <img className="icon check" src={checkIcon} />
                      <p className="icon-text normal">Available</p>
                    </>
                  ) : (
                    <>
                      <img className="icon minus" src={minusIcon} />
                      <p className="icon-text normal">Used</p>
                    </>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex">
                  <img className="icon" src={stethoscopeIcon} />
                  <p className="icon-text">
                    $100 Wellness Screen Allowance
                    <span className="subtext">
                      (Wellness blood test as recommended by your Vet)
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex">
                  {claim.bloodTest ? (
                    <>
                      <img className="icon check" src={checkIcon} />
                      <p className="icon-text normal">Available</p>
                    </>
                  ) : (
                    <>
                      <img className="icon minus" src={minusIcon} />
                      <p className="icon-text normal">Used</p>
                    </>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td className="border-or">
                <div className="flex">
                  <img className="icon" src={toothbrushIcon} />
                  <p className="icon-text">
                    $100 Dental Allowance
                    <span className="subtext">
                      (From 12 months of age onwards)
                    </span>
                  </p>
                </div>
              </td>
              <td rowSpan={2}>
                <div className="flex">
                  {claim.dental || claim.desex ? (
                    <>
                      <img className="icon check" src={checkIcon} />
                      <p className="icon-text normal">Available</p>
                    </>
                  ) : (
                    <>
                      <img className="icon minus" src={minusIcon} />
                      <p className="icon-text normal">Used</p>
                    </>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex">
                  <img className="icon" src={desexedIcon} />
                  <p className="icon-text">
                    $100 Desexing Allowance
                    <span className="subtext">(Up to 12 months of age)</span>
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="flex">
                  <img className="icon" src={storeIcon} />
                  <p className="icon-text">
                    10% Pet Store Discount
                    <span className="subtext">
                      (Your PetPA online store including free shipping on all
                      orders over $99 and less than 13kgs)
                    </span>
                  </p>
                </div>
              </td>
              <td>
                <div className="flex">
                  <img className="icon" src={checkIcon} />
                  <p className="icon-text normal">
                    10% discount applied at the checkout when you use the code
                    ‘Thriving’
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </Row>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div>
      {planView == "detail" ? BasicInformation() : Benefits()}
      <br />
      <br />
    </div>
  );
};

export default EditPetDetail;
