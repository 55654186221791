import "./Overflow.scss";
import React from "react";

const Overflow: React.FC = ({ children }) => {
  /**
   * Convert the children to an array to make them
   * easier to work with.
   */
  const childrenArray = React.Children.toArray(children);

  /**
   * Manually add eventListener to bypass listener being registered as 'passive'
   */
  const scrollRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      // Function to bind
      const onWheel = (event: WheelEvent) => {
        if (event.deltaY == 0) return;
        // Only prevent vertical scrolling if horizontal scrolling is possible
        const condition =
          !(element.scrollLeft == 0 && event.deltaY < 0) &&
          !(
            element.scrollWidth -
              element.clientWidth -
              Math.round(element.scrollLeft) ==
              0 && event.deltaY > 0
          );
        if (condition) event.preventDefault();
        // Do the scroll
        element.scrollTo({
          left: element.scrollLeft + event.deltaY,
          behavior: "smooth",
        });
      };
      // Bind the eventListener
      element.addEventListener("wheel", onWheel);
      // Cleanup
      return () => element.removeEventListener("wheel", onWheel);
    }
  }, []);

  return (
    <div ref={scrollRef} className="overflow">
      {childrenArray.map((child) => (
        <div className="overflow__child">{child}</div>
      ))}
    </div>
  );
};

export default Overflow;
