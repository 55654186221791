import "./CodeInput.scss";
import { FocusProps, InputProps } from "../../../types/inputs";
import { Row } from "../../layout";
import ReactCodeInput, { InputModeTypes } from "react-code-input";
import { InputError } from "../../input";
import { toast } from "react-toastify";
import {
  sendEmailVerification,
  sendPasswordVerification,
} from "../../../api/authorisation";

/**
 * Input component for inputting a verification code.
 *
 * Wraps the react-code-input component:
 * https://www.npmjs.com/package/react-code-input
 */
interface CodeInputProps extends InputProps, FocusProps<HTMLInputElement> {
  /**
   * used for both password reset and email account verification
   */
  email: string;
  /**
   * for rendering certain things based on where the code input is being put
   */
  formType: "password" | "email";
  /**
   * The name of the component
   */
  name: string;
  /**
   * The kind of input values to be handling.
   */
  inputMode: InputModeTypes;
  /**
   * ?
   */
  type: "text" | "number" | "password" | "tel";
  /**
   * The number of values should the input have.
   */
  fields: number;
}
const CodeInput: React.VFC<CodeInputProps> = ({
  touched,
  error,
  value,
  onChange,
  email,
  formType,
  name,
  type,
  fields,
  inputMode,
}) => {
  return (
    <div className="codeInput">
      {email && (
        <>
          <p>
            A confirmation email with a 6 digit code has been sent to {email}{" "}
          </p>
          <Row>
            <ReactCodeInput
              value={value}
              onChange={onChange}
              name={name}
              inputMode={inputMode}
              type={type}
              fields={fields}
            />
          </Row>
          <Row>
            <InputError error={error} touched={touched} />
          </Row>
          <Row>
            <a
              onClick={async () => {
                const waiting = toast.loading("Sending Code");
                let response = { pass: false, reason: "" };
                if (formType === "email") {
                  response = await sendEmailVerification(email);
                } else if (email) {
                  response = await sendPasswordVerification(email);
                }
                if (response.pass === true) {
                  toast.update(waiting, {
                    render: response.reason,
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                  });
                } else {
                  toast.update(waiting, {
                    render: response.reason,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                  });
                }
              }}
            >
              resend code.
            </a>
          </Row>
        </>
      )}
    </div>
  );
};

export default CodeInput;
