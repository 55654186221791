import "./Dashboard.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button } from "../../components/common";
import { ApplicationState } from "../../store";
import { Invoice } from "../../components/common/Invoice/Invoice";
import { getClaims } from "../../api/claim";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import { thrivingPetsLogoDarkReverse, claimIcon } from "./Module";

const claimTypes = [
  { value: "vaccination", label: "Annual Vaccination Allowance" },
  { value: "clinicConsultation", label: "Clinic Consultation" },
  { value: "dental", label: "Dental Allowance" },
  { value: "wellnessBloodTest", label: "Wellness (Blood Test) Allowance" },
  { value: "desex", label: "Desexing Procedure" },
];

interface ClaimsHistoryType {
  closeModal: () => void;
}
const ClaimsHistory: React.FC<ClaimsHistoryType> = ({ closeModal }) => {
  const { fetchUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [isLoading, setIsLoading] = useState(false);
  const [claims, setClaims] = useState<any[]>([]);
  useEffect(() => {
    if (!isAuthenticated) return history.push("/");
    (async () => {
      setIsLoading(true);
      await fetchUserData();
      if (!user || !user.pets) return;
      const claimIds = await user.pets
        .map((p) => {
          return p.claims;
        })
        .filter((c) => {
          return c.length > 0;
        })
        .reduce((a: any, c: any) => {
          return a.concat(c);
        }, []);
      const theClaims = (await getClaims(claimIds)).reverse();
      setClaims(theClaims);
      setIsLoading(false);
    })();
  }, [isAuthenticated]);

  const myClaims = () => {
    if (claims.length < 1 && isLoading) return <p>Loading...</p>;
    else if (claims.length < 1) return <p>No Claims.</p>;
    //console.log({ claims });
    return (
      <div className="accordian__body">
        <div className="accordian__content accordian__content-open">
          {claims.map((c: any) => {
            const {
              _id: ID,
              claimId,
              type,
              status,
              pet,
              vet,
              reimbursement,
              invoice,
              date,
              view,
            } = c;
            const claimDate = () => {
              const today = new Date(date);
              const year = today.getFullYear();
              let mo: any = today.getMonth() + 1;
              let day: any = today.getDate();
              if (day < 10) day = `0${day}`;
              if (mo < 10) mo = `0${mo}`;
              return day + "/" + mo + "/" + year;
            };
            const claimType = claimTypes.find((f) => {
              return f.value == type;
            });
            const claimDetail = (v?: string) => {
              if (v != "active") return;
              return (
                <>
                  <div className={"claim-detail flex align-center " + view}>
                    <div className="view-file">
                      <Invoice
                        claimId={ID}
                        isPdf={invoice.slice(invoice.length - 3) === "pdf"}
                      />
                    </div>
                    <div className="view-detail">
                      <h3>#YPPA-{claimId} Claim</h3>
                      <p>
                        Type: {""}
                        <span>{claimType && claimType.label}</span>
                      </p>
                      <p>Pet: {pet.name}</p>
                      <p>Date: {claimDate()}</p>
                      <p>Vet: {vet}</p>
                      <p>Amount: {reimbursement.amount}</p>
                      <p>Status: {status}</p>
                    </div>
                  </div>
                </>
              );
            };
            return (
              <>
                <div className={"claim " + status}>
                  <div className="claim__content">
                    <h3>
                      #YPPA-{claimId} {claimType && claimType.label} for
                      {""} {pet.name}
                    </h3>
                    <p style={{ textTransform: "capitalize" }}>
                      status: {status}
                    </p>
                  </div>
                  <div className="claim__buttons">
                    <button
                      className="button button-primary button-fullwidth  button-active"
                      onClick={() => {
                        setClaims(
                          claims.map((cl) => {
                            if (cl._id != c._id) return cl;
                            cl.view = cl.view != "active" ? "active" : "";
                            return cl;
                          })
                        );
                      }}
                    >
                      <span>View Claim</span>
                    </button>
                  </div>
                </div>
                {claimDetail(view)}
              </>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div className="dashboard">
      <div className="content-header">
        <div className="flex align-center">
          <img src={thrivingPetsLogoDarkReverse} />
        </div>
      </div>
      <div className="content-body">
        <div className="field flex flex-start flex-align-center">
          <img style={{ width: "100px", maxWidth: "100%" }} src={claimIcon} />
          <h1>Claims History</h1>
        </div>
        {myClaims()}
      </div>
      <div className="content-footer">
        <div className="field flex flex-start">
          <Button
            label="Create New Claim"
            customClass="button btn btn-purple square"
            onClick={() => history.push("/dashboard/create-claim")}
          />
        </div>
      </div>
    </div>
  );
};

export default ClaimsHistory;
