import "./Dashboard.scss";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { UserUpdateData } from "../../types/user";
import { useFormik } from "formik";
import { Row, Modal } from "../../components/layout";
import { isNotEmpty, isMobile } from "../../utils/formik-validate";
import { stripCountry } from "../../utils/mobile-number";
import { PhoneInput, TextInput } from "../../components/input";
import { Button } from "../../components/common";
import AddressInput from "../../components/input/AddressInput/AddressInput";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import {
  arrowLeftIcon,
  arrowRightIcon,
  thrivingPetsLogoDarkReverse,
} from "./Module";

const PersonalAndDelivery: React.FC = () => {
  const location = useLocation();
  const { fetchUserData, updateUserData } = bindActionCreators(
    userActionCreators,
    useDispatch()
  );
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+61");
  const [userAddress, setUserAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    province: "",
    postcode: "",
  });
  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/onboarding/");
    } else {
      fetchUserData();
    }
    if (user && isAuthenticated) {
      //const address = user?.address;
      const initialAddress = Object.assign(userAddress, user.address);
      initialAddress.phone = user.phone.includes("+")
        ? stripCountry(user.phone)
        : user.phone;
      initialAddress.firstName = user.firstName;
      initialAddress.lastName = user.lastName;
      console.log({ initialAddress });
      setUserAddress(initialAddress);
    }
  }, [isAuthenticated, location]);
  const DeliveryInformation = useFormik({
    enableReinitialize: true,
    initialValues: userAddress,
    onSubmit: async (values) => {
      //return console.log({ values, user });
      const {
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        country,
        province,
        postcode,
      } = values;
      const imageUrl = user ? user.imageUrl : "",
        interests = user ? user.interests : [],
        email = user ? user.email : "",
        phone = `(${countryCode})${
          values.phone.includes("+") ? stripCountry(values.phone) : values.phone
        }`;
      const data: UserUpdateData = {
        phone,
        email,
        firstName,
        lastName,
        address: {
          address1,
          address2,
          city,
          state,
          country,
          province,
          postcode,
        },
        interests,
        imageUrl,
      };
      console.log({ data });
      setIsLoading(true);
      await updateUserData(data);
      await fetchUserData();
      setIsLoading(false);
      toast.success(`Account details successfully updated.`);
      history.push("/dashboard");
    },
    validate: async ({
      address1,
      address2,
      city,
      state,
      country,
      postcode,
      phone,
    }) => {
      const errors = {
        ...isNotEmpty(address1, "address1"),
        ...isNotEmpty(city, "city"),
        ...isNotEmpty(state, "state"),
        ...isNotEmpty(country, "country"),
        ...isNotEmpty(postcode, "postcode"),
        ...isMobile(`(${countryCode})${phone}`, "phone"),
      };
      //console.log(errors);
      return errors;
    },
  });
  /*
   * RENDERING
   */
  return (
    <div className="dashboard">
      <div className="content-header">
        <div className="flex align-center">
          <img src={thrivingPetsLogoDarkReverse} />
        </div>
      </div>
      <div className="content-body">
        <h1>Personal & Delivery Details</h1>
        <Row>
          <TextInput
            label="First Name"
            value={DeliveryInformation.values.firstName}
            onChange={DeliveryInformation.handleChange("firstName")}
            touched={true}
            error={DeliveryInformation.errors.firstName}
          />
          <TextInput
            label="Last Name"
            value={DeliveryInformation.values.lastName}
            onChange={DeliveryInformation.handleChange("lastName")}
            touched={true}
            error={DeliveryInformation.errors.lastName}
          />
        </Row>
        <Row>
          <PhoneInput
            label="Phone"
            value={DeliveryInformation.values.phone}
            onChange={DeliveryInformation.handleChange("phone")}
            touched={DeliveryInformation.touched.phone}
            error={DeliveryInformation.errors.phone}
            country={DeliveryInformation.values.country}
            onRegionSelect={(code: string) => setCountryCode(code)}
            required
          />
        </Row>
        <Row>
          <AddressInput form={DeliveryInformation} />
        </Row>
        <p>
          At the end of each relevant month, the parasite preventions included
          in your Thriving Pets Wellness Plan will be sent to the address you
          register here. You can expect this to arrive around the 30th of each
          month.
        </p>
        <p>
          <Button
            label="Submit"
            customClass="button btn btn-purple square"
            isDisabled={isLoading}
            onClick={() => {
              DeliveryInformation.handleSubmit();
            }}
          />
          <Button
            label="Cancel"
            customClass="button btn btn-grey square"
            onClick={() => history.push("/dashboard")}
          />
        </p>
      </div>
    </div>
  );
};

export default PersonalAndDelivery;
