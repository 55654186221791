import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Page, Document } from "react-pdf";
import { getCustomersInvoices, getInvoice } from "../../api/chargebee";
import { ApplicationState } from "../../store";
import { actionCreators as userActionCreators } from "../../store/User";
import history from "../../utils/history";
import { thrivingPetsLogoDarkReverse, claimIcon } from "./Module";

const MembershipInvoices: React.FC<any> = () => {
  const user = useSelector((state: ApplicationState) => state.user);
  const isAuthenticated = useSelector(
    (state: ApplicationState) => state.auth?.isAuthenticated
  );
  const [isLoading, setIsLoading] = useState(false);
  const [activeInvoice, setActiveInvoice] = useState<number | null>(null);
  const [PDFFile, setPDFFile] = useState<string | null>(null);
  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    if (!isAuthenticated) history.push("/");
    (async () => {
      if (!user?._id) return;
      //console.log({ user }, user?._id);
      await setIsLoading(true);
      const getInvoices = await getCustomersInvoices(
        user?._id,
        user?.address?.country
      );
      await setIsLoading(false);
      if (!getInvoices) return;
      const theInvoices = getInvoices.filter((invoice: any) => {
        const pet: any = user?.pets.find((p: any) => {
          return p.subscriptionId == invoice.subscription_id;
        });
        if (!pet) return;
        invoice.pet = pet;
        return invoice;
      });
      await setInvoices(theInvoices);
    })();
    //else //fetchUserData();
  }, [isAuthenticated]);

  const getTheDate = (theDate: Date, format: "default") => {
    const year = theDate.getFullYear();
    const date = theDate.getDate();
    const month = theDate.getMonth();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    switch (format) {
      case "default":
        return `${date} ${months[month]} ${year}`;
        break;
      default:
        return `${date} ${months[month]} ${year}`;
    }
  };
  const Invoices = () => {
    //const file = `https://cb-downloads-prod.s3.amazonaws.com/yourpetpa-test/invoice/6oZlkTkazdAm17pT.pdf?response-content-disposition=inline%3Bfilename%3Dyourpetpa-test%2Finvoice%2F6oZlkTkazdAm17pT.pdf&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECcaCXVzLWVhc3QtMSJHMEUCIQD1037zqdEVUK3R8SoPAiaLX7lg9mBkHHse%2BE2tWvPf%2FAIgHTxK4Bhc0bZHt%2BnqRlhZapFlQ3gD1CByPxNSZIlGWooqwgUIr%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNDE4NTQ3ODE3NTkiDFiCkLyOaef1NSEvWyqWBcwOrjUQm10WcRUOZarTuEW455fieQFLbHoAfBEuLrfdetyC%2F7Rtykn6q4GhENFou%2FcczPtT4NKWreo47KH7idWIsq1tOkRSRSjf82aOTZNqu5vJobUFD7ZWRqQd6tvkxJbKMPoSL0Stcl%2Byg3n7zFoxE6wYtDatQTmIPwQrYGVR9xmutRQbrZWjVI3cOp8OZhHGBseM73UpgS7Vgj08jd17RuXskNAx%2B1cRzBK%2BUIeSruJXpYDr9sW4LLZlVDKmvt15wjmxwkXiznwC8ut7JO5oPu5%2BI8%2FQ4WCAXJyet8BRfAqh3k9Qdxnp8VpSUQH0lLrQ8UIrd3jCR7e1gjMLs7Mock7U%2FJyUVnJtm62pK7ccPI7tak9kuYNKZn4zOfqaKugk%2BBZpOLxyZz7BDSuIwQ6YXbLAr2RgjfZbxn73ND0EK%2Fll10ZJGGEjdObPiKFUpubdpFUkRQSBpXsDID6FLrooBZ19o63QCVZbllOI79uJTdkVVM5qXganKoTqpVbWhzSr8Hh%2FTMztWCalv5Hh03%2FefJg5LRIuvfsIl6T6G%2F6ihsQlJiAZPCUIUmW%2Fc%2F%2BUjM6haYrv2bxrXLTJ2glTzB7sMOKbAUsc2D4YO5bFmU8igIRx%2BYqPaeR3P0uITjjc5oaymTbDh54ATrLLuYrUaoGv6J%2F9u34IfTPfYXoRJlu%2F7j5vuy6PzM2aQ9nUH8%2FOK%2FciG%2FUZh4GgFJpOhV0gsejbkHMoBUeULUKKLzhAnUC2q1LGpeXQ5xAI8KtVrXwH4R%2BpF%2B%2FGLEjVMBfXy4uK85iiiI1MWlcr2%2BcKYKc%2FoPCo%2Frbzgybyc1kzGzLXHRfvHDGgzosiIA8BbtkAJgVay7yaOSdg3vQHOgCH6GB8mIM%2BH5PG4LbGMI%2Fj5qUGOrEB7O0oVvzUYKHMqnqDZc86bZDnj%2F29L5oXxSTyUZfB%2FM2%2BeqDInBsp7mKBatQAvidx9vT4vozSRtMBgZihRXBH%2FFJrXQUz8RdCHLv8N3vAfi1qqFDqe8JCF4yCoP6bT1V3YyCQx6WXYovdcSkY2eUyujdNpxGH%2FamOndJuJTJXonyKfVtrL7x3iaF9PjWwbSMCEHaFOrFJoRtXCSC8tgZgaC4LQeeuEBt889sMBAXX4ZFq&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230720T232618Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=ASIATQT5LDE77EYGTCAH%2F20230720%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=336d0d7bc1e18cdcd0676fc1ce9d6c016b3b569cd7f7cfb17b8a88be724bb9ac`;
    if (invoices.length < 1 && isLoading) return <p>Loading...</p>;
    else if (invoices.length < 1) return <p>No Transaction.</p>;
    return (
      <div className="accordian__body">
        <div className="accordian__content">
          {invoices.map((invoice: any) => {
            const { pet } = invoice;
            console.log({ pet, invoice });
            return (
              <>
                <div className="claim pending flex flex-align-center">
                  <div className="claim__content" style={{ flex: "inherit" }}>
                    <p style={{ lineHeight: "30px" }}>
                      <strong>Thriving Pets Membership</strong>
                      <br />
                      {pet.onboarding ? (
                        <>
                          {pet?.onboarding.toUpperCase()}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      <span style={{ textTransform: "capitalize" }}>
                        {pet?.name}, {pet?.type}, {pet?.breed}
                      </span>
                    </p>
                  </div>
                  <div className="claim__content" style={{ flex: "inherit" }}>
                    <p style={{ lineHeight: "30px" }}>
                      Amount: {""}
                      <strong>${(invoice.total / 100).toFixed(2)}</strong>
                      <br />
                      Status: {""}
                      <span style={{ textTransform: "capitalize" }}>
                        {invoice.status}
                      </span>
                      <br />
                      Date: {""}
                      {getTheDate(new Date(invoice.paid_at * 1000), "default")}
                    </p>
                  </div>
                  <div
                    className="claim__content"
                    style={{
                      flex: "inherit",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        width: "200px",
                      }}
                      className="button btn btn-purple square"
                      onClick={async () => {
                        if (!user) return;
                        const invoiceId =
                          invoice.id == activeInvoice ? null : invoice.id;
                        setActiveInvoice(invoiceId);
                        if (!invoiceId) return;
                        const theInvoice = await getInvoice(
                          invoice.id,
                          user?.address?.country
                        );
                        if (theInvoice && theInvoice.download_url)
                          setPDFFile(theInvoice.download_url);
                      }}
                    >
                      View Invoice
                    </button>
                  </div>
                </div>
                {invoice.id == activeInvoice && (
                  <div className="claim-detail flex active">
                    <div className="view-file" style={{ marginTop: "20px" }}>
                      {PDFFile && (
                        <Document file={PDFFile}>
                          <Page
                            pageNumber={1}
                            renderAnnotationLayer={false}
                            width={600}
                          />
                        </Document>
                      )}
                    </div>
                    <div className="view-detail">
                      <br />
                      <br />
                      <h4>
                        Thriving Pets Membership
                        <br />
                        {pet?.onboarding && pet?.onboarding.toUpperCase()}
                      </h4>
                      <p style={{ textTransform: "capitalize" }}>
                        Pet: {pet?.name}
                      </p>
                      <p style={{ textTransform: "capitalize" }}>
                        Date: {""}
                        {getTheDate(
                          new Date(invoice.paid_at * 1000),
                          "default"
                        )}
                      </p>
                      <p style={{ textTransform: "capitalize" }}>
                        Amount: ${(invoice.total / 100).toFixed(2)}
                      </p>
                      <p style={{ textTransform: "capitalize" }}>
                        Status: {invoice.status.toUpperCase()}
                      </p>
                      <a
                        style={{ lineHeight: "30px" }}
                        target="_blank"
                        href={PDFFile ? PDFFile : ""}
                        className="button btn btn-purple square"
                      >
                        Download Invoice
                      </a>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  };
  /*
   * RENDERING
   */
  return (
    <div className="dashboard">
      <div className="content-header">
        <div className="flex align-center">
          <img src={thrivingPetsLogoDarkReverse} />
        </div>
      </div>
      <div className="content-body">
        <div className="field flex flex-start flex-align-center">
          <img style={{ width: "100px", maxWidth: "100%" }} src={claimIcon} />
          <h1>Membership Invoices</h1>
        </div>
        {Invoices()}
      </div>
    </div>
  );
};

export default MembershipInvoices;
