import "./Stepper.scss";
import React from "react";
import { Overflow } from "..";

interface StepperProps {
  /**
   * The content of the Stepper. Each child
   * corresponds with being a page.
   */
  children: React.ReactNode | React.ReactNode[];
  /**
   * Optional custom step labels for each page. Ensure
   * the stepLables length matches the number of children.
   */
  customStepLabels?: string[];
  /**
   * Method for setting the current page of the stepper.
   */
  currentIndex: number;
  /**
   * Function to be provided for setting the new index.
   */
  setIndex?: (index: number) => void;
}

const Stepper: React.FC<StepperProps> = ({
  children,
  customStepLabels,
  currentIndex,
  setIndex,
}) => {
  /**
   * Convert the children to an array to make them
   * easier to work with.
   */
  const childrenArray = React.Children.toArray(children);

  /**
   * Renders the step progress.
   */
  const renderProgress = () => {
    // Final array to be returned
    const progress: React.ReactNode[] = [];
    // For each child
    childrenArray.map((child, index) => {
      const active = index === currentIndex ? "stepper__step-active" : "";
      const complete = index < currentIndex ? "stepper__step-complete" : "";
      const clickable = !!setIndex ? "stepper__step-clickable" : "";
      // Add a step
      progress.push(
        <h3
          className={`stepper__step ${active} ${complete} ${clickable}`}
          onClick={() => {
            return setIndex ? setIndex(index) : undefined;
          }}
        >
          {customStepLabels && customStepLabels[index]
            ? customStepLabels[index]
            : `Step ${index + 1}`}
        </h3>
      );
      // And add a seperator between each item
      if (index + 1 !== childrenArray.length) {
        // Add additional style if completed
        const travelled =
          index < currentIndex ? "stepper__seperator-travelled" : "";
        progress.push(
          <div className={`stepper__seperator ${travelled}`}>&#62;</div>
        );
      }
    });

    return progress;
  };

  /**
   * Renders the step content. Make content that is not current
   * rendered but hidden - this is necessary for access input values.
   */
  const renderContent = () => {
    return childrenArray.map((child, index) => {
      return index === currentIndex ? (
        child
      ) : (
        <div key={index} style={{ display: "none" }}>
          {child}
        </div>
      );
    });
  };

  return (
    <div className="stepper">
      <Overflow>
        <div className="stepper__progress">{renderProgress()}</div>
      </Overflow>
      <div className="stepper__content">{renderContent()}</div>
    </div>
  );
};

export default Stepper;
