import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

const useFacebookPixel = () => {
  const pixelID = `${process.env.REACT_APP_FACEBOOK_PIXEL_ID}`;
  const advancedMatching = undefined;
  const options = {
    autoConfig: true,
    debug: false,
  };

  useEffect(() => {
    ReactPixel.init(pixelID, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
};

export default useFacebookPixel;
